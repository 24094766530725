export default function ShareSidebar({ handleRedirectToMentor }) {
  return (
    <div
      data-collapsed="false"
      className="w-layout-hflex site-left-sidebar-block black-bg-dark-mode"
    >
      <div className="w-layout-hflex flex-block-92">
        <div
          behave-as-btn="1"
          prop-events-names="onClick"
          prop-events-value-onclick="handleHeaderMenuToggleClick"
          data-w-id="8f2ed2b8-d3dd-1bce-014c-90f64c8654d5"
          onClick={handleRedirectToMentor}
          className="menu-toggler-v2 greyish-bg-in-darkmode"
        >
          <span className="sr-only">Sidebar toggle</span>
          <img
            src="/images/align-justify.svg"
            loading="lazy"
            alt="VIew and Hide Recent Messages Tab"
            width="24"
            className="menu-toggler-icon whiten-in-dark-mode"
          />
        </div>
        <a className="logo-container" href="/">
          <img
            src="/images/66280a6575f5ceb6315a8315_iblagents-1-p-3200-1.png"
            loading="lazy"
            width="Auto"
            sizes="100vw"
            alt="mentorAI logo"
            className="image-58"
          />
          <div className="text-block whiten-in-dark-mode title-logo-container">
            {' '}
            mentor
          </div>
        </a>
      </div>
      <div className="w-layout-hflex new-chat-btn-sidebar-container">
        <div
          behave-as-btn="1"
          prop-events-names="onClick"
          prop-events-value-onclick="handleNewChatBtnClick"
          title="New Chat Button"
          role="button"
          onClick={handleRedirectToMentor}
          className="w-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode new-chat-btn-block-small-block light-grey-bg-dark-mode"
        >
          <img
            src="/images/plus-4.svg"
            loading="lazy"
            alt="Create New Chat By Clicking on New Chat Button"
            className="image-32"
          />
        </div>
      </div>
      <aside
        react-component="sidebar"
        className="body-left-side all-whiten-in-dark-mode"
      >
        <div className="w-layout-vflex body-left-side-block">
          <div
            behave-as-btn="1"
            prop-events-names="onClick"
            prop-events-value-onclick="handleNewChatBtnClick"
            title="New Chat Button"
            role="button"
            className="w-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode"
          >
            <img
              src="/images/plus-4.svg"
              loading="lazy"
              alt="Create New Chat By Clicking on New Chat Button"
              className="image-32"
            />
            <div className="text-block-32">New Chat</div>
          </div>
        </div>
      </aside>
      <div
        behave-as-btn="1"
        prop-events-names="onClick"
        prop-events-value-onclick="handleThemeSwicherBtnClick"
        title="Theme Toggle Button"
        role="button"
        tabIndex="101"
        className="w-layout-hflex theme-switcher-container greyish-bg-in-darkmode whiten-border-in-dark-mode theme-switcher-container-new-pos hidden"
      >
        <img
          src="/images/moon-svgrepo-com.svg"
          loading="lazy"
          alt="Change Theme By Pressing Theme Toggle Button"
          className="theme-switcher-icon"
        />
      </div>
    </div>
  );
}
