import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import useSubscriptionStatus from '../../hooks/subscription/useSubscriptionStatus';
import { useTriggers } from '../../hooks/navigation';
import { stripeBilling } from '@iblai/ibl-web-react-common';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { SubscriptionExpired } from '../alerts';

export default function SubscriptionInactiveBanner() {

  const {
    show,
    tooltip,
    pillDisplay,
    description,
    customerPortalURL,
    app, handleRenewSubscription, handleSuccessFulRenewalSubscription
  } = useSubscriptionStatus();

  const { handleSeePricingIframeBoxClick } = useTriggers();

  const [loading, setLoading] = useState(false);

  const handleBannerBtnClick = async () => {
    if(!customerPortalURL){
      handleSeePricingIframeBoxClick()
      return
    }
    setLoading(true)
    await handleRenewSubscription(app?.subscription?.identifier, (response)=>{
      if(response?.data?.success && !response?.data?.redirect_url){
        //SUCCESSFULLY DEBITED USERS
        handleSuccessFulRenewalSubscription()
        return;
      }
      if(response?.data?.redirect_url){
        toast.success("Redirecting you to the billing page.");
        setTimeout(()=>{
          window.location.href=response?.data?.redirect_url
        }, 500)
        return
      }
      stripeBilling(
        {
          return_url: new URL(window.location.href),
        },
        (url) => {
          window.location.href = url;
        }
      );
    });
  }

  return (
    <>
      {show && (
        <TrialTopHeader
          descriptionText={description}
          pillDisplayText={pillDisplay}
          tooltipText={tooltip}
          loading={loading}
          handleClick={handleBannerBtnClick}
          fixedClassNamesToMove=".header, .main-content"
        />
      )}
    </>
  );
}
