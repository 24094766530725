import { useEffect } from 'react';

import { useTriggers } from '../../hooks/navigation';
import { usePlatformData } from '../platform/platform-layout';

export default function MentorsOptions() {
  const { handleOpenEditMentorModal } = useTriggers();
  const mentorSettings = usePlatformData();
  useEffect(() => {
    if (mentorSettings) {
      handleOpenEditMentorModal();
    }
  }, [mentorSettings]);

  return <></>;
}
