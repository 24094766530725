import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import useAnonymousMode from '../iframe/useAnonymousMode';
import { stripeBilling } from '@iblai/ibl-web-react-common';
import {
  getDayJSDurationObjFromSeconds,
  generateFutureDateForNMinutes,
  getTimeDifferenceBetweenTwoDates,
} from '../../utils/shared';
import dayjs from 'dayjs';
import { navigationActions } from '../../lib/redux/navigation/slice';
import { toast } from 'react-hot-toast';
import { SubscriptionExpired } from '../../modules/alerts';


export default function useSubscriptionStatus() {
  const [show, setShow] = useState(false);
  const [subscriptionExtraData, setSubscriptionExtraData] = useState({
    active: false,
    trial_ends: null,
  });
  const [tooltip, setTooltip] = useState(
    `Your subscription is currently inactive. You need to renew or update your payment details`
  );
  const [pillDisplay, setPillDisplay] = useState(`Update`);
  const [description, setDescription] = useState(
    `Your subscription is currently inactive. You need to renew or update your payment details`
  );
  const [loading, setLoading] = useState(false);
  const [customerPortalURL, setCustomerPortalURL] = useState('');
  const [app, setApp] = useState(null);
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();
  const anonymous = useAnonymousMode();
  const dispatch = useDispatch();
  const [freeTrialIntervalCheckCounter, setFreeTrialIntervalCheckCounter] = useState(24*60*60000) //Every 1 day;
  const [freeTrialIntervalID, setFreeTrialIntervalID] = useState(0);

  const loadSubscriptionsStatus = async () => {
    const url = `/api/features/apps/`;
    const dmToken = localStorage.getItem('dm_token');
    const response = await api.get(url, { app: 'mentor' }, dmToken);
    if (response?.data) {
      const mentorApp = response?.data?.results?.find(
        (item) =>
          item?.app?.name?.toLowerCase()?.includes('mentor') &&
          item?.platform?.key === auth.tenant.key
      );
      if (mentorApp) {
        await stripeBilling(
          {
            return_url: new URL(window.location.href),
          },
          (url) => {
            if(url){
              setCustomerPortalURL(url)
              setApp(mentorApp);
              setSubscriptionExtraData({
                active: mentorApp?.subscription?.active,
                trial_ends: mentorApp?.subscription?.trial_ends,
              });
            }
          }
        );
      } else if (auth?.tenant?.key === 'main') {
        await handleStartFreeTrial();
      }
    }
  };

  const handleStartFreeTrial = async () => {
    const url = `/api/features/apps/update-trial-status/`;
    const payload = {
      app: process.env.REACT_APP_IBL_MENTOR_URL,
      free_trial_started: true,
      platform: auth?.tenant?.key,
    };
    const response = await api.post(
      url,
      payload,
      false,
      localStorage.getItem('dm_token')
    );
    if (response?.data) {
    }
  };

  const handleGotoPaymentUpdate = async () => {
    if (app?.app?.url?.startsWith('mentor.')) {
      if (app?.subscription?.status === 'canceled') {
        handleRenewSubscription(app?.subscription?.identifier, (response)=>{
          if(response?.data){
            window.location.reload();
          }
        });
        return;
      }

      const url = `/api/service/orgs/main/users/${auth?.user?.user_nicename}/stripe/customer-portal/`;
      const response = await api.get(url);
      if (response?.data) {
        window.location.assign(response?.data?.url);
      }
    } else {
      window.location.assign(app?.app?.url);
    }
  };

  const handleRenewSubscription = async (checkoutID, callback=()=>{}) => {
    const url = `/api/service/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/stripe/subscription-renewal/`;
    const payload = {
      checkout_session_uuid: checkoutID,
      return_url: window.location.href,
    };
    const response = await api.post(url, payload);
    callback(response);
    /*if (response?.data) {
      window.location.reload();
    }*/
  };

  const handleSuccessFulRenewalSubscription = () => {
    dispatch(
      navigationActions.subscriptionExpiredUpdated(false)
    )
    document.querySelector(".ibl-trial-top-header .top-header-closer-container").click()
    //setShow(false)
    toast.success("Successfully renewed your subscription.");
  }

  const handleTriggerFreeTrialExpiry = () => {
    const tooltipValue = `Your free trial has ended. Please upgrade to continue using mentorAI 😎.`;
    setDescription(tooltipValue);
    setTooltip(tooltipValue);
    setPillDisplay('Upgrade');
    dispatch(
      navigationActions.subscriptionExpiredUpdated(true)
    )
    toast.custom(SubscriptionExpired(tooltipValue));
    if(freeTrialIntervalID){
      clearInterval(freeTrialIntervalID)
    }
  }

  const handleFreeTrialSubscriptionExpiry = () => {
    setShow(true);
    const currentDate = new Date();
    if(!app?.subscription?.status ||
      ["paused", "canceled"].includes(app?.subscription?.status) ||
      currentDate > new Date(subscriptionExtraData?.trial_ends)){
      handleTriggerFreeTrialExpiry()
    }else{
      //SUBSCRIPTION TRIALING
      const timeRemaining = getTimeDifferenceBetweenTwoDates(
        subscriptionExtraData?.trial_ends,
        dayjs()
      );
      const durationObj = getDayJSDurationObjFromSeconds(timeRemaining);
      let timeRemainingInString = '';
      let durationNumber
      if (timeRemaining >= 3600 * 24) {
        durationNumber = Math.round(durationObj.asDays())
        timeRemainingInString = `${durationNumber} day${durationNumber > 1 ? "s": ""}`;
      } else if (timeRemaining > 3600) {
        clearInterval(freeTrialIntervalID)
        setFreeTrialIntervalCheckCounter(10*60000) // Every 10 minutes
        durationNumber = Math.round(durationObj.asHours())
        timeRemainingInString = `${durationNumber} hour${durationNumber > 1 ? "s": ""}`;
      } else {
        clearInterval(freeTrialIntervalID)
        setFreeTrialIntervalCheckCounter(60000) //Every 1 minute
        durationNumber = Math.round(durationObj.asMinutes())
        timeRemainingInString = `${durationNumber} minute${durationNumber > 1 ? "s": ""}`;
      }
      const tooltipValue = `You’re on a free trial for the next ${timeRemainingInString}. Upgrade to a paid plan 💪`;
      setDescription(tooltipValue);
      setTooltip(tooltipValue);
      setPillDisplay('Subscribe');
    }
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true' &&
      !loading &&
      !anonymous
    ) {
      loadSubscriptionsStatus();
    }
  }, []);

  useEffect(() => {
    if(app && app?.subscription?.status !== 'active') {
      handleFreeTrialSubscriptionExpiry()
      const intervalId = setInterval(handleFreeTrialSubscriptionExpiry, freeTrialIntervalCheckCounter);
      setFreeTrialIntervalID(intervalId)
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [app]);

  return {
    show,
    handleGotoPaymentUpdate,
    subscriptionExtraData,
    tooltip,
    description,
    pillDisplay,
    customerPortalURL,
    app,
    handleRenewSubscription,
    handleSuccessFulRenewalSubscription
  };
}
