import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  canSwitchProvider,
  loadLLMsRaw,
  getDisplayName,
  getImageName,
  handleChooseProvider,
} from './utils';
import useLLMs from '../../hooks/llm/useLLMs';
import useApi from '../../hooks/api/base/useApi';
import { useTriggers } from '../../hooks/navigation';
import { mentorActions } from '../../lib/redux/mentors/slice';
import NoRecordFound from '../../components/NoRecordFound/NoRecordFound';

export default function LLMs() {
  const mentors = useSelector((state) => state.mentors);
  const api = useApi();
  const { handleOpenLLMSelectionModal } = useTriggers();
  const [searchInput, setSearchInput] = useState('');
  const auth = useSelector((state) => state.auth.data);

  const dispatch = useDispatch();
  const { loading, error } = useLLMs();
  const [providerLoaded, setProviderLoaded] = useState(false);
  useEffect(() => {
    if (!loading && !error) {
      setProviderLoaded(true);
      return;
    }
    if (!loading && error) {
      loadLLMsRaw(auth, api, dispatch, mentorActions).then(() =>
        setProviderLoaded(true)
      );
    }
  }, [loading]);

  const isSearchResultEmptyProviders = () => {
    return (
      !!searchInput &&
      !mentors.providers.some((provider) =>
        getDisplayName(provider.name)
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      )
    );
  };

  return (
    <div className="w-layout-vflex llm-tab">
      <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
        <img
          alt=""
          className="image-49"
          loading="lazy"
          src="/images/search-1.svg"
        />
        <div className="search-box-form-block w-form">
          <form
            data-name="Email Form 4"
            data-wf-element-id="b2182b0b-09e9-b9e8-0941-94ce822aa70a"
            data-wf-page-id="650305782bc61751e5765214"
            id="email-form-4"
            method="get"
            name="email-form-4"
          >
            <input
              className="text-field-4 w-input"
              data-name="Name 5"
              id="name-5"
              maxLength={256}
              name="search"
              onChange={(event) => setSearchInput(event?.target?.value)}
              placeholder="Search Providers"
              value={searchInput}
              type="text"
            />
          </form>
        </div>
      </div>
      {providerLoaded &&
      !!mentors?.providers &&
      !isSearchResultEmptyProviders() ? (
        <div className="w-layout-grid grid-3">
          {mentors.providers.map((provider, index) => {
            if (
              !!searchInput &&
              !getDisplayName(provider.name)
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            ) {
              return;
            } else
              return (
                <button
                  key={`llm-provider-icon-${index}`}
                  data-w-id="249fc066-2c04-4639-c8fb-7759d57a6fb5"
                  className="w-layout-hflex resource-card"
                  onClick={() =>
                    canSwitchProvider(mentors, provider.name)
                      ? handleChooseProvider(
                          provider.name,
                          dispatch,
                          mentorActions,
                          handleOpenLLMSelectionModal
                        )
                      : null
                  }
                >
                  <img
                    src={`/images/${getImageName(provider.name)}`}
                    loading="lazy"
                    alt=""
                    className={
                      canSwitchProvider(mentors, provider.name)
                        ? 'image-184'
                        : 'image-184 dimmed-image'
                    }
                  />
                  <div>
                    {mentors?.currentMentorLLM?.provider === provider.name ? (
                      <strong className="bold-text-29">
                        {getDisplayName(provider.name)}
                      </strong>
                    ) : (
                      getDisplayName(provider.name)
                    )}
                  </div>
                </button>
              );
          })}
        </div>
      ) : (
        <NoRecordFound />
      )}
    </div>
  );
}
