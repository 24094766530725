import { useEffect, useRef } from 'react';

export function Dialog({ closeModal, name, className, children }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const closeButton = document.querySelector('.modal-close-wrapper');
    if (closeButton) {
      closeButton.focus();
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [closeModal]);

  return (
    <div
      ref={modalRef}
      className={`modal ${name}`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div className={className} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}
