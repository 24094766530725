import React, { useState } from 'react';
import useApi from '../../../hooks/api/base/useApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHostFromUrl,
  handleLayoutsWithCustomTooltipsJS,
} from '../../../utils/shared';
import { BASE_API_URL } from '../../../utils/api';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../hooks/useDebounce';
import { Error } from '../../alerts';
import { mentorActions } from '../../../lib/redux/mentors/slice';

export default function DatasetItem({ dataset }) {
  const [item, setItem] = React.useState({ ...dataset });
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const api = useApi();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const handleDeleteDataset = async () => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.tenant?.key}/documents/${item?.id}/`;
    const response = await api.remove(url);
    if (response?.data) {
      toast.success('Item successfully deleted!', {
        ariaProps: { role: 'status' },
      });
      dispatch(
        mentorActions.editMentorUpdated({
          ...editMentor,
          lastUpdated: new Date().toISOString(),
        })
      );
    } else {
      toast.custom(Error('Could not delete document'), {
        ariaProps: { role: 'alert' },
      });
    }
  };

  const handleUpdateDataset = async (data) => {
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.tenant?.key}/documents/${item?.id}/`;
    return await api.put(url, data);
  };

  const handleVisibilityToggle = async (status) => {
    const data = {
      access: status,
      pathway: item.pathway,
    };
    const response = await handleUpdateDataset(data);
    if (response?.data) {
      setItem((prevState) => {
        return { ...prevState, ...response?.data };
      });
    }
  };

  const handleToggleTrainDataset = async (event) => {
    const train = event.target.checked;
    const data = {
      train: train,
      pathway: item.pathway,
      url: item.url,
    };
    const response = await handleUpdateDataset(data);
    if (response?.data) {
      setItem((prevState) => {
        return { ...prevState, ...response?.data };
      });
      const message = train
        ? t('Resource has been submitted for training')
        : t('Resource has been submitted for untraining');
      toast.success(message, { ariaProps: { role: 'status' } });
      setShowDeletePrompt(!train);
    }
  };

  const includeUrlParams = (url) => {
    let updatedUrl = url;
    if (
      url?.startsWith(BASE_API_URL) ||
      url?.startsWith(BASE_API_URL.replace('https://', 'http://'))
    ) {
      updatedUrl = `${url}&username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
    }
    return updatedUrl;
  };

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode dataset-item">
      <div className="table-block table-block-very-big">
        {item.document_type.toLowerCase() === 'url' ? (
          <div className="table-text text-inline">
            {item.document_name || item.url}
          </div>
        ) : (
          <div className="table-text text-inline">
            {item.document_name || item.url}
          </div>
        )}
      </div>
      <div className="table-block table-block-medium">
        <div className="table-text">
          {item.document_type
            ?.toUpperCase()
            ?.replace('.PDF', 'PDF')
            .replace('.URL', 'URL')}
        </div>
      </div>
      <div className="table-block table-block-big bold-in-dark-mode">
        <div className="table-text">
          {item.tokens}
          <br />
        </div>
      </div>
      <div className="table-block table-block-large">
        <a
          title={item.url}
          href={`${includeUrlParams(item.url)}`}
          target="_blank"
          className="table-text-link underline-on-hover w-inline-block"
        >
          <span className="text-inline">{getHostFromUrl(item.url)}</span>
        </a>
      </div>
      <div className="table-block table-block-small action-block has-custom-tooltip">
        {item?.training_status === 'trained' && (
          <div className="user-row-switcher-embed w-embed">
            <label className="user-switcher">
              <input
                onChange={handleToggleTrainDataset}
                type="checkbox"
                checked={true}
              />
              <span className="user-switcher-slider round"></span>
            </label>
            <div
              className="tooltip dataset-status-tooltip"
              style={{ display: 'none', marginRight: '60px' }}
            >
              Document has been trained
            </div>
          </div>
        )}

        {item?.training_status === 'untrained' && (
          <div className="user-row-switcher-embed w-embed">
            <label className="user-switcher">
              <input
                onChange={handleToggleTrainDataset}
                type="checkbox"
                checked={false}
              />
              <span className="user-switcher-slider round"></span>
            </label>
            <div
              className="tooltip dataset-status-tooltip"
              style={{ display: 'none', marginRight: '60px' }}
            >
              Document is untrained
            </div>
          </div>
        )}

        {item?.training_status === 'failed' && (
          <div className="user-row-switcher-embed w-embed">
            <label className="user-switcher failed">
              <input
                checked={false}
                onChange={handleToggleTrainDataset}
                type="checkbox"
              />
              <span className="user-switcher-slider round"></span>
            </label>
            <div
              className="tooltip dataset-status-tooltip"
              style={{ display: 'none', marginRight: '60px' }}
            >
              {item?.metadata?.user_training_failure_reason ??
                'Failed to train dataset'}
            </div>
          </div>
        )}

        {item?.training_status === 'pending' && (
          <div className="w-layout-hflex flex-block-182">
            <div className="code-embed w-embed">
              <div className="lds-dual-ring"></div>
            </div>
            <div
              className="tooltip dataset-status-tooltip"
              style={{ display: 'none', marginRight: '60px' }}
            >
              Document training is pending
            </div>
          </div>
        )}

        {item?.training_status === 'failed' && (
          <img
            onClick={handleDeleteDataset}
            title={t('Delete document')}
            src="/images/trash-3.svg"
            loading="lazy"
            alt=""
            style={{ marginRight: '10px' }}
            className="dataset-delete-icon"
          />
        )}

        {showDeletePrompt && (
          <div
            className="w-layout-hflex pop-confirm-block"
            style={{ display: 'flex' }}
          >
            <div className="w-layout-hflex flex-block-187">
              <img
                src="/images/info-yellow.svg"
                loading="lazy"
                alt=""
                className="image-71"
              />
              <h5 className="heading-47">Confirm Deletion!</h5>
            </div>
            <div className="w-layout-vflex flex-block-188">
              <div className="text-block-64">
                Do you want to delete this resource?
              </div>
              <div className="w-layout-hflex flex-block-189">
                <div
                  data-w-id="95dc1a64-cd03-8fd9-f899-46d3cc55c02c"
                  className="button-with-icon pop-confirm-btn no-fill"
                  onClick={() => setShowDeletePrompt(false)}
                >
                  <img
                    width="70"
                    loading="lazy"
                    alt=""
                    src="/images/user-black-thick.svg"
                    className="button-icon hidden"
                  />
                  <div className="button-text">No</div>
                </div>
                <div
                  data-w-id="95dc1a64-cd03-8fd9-f899-46d3cc55c030"
                  className="button-with-icon pop-confirm-btn"
                  onClick={handleDeleteDataset}
                >
                  <img
                    width="70"
                    loading="lazy"
                    alt=""
                    src="/images/user-black-thick.svg"
                    className="button-icon hidden"
                  />
                  <div className="button-text">Yes</div>
                </div>
              </div>
            </div>
            <div className="w-layout-vflex pop-confirm-block-chevron-box"></div>
          </div>
        )}
      </div>
      <div className="table-block table-block-small">
        <div className="w-layout-hflex flex-block-466">
          {item?.access === 'public' ? (
            <img
              data-w-id="cfe05aea-90c5-050c-f6bc-b7ebbe4716b7"
              onClick={() => handleVisibilityToggle('private')}
              loading="lazy"
              alt=""
              src="/images/eye-1.svg"
              className="eye-toggle-img"
              style={{ display: 'block' }}
              title="Make Private"
            />
          ) : (
            <img
              data-w-id="64dbe26e-fce7-f687-aae8-b2f19ec69892"
              loading="lazy"
              alt=""
              onClick={() => handleVisibilityToggle('public')}
              src="/images/eye-off.svg"
              className="eye-hide-toggle-img"
              title="Make Public"
            />
          )}
        </div>
      </div>
    </div>
  );
}
