import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useFreeTrial from '../freetrial/useFreeTrial';
import { chatActions } from '../../lib/redux/chat/slice';
import { useMentorProperties } from '../settings/useSettings';
import useGuidedSuggestedPrompts from './useGuidedSuggestedPrompts';

let chatSocket = null;
let responseInProgress = '';

export default function useWelcome() {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const session = useSelector((state) => state.chat.session);
  const welcomeMessage = useSelector((state) => state.chat.welcomeMessage);
  const pathParams = useParams();
  const [stateResponseInProgress, setStateResponseInProgress] = useState('');
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();

  const { reloadFreeTrialCount } = useFreeTrial(true);
  const dispatch = useDispatch();

  const { settings, refreshSettingsCount } = useMentorProperties(
    mentors?.mentor?.unique_id
  );

  const greetingMethod = settings?.settings?.greeting_method;
  const proactiveResponse = settings?.settings?.proactive_response;

  const chatPayload = {
    flow: {
      name: mentors?.mentor?.unique_id,
      tenant: pathParams?.platform ? pathParams.platform : auth?.tenant?.key,
      username: auth?.user?.user_nicename,
      pathway: mentors?.mentor?.unique_id,
    },
    session_id: sessionId,
    token: localStorage.getItem('axd_token'),
  };

  const _initiateConnection = () => {
    try {
      chatSocket?.send(JSON.stringify(chatPayload));
    } catch (e) {
      console.log(e);
    }
  };

  const _handleSendTextQuery = () => {
    const payload = {
      ...chatPayload,
      // prompt: mentors?.mentor.proactive_prompt,
      is_proactive: true,
    };
    chatSocket?.send(JSON.stringify(payload));
  };

  const _handleIncomingMessage = (event) => {
    let response = JSON.parse(event.data);

    if (response?.detail === 'Connected.') {
      _handleSendTextQuery();
    }

    if (response?.data) {
      responseInProgress += response?.data;
      setStateResponseInProgress(responseInProgress);
    }

    if (response?.eos) {
      responseInProgress = '';
      _endConnection();
      loadGuidedPrompts();
    }

    if (response?.error) {
      console.log(response.error);
      //toast.custom(Error(response.error));
      _endConnection();
    }
  };

  useEffect(() => {
    if (greetingMethod === 'proactive_response') {
      dispatch(chatActions.welcomeMessageUpdated(proactiveResponse));
    }
    if (responseInProgress) {
      dispatch(chatActions.welcomeMessageUpdated(responseInProgress));
    }
  }, [responseInProgress, proactiveResponse]);

  const _endConnection = () => {
    if (chatSocket?.readyState === WebSocket.OPEN) {
      chatSocket?.close();
    }
  };

  const handleSendProactivePrompt = () => {
    if (greetingMethod === 'proactive_prompt') {
      chatSocket = new WebSocket(
        `${process.env.REACT_APP_BASE_WS_URL}/ws/langflow/`
      );

      chatSocket.onmessage = _handleIncomingMessage;
      chatSocket.onopen = () => {
        setTimeout(_initiateConnection, 1000);
      };
    }
  };

  useEffect(() => {
    if (mentors?.mentor) {
      setStateResponseInProgress('');
    }
  }, [mentors?.mentor]);

  useEffect(() => {
    if (
      sessionId &&
      session?.sessionId === sessionId &&
      session?.mentor === mentors?.mentor.unique_id
    ) {
      if (greetingMethod === 'proactive_prompt') {
        handleSendProactivePrompt();
      } else {
        loadGuidedPrompts();
      }
    }
  }, [
    sessionId,
    session?.sessionId,
    session?.mentor,
    mentors?.mentor?.unique_id,
    greetingMethod,
    proactiveResponse,
    refreshSettingsCount,
  ]);

  return { response: welcomeMessage, handleSendProactivePrompt };
}
