import $ from 'jquery';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const findParentElementMatchingClass = (el, className) => {
  let parent = el.parentNode;
  while (parent) {
    if (parent.className.indexOf(className) < 0) {
      parent = parent.parentNode;
    } else {
      break;
    }
  }
  return parent;
};

export const getMentorLLMProviderName = (llmName) => {
  if (llmName?.toLowerCase()?.includes('gpt-4')) {
    return 'gpt-4';
  }

  if (llmName?.toLowerCase()?.includes('vertex')) {
    return 'google';
  }
};

export const createDataTable = (data) => {
  let table = document.createElement('table');
  table.id = 'export-message-table';
  table.style.display = 'none';

  let content = `
        <thead>
            <tr>
                <th>Message Type</th>
                <th>Content</th>
            </tr>
        </thead>
        <body>
    `;

  data?.forEach((item) => {
    content += `<tr>
                <td>${item?.message?.type}</td>
                <td>${item?.message?.data?.content}</td>
            </tr>`;
  });

  content += `</body>`;
  table.innerHTML = content;

  document.body.appendChild(table);
};

export function exportTableToExcel(tableId) {
  const table = document.getElementById(tableId);
  const html = table.outerHTML;
  const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'table.xls';

  a.click();
  URL.revokeObjectURL(url);
  table.remove();
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export async function copyTextToClipboard(textToCopy = '') {
  try {
    if (!navigator.clipboard) {
      return false;
    }

    await navigator.clipboard.writeText(textToCopy);
    return true;
  } catch (error) {
    return false;
  }
}

export const getFileExtension = (fileName) => {
  return fileName?.split('.').pop()?.toUpperCase();
};

export const handleLayoutsWithCustomTooltipsJS = () => {
  $('.has-custom-tooltip').on('mouseover', function () {
    $(this).find('.tooltip').css({
      display: 'flex',
    });
  });
  $('.has-custom-tooltip').on('mouseout', function () {
    $(this).find('.tooltip').css({
      display: 'none',
    });
  });
};

export const handleStopBrowserStream = () => {
  if (!window.audioStreamReference) return;
  window.audioStreamReference.getAudioTracks().forEach(function (track) {
    track.stop();
  });
  window.audioStreamReference.getVideoTracks().forEach(function (track) {
    track.stop();
  });
  window.audioStreamReference = null;
};

export const mentorIsIframe = () => {
  return window.self !== window.top;
};

export const llmOrder = {
  'gpt-4o': 1,
  'gpt-4o-mini': 2,
  'llama3-70b-8192': 3,
  'llama3-8b-8192': 4,
  'gemini-1.5-pro-vision': 5,
  'gemini-1.0-pro-vision': 6,
  'claude-3-5-sonnet-20240620': 7,
  'claude-3-opus-20240229': 8,
};

export const llmProviderDisplayNames = {
  openai: 'OpenAI',
  google: 'Google',
  groq: 'Groq',
};

export const convertFirstLetterUppercase = (text) => {
  return String(text).charAt(0).toUpperCase() + String(text).slice(1);
};

const getCurrentTenant = () => {
  const tenantStr = localStorage.getItem('current_tenant');
  if (tenantStr) {
    return JSON.parse(tenantStr);
  }
};

const getTenants = () => {
  const tenantsStr = localStorage.getItem('tenants');
  if (tenantsStr) {
    return JSON.parse(tenantsStr);
  }
};

export const userIsAdmin = () => {
  const tenant = getCurrentTenant();
  if (tenant) {
    return tenant?.is_admin;
  }
};

export const canSwitchLLm = (llm) => {
  if (llm?.has_credentials) {
    return true;
  }

  return (
    llm?.can_use_main_keys &&
    (llm.main_has_credentials === true ||
      llm.main_has_credentials === undefined)
  );
};

export const userIsOnTrial = () => {
  const tenant = getCurrentTenant();
  if (tenant && getTenants()?.length === 1) {
    return tenant?.key === 'main' && tenant?.is_admin === false;
  }
  return false;
};

export const textTruncate = function (str, length, ending) {
  if (length == null) {
    length = 50;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const removeHtmlTags = (input) => {
  return String(input).replace(/<[^>]*>/g, '');
};

export const getHostFromUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
};

const data = {};

let loaded = data?.loaded ?? 'default';

export const DOCUMENTS_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.PNG',
  '.JPG',
  '.JPEG',
  '.pdf',
  '.docx',
  '.doc',
  '.rtf',
  '.csv',
  '.gif',
];
export const UPLOAD_MAX_SIZE = 1024 * 1024 * 60; //60MB

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const getDatasetFormatExtensions = {
  TXT: ['.rtf', '.txt'],
  FILE: ['.pdf', '.docx'],
  PDF: ['.pdf'],
  DOCX: ['.docx'],
  Audio: ['.mp3', '.wav'],
  Video: ['.mp4', 'webm', '.mov', '.avi', '.gif'],
  Image: ['.png', '.jpg', 'jpeg', '.webp', '.svg', '.gif'],
  Course: ['.zip', '.xz', '.gz'],
  PowerPoint: ['.ppt', '.pptx'],
  'ZIP': ['.zip'],
};

export const DEFAULT_INITIAL_MESSAGE = `I’m your helpful AI assistant. Not sure where to start? You can try:`;

export const DEFAULT_SUGGESTED_MESSAGE = `Can you help me write a research paper on the history of the Roman Empire?`;

export const handleSessionMenuPlacement = () => {
  $('.history-dropdown-toggle').on('click', function () {
    const parentEl = $(this).closest('.history-dropdown');
    const historyBoxPosition = $(this).closest('.history-element').offset();
    const parentElPosition = parentEl.position();
    parentEl
      .find('.user-menu-dropdown-block')
      .css({ top: historyBoxPosition.top + parentElPosition.top });
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const parseApiError = (responseText) => {
  try {
    const payload = JSON.parse(responseText);
    return payload.error || payload.detail;
  } catch (e) {
    return responseText;
  }
};

const getPromptNameFromText = (text) => {
  const words = text?.split(' ');
  if (words?.length >= 6) {
    return `${words.slice(0, 7).join(' ')}...`;
  }
  return text;
};

export const getPromptFromString = (text) => {
  return {
    prompt: text,
    description: text,
  };
};

export function preprocessLaTeX(content) {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  if (!content) {
    return;
  }

  // Escape currency dollar signs: if a $ is directly followed by a digit,
  // prepend a backslash so that it is rendered as a literal dollar sign.
  // Replace the regex replacement with one using a lookbehind and a function to ensure the digit group is preserved correctly.
  const escapedContent = content.replace(
    /(?<!\\)\$(\d)/g,
    (_, digit) => `\\$${digit}`
  );

  // Replace block-level LaTeX delimiters \[ \] with $$ $$.
  const blockProcessedContent = escapedContent.replace(
    /\\\[(\s*[\s\S]*?\s*)\\\]/g,
    (_, equation) => `$$${equation}$$`
  );

  // Replace inline LaTeX delimiters \( \) with $ $
  return blockProcessedContent.replace(
    /\\\(([\s\S]*?)\\\)/g,
    (_, equation) => `$${equation}$`
  );
}

export const handleFixedMainContentHeightJS = () => {
  const documentHeight = $(window).height();
  const mainContentBodyEl = $('.main-content-body');
  if (documentHeight < 786) {
    mainContentBodyEl[0].style.setProperty('height', `100%`, 'important');
    return;
  }
  let topAreaHeights = 0;
  if ($('.header').length) {
    topAreaHeights += $('.header').height();
  }
  mainContentBodyEl[0].style.setProperty(
    'height',
    `${documentHeight - topAreaHeights}px`,
    'important'
  );
};

export function isAlphaNumeric(input) {
  const regex = /^[a-zA-Z0-9 ]+$/;
  return regex.test(input);
}

export const isMobile = () => {
  if (!!window.matchMedia) {
    return window.matchMedia('(max-width: 767px)')?.matches;
  }
  return false;
};

export const isDesktop = () => {
  if (!!window.matchMedia) {
    return window.matchMedia('(min-width: 992px)')?.matches;
  }
  return false;
};

export const isLoggedIn = () => {
  return localStorage.getItem('axd_token') || false;
};

export const getTimeDifferenceBetweenTwoDates = (
  futureDate,
  pastDate,
  format = 'second'
) => {
  const targetDate = dayjs(futureDate);
  // Calculate the difference in seconds
  return targetDate.diff(pastDate, format);
};

export function getDayJSDurationObjFromSeconds(seconds) {
  if (!seconds || seconds === 0) {
    return 0;
  }
  // Create a duration object from the seconds
  return dayjs.duration(seconds, 'seconds');
}

export const generateFutureDateForNMinutes = (minute = 2) => {
  // Get the current date and time
  const currentDate = new Date();

  // Add 2 minutes (120,000 milliseconds) to the current time
  const futureDate = new Date(currentDate.getTime() + minute * 60 * 1000);

  // Format the date to ISO 8601 with microseconds and UTC offset
  const isoString = futureDate.toISOString(); // e.g., "2025-03-29T13:14:24.839Z"
  const microseconds = Math.floor(Math.random() * 1000000)
    .toString()
    .padStart(6, '0'); // Random microseconds
  return isoString.replace('Z', `${microseconds}+00:00`);
};
