import React, { useEffect } from 'react';

import $ from 'jquery';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import ThemeSwitcher from './theme-switcher';
import { useHeader } from '../../hooks/header/useHeader';
import Helpmenudropdownbox from '../../components/Helpmenudropdownbox/Helpmenudropdownbox';
import { SidebarBottomLink } from './sidebar-bottom-link';
import { userIsOnTrial } from '../../utils/shared';
import { useTriggers } from '../../hooks/navigation';

const SidebarBottomBlock = () => {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const { sidebar } = useSelector((state) => state.navigation.data);

  const platform = auth?.tenant?.key;
  const mentor = mentors?.mentor?.slug;
  const baseUrl = `/platform/${platform}/${mentor}`;
  const analyticsUrl = `${baseUrl}/analytics`;
  const promptGalleryUrl = `${baseUrl}/prompt-gallery`;
  let instructorMode = useSelector((state) => state.navigation.instructorMode);
  if (!auth?.tenant?.is_admin) {
    instructorMode = false;
  }

  const { HELP_THREE_DOT_MENU_ENABLED } = useHeader();
  const { t } = useTranslation();
  const { _handleUpdateModalStatus } = useTriggers();

  const handleJQueryScripts = () => {
    $(document).on('click', '.help-action-btn', function () {
      $(this)
        .closest('.header-help-dropdown')
        .find('.w-dropdown-list')
        .fadeIn('fast')
        .css({ display: 'flex' });
    });

    $(document).on('click', function (e) {
      if (
        $(
          '.header-help-dropdown-container.bottom-side-positionned[style*="display: flex"]'
        ) &&
        !$(e.target).closest(
          '.header-help-dropdown-container.bottom-side-positionned .help-user-menu-dropdown-block'
        ).length
      ) {
        $('.header-help-dropdown-container.bottom-side-positionned').hide();
      }
    });
  };

  useEffect(() => {
    handleJQueryScripts();
  }, []);

  let containerStyle = {
    backgroundColor: 'inherit',
    bottom: '0px',
  };

  const extraStyles = {
    width: '100%',
    alignItems: 'start',
    paddingLeft: '15px',
    paddingTop: '15px',
  };

  if (sidebar) {
    containerStyle = { ...containerStyle, ...extraStyles };
  }

  const promptGalleryLinkDetails = {
    url: promptGalleryUrl,
    label: 'Prompt Gallery',
    icon: '/images/terminal.svg',
  };

  const analyticsLinkDetails = {
    url: analyticsUrl,
    label: 'Analytics',
    icon: '/images/line-chart.svg',
  };

  const isOnTrialAndMainTenant = userIsOnTrial() && platform === 'main';

  function handleShowTrialModal() {
    _handleUpdateModalStatus('not-admin-banner-modal', true);
  }

  return (
    <div
      className="w-layout-vflex left-sidebar-bottom-block all-whiten-in-dark-mode"
      style={containerStyle}
    >
      <SidebarBottomLink {...promptGalleryLinkDetails} />
      {(isOnTrialAndMainTenant || auth?.tenant?.is_admin || instructorMode) && (
        <SidebarBottomLink
          {...analyticsLinkDetails}
          onClick={handleShowTrialModal}
          isOnTrialAndMainTenant={isOnTrialAndMainTenant}
        />
      )}
      {HELP_THREE_DOT_MENU_ENABLED && (
        <div className="w-layout-hflex header-action-btn-block info-header-action-btn-block bottom-side-positionned">
          <div
            data-hover="false"
            data-delay={0}
            className="header-help-dropdown w-dropdown"
          >
            <div
              title="Action menu"
              className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode left-sidebar-bottom-btn w-dropdown-toggle has-custom-tooltip help-action-btn"
            >
              <img
                src="/images/more-vertical.svg"
                loading="lazy"
                alt="View All Menu Items By Clicking"
                className="ai-expert-header-action-icon"
              />
              <div className="left-sidebar-bottom-btn-label">
                {t('Support Hub')}
              </div>
              <div className="tooltip tooltip-left-sidebar-bottom-btn support-zone">
                {t('Support Hub')}
              </div>
            </div>
            <Helpmenudropdownbox
              style={{ display: 'none' }}
              leftSideBarBottomPositionned={true}
            />
          </div>
        </div>
      )}
      {/* <ThemeSwitcher /> */}
    </div>
  );
};

export default SidebarBottomBlock;
