import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

import { MentorListCard } from './mentor-list-card';
import { useTriggers } from '../../hooks/navigation';
import { useGetMentorsOverviewQuery } from '../../lib/redux/mentors-overview/api-slice';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { SearchAndLoadIcon } from './SearchAndLoadIcon';

export default function MentorSearchModal() {
  const [search, setSearch] = useState('');
  const { updateAnalyticsMentor } = useAnalytics();
  const auth = useSelector((state) => state.auth.data);
  const { handleCloseMentorSearchModal } = useTriggers();

  const [debouncedSearch] = useDebounce(search, 500);

  const key = auth?.tenant?.key;
  const user_nicename = auth?.user?.user_nicename;

  const {
    data: mentors,
    isFetching,
    isLoading,
  } = useGetMentorsOverviewQuery({
    limit: 8,
    query: debouncedSearch,
    org: key,
    userId: user_nicename,
  });

  useEffect(() => {
    const closeButton = document.querySelector('.modal-close-wrapper-7');
    if (closeButton) {
      closeButton.focus();
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseMentorSearchModal();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleClick(e, mentor) {
    e.preventDefault();
    updateAnalyticsMentor({
      slug: mentor.slug,
      name: mentor.name,
      unique_id: mentor.unique_id,
    });
    handleCloseMentorSearchModal();
  }

  return (
    <div
      className="modal mentor-search-modal"
      onClick={(e) => {
        e.stopPropagation();
        handleCloseMentorSearchModal();
      }}
    >
      <div
        className="w-layout-vflex modal-container-7 search-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header-6 search-modal-header">
          <div className="w-layout-hflex flex-block-481">
            <div className="form-block-24 w-form">
              <div id="email-form-17">
                <div className="w-layout-hflex flex-block-480">
                  <div className="code-embed-23 w-embed">
                    <SearchAndLoadIcon
                      isFetching={isFetching}
                      isLoading={isLoading}
                    />
                  </div>
                  <input
                    className="text-field-13 w-input"
                    maxLength="256"
                    name="search-mentor"
                    placeholder="Search"
                    id="search-mentor-2"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            style={{ height: '40px', width: '40px' }}
            className="modal-close-wrapper-7"
            onClick={handleCloseMentorSearchModal}
          >
            <img
              loading="lazy"
              src="https://cdn.prod.website-files.com/67238084c08ebd191d57bd8e/67238084c08ebd191d57bf16_close.png"
              alt="Close Button"
            />
          </button>
        </div>
        <div className="w-layout-vflex modal-body integration-modal-body search-body">
          <div className="form-block-23 w-form">
            <div id="email-form-16">
              <div className="w-layout-vflex flex-block-479">
                {isLoading ? (
                  <IBLSpinLoader size={40} />
                ) : (
                  <>
                    {mentors?.results?.map((mentor) => (
                      <MentorListCard
                        key={mentor.id}
                        mentor={mentor}
                        onClick={handleClick}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
