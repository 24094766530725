import { useParams } from 'react-router-dom';
import usePlatformRequest from '../../hooks/platform/usePlatformRequest';
import { useSelector } from 'react-redux';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import useUserAuth from '../../hooks/user/useUserAuth';
import { toast } from 'react-hot-toast';
import { Error } from '../alerts';
import React, { useEffect } from 'react';
import BasePlatform from '../platform/base';

export default function Checkout() {
  const pathParams = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const { ready } = usePlatformRequest();
  const auth = useSelector((state) => state.auth.data);
  const { handleSubmitUpgrade } = useFreeTrial();
  const { setupUserAuth } = useUserAuth();

  const handleCheckout = async () => {
    const coupon = searchParams.get('coupon');
    const sku = pathParams.sku;

    const response = await handleSubmitUpgrade(coupon, sku);
    if (response?.error) {
      try {
        const errorResponse = JSON.parse(response?.error);
        toast.custom(Error(errorResponse?.error || errorResponse?.detail), {
          ariaProps: { role: 'alert' },
        });
      } catch (e) {
        toast.custom(Error('We could not process your request at this time.'), {
          ariaProps: { role: 'alert' },
        });
      }
      setTimeout(() => {
        window.location.assign('/');
      }, 8000);
    }
  };

  useEffect(() => {
    if (ready && auth?.tenant?.key && auth?.user?.user_nicename) {
      handleCheckout();
    }
  }, [auth]);

  useEffect(() => {
    setupUserAuth();
  }, [ready]);

  return <BasePlatform />;
}
