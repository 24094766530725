import Logo from '../header/Logo';
import CollapsedSidebarNewChat from './new-chat';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarBottomBlock from './sidebar-bottom-block';
import React from 'react';
import { useSelector } from 'react-redux';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useEmbedMode from '../../hooks/iframe/useEmbedMode';
import { useTriggers } from '../../hooks/navigation';
import { isLoggedIn } from '../../utils/shared';

export default function FullSidebar() {
  const { sidebar } = useSelector((state) => state.navigation.data);
  const embed = useEmbedMode();
  const { handleHeaderMenuToggleClick } = useTriggers();

  return (
    <>
      {
        <div
          className={`w-layout-hflex site-left-sidebar-block black-bg-dark-mode ${sidebar ? 'sidebar-opened' : ''}`}
          style={{
            width: sidebar ? '292px' : '45px',
            position: 'relative',
          }}
        >
          <div
            className="w-layout-hflex flex-block-92"
            style={{
              display: 'flex',
              maxHeight: '80px',
            }}
          >
            <div
              className="menu-toggler-v2 greyish-bg-in-darkmode"
              onClick={handleHeaderMenuToggleClick}
            >
              <img
                src="/images/align-justify.svg"
                loading="lazy"
                alt="View and Hide Recent Messages Tab"
                width="24"
                className="menu-toggler-icon whiten-in-dark-mode"
              />
              <span className="sr-only">Sidebar toggle</span>
            </div>
            <div
              className="logo-container"
              style={{ display: sidebar ? 'flex' : 'none' }}
            >
              <Logo />
            </div>
          </div>
          {!sidebar && !embed && <CollapsedSidebarNewChat />}
          <Sidebar />
          {!embed && <SidebarBottomBlock />}
        </div>
      }
    </>
  );
}
