import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../lib/redux/mentors/slice';

export default function useUserMentor() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();
  const [loadingUserRecentMentors, setLoadingUserRecentMentors] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingUserRecentMentors(true);
    setTimeout(() => {
      setLoadingUserRecentMentors(false);
    }, 20000);
    // getUserMostRecentMentors()
    //   .then((recentMentors) => {
    //     dispatch(mentorActions.userRecentMentorsUpdated(recentMentors));
    //   })
    //   .finally(() => setLoadingUserRecentMentors(false));
  }, []);

  const getUserMostRecentMentors = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/recently-accessed-mentors/`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  return { loadingUserRecentMentors, getUserMostRecentMentors };
}
