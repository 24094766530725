import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import { useSelector } from 'react-redux';
import { mentorIsIframe, userIsOnTrial } from '../../utils/shared';
import { useTriggers } from '../../hooks/navigation';

export default function FreeTrialBanner() {
  const auth = useSelector((state) => state.auth);
  const { handleUpgradeClick } = useFreeTrial(false);
  const { handleSeePricingIframeBoxClick } = useTriggers();

  const tooltip = `Upgrade to create your own mentors. No credit card required 😎`;
  const pillDisplay = `Upgrade`;
  const description = `Upgrade to create your own mentors. No credit card required 😎`;
  return (
    <>
      {
        //userIsOnTrial() && auth?.freeTrialUsage?.count && !mentorIsIframe() && (
        userIsOnTrial() && !mentorIsIframe() && (
          <TrialTopHeader
            handleClick={handleSeePricingIframeBoxClick}
            tooltipText={tooltip}
            pillDisplayText={pillDisplay}
            descriptionText={description}
            fixedClassNamesToMove=".header, .main-content"
          />
        )
      }
    </>
  );
}
