import { useSelector } from 'react-redux';

import useWelcome from '../../../hooks/chat-room/useWelcome';
import { useMentorProperties } from '../../../hooks/settings/useSettings';
import useSuggestedPrompt from '../../../hooks/chat-room/useSuggestedPrompt';

const DEFAULT_IMAGE = '/images/ai-academy-p-500.png';

export default function Welcome() {
  const mentors = useSelector((state) => state.mentors);
  const mentor = useSelector((state) => state.mentors.mentor);
  const prompts = useSelector((state) => state.chat.guidedPrompts);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { response } = useWelcome();

  const { settings } = useMentorProperties(mentors?.mentor?.unique_id);

  return (
    <div
      react-component="chatListBlock"
      className="w-layout-vflex view-middle-block"
    >
      <img
        className="agentuserprofileimage"
        src={
          mentor?.settings?.profile_image ||
          mentor?.profile_image ||
          DEFAULT_IMAGE
        }
        alt=""
        prop-data-value-src="agentImage"
        sizes="(max-width: 479px) 100vw, 48px"
        prop-attr-names="src"
        loading="lazy"
        onError={(e) => {
          e.target.src = DEFAULT_IMAGE;
        }}
      />
      <div
        prop-data-value-label="marketing research"
        prop-data-names="label"
        className="text-block-92"
        style={{ textAlign: 'center' }}
      >
        {mentor.name}
      </div>
      <div
        prop-data-value-label=""
        prop-data-names="label"
        className="text-block-93"
      >
        {mentor?.settings?.metadata?.category ?? ''}
        <br />
      </div>
      <div
        prop-data-value-label=""
        prop-data-names="label"
        className="description-and-goal whiten-in-dark-mode"
      >
        {response}
      </div>
      {settings?.enable_guided_prompts && (
        <div className="w-layout-hflex prompts-block">
          {prompts?.slice(0, 4).map((prompt, i) => (
            <button className="w-layout-vflex prompt" key={`${prompt}-${i}`}>
              <div
                className="text-block-94 whiten-in-dark-mode"
                onClick={() => {
                  handleSuggestedPromptSelection(prompt);
                }}
              >
                {prompt}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
