import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMentorLLMProviderName,
  isAlphaNumeric,
  parseApiError,
} from '../../../utils/shared';
import { toast } from 'react-hot-toast';
import useApi from '../base/useApi';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';
import { navigationActions } from '../../../lib/redux/navigation/slice';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { Error } from '../../../modules/alerts';
import { chatActions } from '../../../lib/redux/chat/slice';
import { useNavigate } from 'react-router-dom';
import useNewChat from '../../chat-room/useNewChat';

export const DEFAULT_SYSTEM_PROMPT = `You are a helpful instructor, ready to answer the student's questions. Answer quickly and concisely.  Offer to go in depth or explain with an example where necessary. Will tip you $200 if the student understands what you say. 

Given this information, help students understand  by providing explanations, examples, analogies. 
Given the data you will receive from the vector store extracted parts of a long document and a question, create a final answer. Do not tell the user how you are going to answer the question. If and ONLY if the current message from the user is  a greeting, greet back and ask them how you may help them. DO NOT needlessly keep greeting or repeating messages to the user. If the there is no data from the document or it is blank, or no chat history, do not tell the user that the document is blank and also do not tell them that you have not asked any questions  just answer normally with your own knowledge

IMPORTANT: You must ONLY reply to the current message from the user.
Always use LaTeX formatting for presenting your responses and for mathematical equations to ensure clarity when displaying to the user.`;

export const DEFAULT_MODERATION_PROMPT = `
You are a moderator tasked with identifying whether a prompt from a user is appropriate or inappropriate. Any prompt that is immoral or contains abusive words, insults, query that involve damaging content, and law breaking acts, etc should be deemed inappropriate. Otherwise it is deemed appropriate.
`;

export const DEFAULT_PROACTIVE_PROMPT = `
The user has entered the chat session. Based on the conversation history, initiate interaction with the user to keep the conversation going.
`;

export const DEFAULT_GUIDED_PROMPT = `
Generate suggested prompts for the user based on the conversation.
`;

export const DEFAULT_MODERATION_RESPONSE = `Please keep the conversation within the bounds of what the agent is tasked to do and per your platform's rules.`;

export const DEFAULT_SAFETY_PROMPT = `You are a moderator tasked with identifying whether a message from an ai model to a user is is appropriate or inappropriate. If the message is immoral or contains abusive words, insults, damaging content, and law breaking acts, etc it should be deemed inappropriate. Otherwise it is deemed appropriate.`;

export const DEFAULT_SAFETY_RESPONSE = `Sorry, the AI model generated an inappropriate response. Kindly try a different prompt.`;

export default function useCreateMentor() {
  const emptyMentor = {
    name: '',
    description: '',
    featured: true,
    system_prompt: DEFAULT_SYSTEM_PROMPT,
    moderation_system_prompt: DEFAULT_MODERATION_PROMPT,
    proactive_prompt: DEFAULT_PROACTIVE_PROMPT,
    guided_prompt_instructions: DEFAULT_GUIDED_PROMPT,
  };
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);
  const nav = useSelector((state) => state.navigation.data);
  const mentors = useSelector((state) => state.mentors);
  const mentorCategories = useSelector((state) => state.mentors.categories);
  const [mentor, setMentor] = useState(emptyMentor);
  const [mentorImgSrc, setMentorImgSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const update = useUpdateMentorSettings();
  const dispatch = useDispatch();
  const { _handleUpdateModalStatus } = useTriggers();
  const navigate = useNavigate();
  const { handleNewChatBtnClick } = useNewChat();

  const tabs = [
    { key: 'settings', title: 'Settings' },
    { key: 'prompts', title: 'Prompts' },
  ];

  const [tab, setTab] = useState('settings');

  const isMentorFormValid = () => {
    return mentor.name && mentor.description && mentor?.metadata?.category;
  };

  const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-with-settings/`;

  const handleLLMKeyCheck = async (data) => {
    const llmProviderName = getMentorLLMProviderName(data?.llm_provider);
    if (llmProviderName) {
      const url = `/api/ai-account/orgs/${auth?.tenant?.key}/credential/?name=${llmProviderName}`;
      const response = await api.get(url);
      if (!response?.data?.length > 0) {
        toast.info(
          'Your mentor does not have an LLM Key set. Please update the key'
        );
        dispatch(mentorActions.editMentorUpdated(data));
        dispatch(mentorActions.editMentorActiveTab('llm'));
        dispatch(mentorActions.editMentorLLMProviderUpdated(llmProviderName));
        dispatch(
          navigationActions.navigationUpdated({
            ...nav,
            modals: {
              ...nav.modals,
              'mentor-creation-modal': false,
              'edit-mentor-modal': true,
              'llm-set-api-key-modal': true,
            },
          })
        );
      }
    }
  };

  const handleUpdateCurrentMentorIfNeeded = (data) => {
    dispatch(mentorActions.currentMentorUpdated(data));
    navigate(
      `/platform/${auth.tenant.key}/${data.slug}${window.location.search}`
    );
    handleNewChatBtnClick();
  };

  const handleAddToLocalMentors = (data) => {
    const newMentors = [...(mentors?.mentors?.results ?? []), data];
    dispatch(
      mentorActions.mentorsUpdated({
        ...(mentors?.mentors ?? {}),
        results: newMentors,
      })
    );
  };

  const handleAddToLocalFeaturedMentors = (data) => {
    const newFeaturedMentors = [...(mentors?.featured?.results ?? []), data];
    dispatch(
      mentorActions.featuredMentorsUpdated({
        ...(mentors?.featured ?? {}),
        results: newFeaturedMentors,
      })
    );
  };

  const handleCreateMentorComplete = async (data) => {
    setMentor(emptyMentor);
    toast.success('Mentor has been successfully created', {
      ariaProps: { role: 'status' },
    });
    handleUpdateCurrentMentorIfNeeded(data);
    handleAddToLocalMentors(data);
    if (mentor?.featured) {
      handleAddToLocalFeaturedMentors(data);
    }
    await handleLLMKeyCheck(data);
    _handleUpdateModalStatus('mentor-creation-modal', false);
  };

  const handleTriggerFileUpload = () => {
    document.getElementById('input-mentor-image-upload').click();
  };

  const handleRemoveImage = () => {
    const imageInput = document.getElementById('input-mentor-image-upload');
    if (imageInput) {
      imageInput.value = '';
    }
    setMentorImgSrc(null);
  };

  const handleUpdateSettings = async (existingMentor) => {
    const form = document.createElement('form');
    const profile_image = document.getElementById('input-mentor-image-upload');
    if (profile_image?.files?.length > 0) {
      form.appendChild(profile_image);
    }
    const formData = new FormData(form);
    if (mentor.featured) {
      formData.append('mentor_visibility', 'viewable_by_tenant_students');
      formData.append('featured', 'true');
    }
    return await update.handleUpdateSettings(
      existingMentor,
      formData,
      false,
      true
    );
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files?.length > 0) {
      const mentorImgSrc = URL.createObjectURL(files[0]);
      setMentorImgSrc(mentorImgSrc);
    } else {
      setMentorImgSrc(null);
    }
  };

  const handleCreateMentor = async () => {
    if (mentor?.name.length === 0) {
      toast.error('Mentor name is required.', {
        ariaProps: { role: 'alert' },
      });
      return;
    }

    const payload = {
      ...mentor,
      description: mentor?.description,
      new_mentor_name: mentor?.name,
      metadata: mentor?.metadata,
      display_name: mentor?.name,
      template_name: process.env.REACT_APP_IBL_TEMPLATE_MENTOR,
    };
    setLoading(true);
    const response = await api.post(endpoint, payload);
    if (response?.data) {
      const settings = await handleUpdateSettings(response.data);
      const mentorData = {
        ...response.data,
        metadata: settings?.metadata,
        profile_image: settings?.profile_image,
        settings,
      };
      await handleCreateMentorComplete(mentorData);
      handleRemoveImage();
    } else {
      toast.custom(Error(parseApiError(response?.error)), {
        ariaProps: { role: 'alert' },
      });
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'category') {
      setMentor((prevState) => {
        return {
          ...prevState,
          metadata: {
            ...prevState?.metadata,
            [name]: value,
          },
        };
      });
    } else {
      setMentor((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setMentor((prevState) => {
      return { ...prevState, [name]: checked };
    });
  };

  return {
    loading,
    mentor,
    handleChange,
    handleCheckChange,
    handleCreateMentor,
    handleFileChange,
    mentorImgSrc,
    handleTriggerFileUpload,
    handleRemoveImage,
    mentorCategories,
    tab,
    setTab,
    tabs,
    setMentor,
    isMentorFormValid,
  };
}
