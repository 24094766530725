export const endpoints = {
  getPromptCategories: (args) => {
    return `/api/ai-prompt/orgs/${args.org}/users/${args.userId}/prompts/category`;
  },

  getPrompts: (args) => {
    if (args.org && args.userId) {
      return `/api/search/orgs/${args.org}/users/${args.userId}/prompts/`;
    }

    return '/api/search/prompts/';
  },

  updatePrompt: (args) => {
    return `/api/ai-prompt/orgs/${args.org}/users/${args.userId}/prompt/${args.promptId}/`;
  },

  createPrompt: (args) => {
    return `/api/ai-prompt/orgs/${args.org}/users/${args.userId}/prompt/`;
  },

  deletePrompt: (args) => {
    return `/api/ai-prompt/orgs/${args.org}/users/${args.userId}/prompt/${args.promptId}/`;
  },
};
