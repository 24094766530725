const canSwitchProvider = (mentors, name) => {
  const provider = mentors.providers.find(provider => provider.name === name);
  return !!provider.chat_models && provider.chat_models.length > 0;
}
const getDisplayName = (provider) => {
  switch (provider) {
    case "azure_openai": return "Microsoft";
    case "deepseek": return "DeepSeek";
    case "groq": return "Groq";
    case "openai": return "OpenAI";
    case "IBLChatNvidia": return "NVIDIA";
    case "perplexity": return "Perplexity";
    case "IBLChatAnthropic": return "Anthropic";
    case "google": return "Google";
    case "amazon": return "Amazon";
    case "xai": return "xAI";
    default: return provider;
  }
}
const getImageName = (provider) => {
  switch (provider) {
    case "azure_openai": return "Microsoft_Logo_512px.png";
    case "deepseek": return "deepseek.png";
    case "groq": return "images-5.png";
    case "openai": return "openai-2.svg";
    case "IBLChatNvidia": return "2945060.webp";
    case "perplexity": return "perplexity-ai-icon.webp";
    case "IBLChatAnthropic": return "Claude-AI-KVIXtY-p-500.png";
    case "google": return "google-color-svgrepo-com.svg";
    case "amazon": return "amazon-icon-1024x861-rigpcg3n.png";
    case "xai": return "lIgjSJGU_400x400.jpg";
    default: return provider;
  }
}
const loadLLMsRaw = async (auth, api, dispatch, mentorActions) => {
  const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-llms/`;
  const response = await api.get(endpoint, {});
  if (response?.data) {
    dispatch(mentorActions.providersUpdated(response.data));
  }
};
const handleChooseProvider = (provider, dispatch, mentorActions, handleOpenLLMSelectionModal) => {
  dispatch(
    mentorActions.chosenProviderUpdated(provider)
  );
  handleOpenLLMSelectionModal();
};
module.exports = {
  getDisplayName,
  getImageName,
  canSwitchProvider,
  loadLLMsRaw,
  handleChooseProvider,
}