import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { authActions } from '../../lib/redux/auth/slice';
import useAnonymousMode from '../iframe/useAnonymousMode';
import { mentorIsIframe } from '../../utils/shared';
import { useTriggers } from '../navigation';
import { Error } from '../../modules/alerts';

export default function useFreeTrial(manual = true) {
  const auth = useSelector((state) => state.auth?.data);
  const [loading, setLoading] = useState(false);
  const [usage, setUsage] = useState(null);
  const [onFreeTrial, setOnFreeTrial] = useState(false);
  const [canUseMain, setCanUseMain] = useState(true);
  const anonymous = useAnonymousMode();
  const { handleOpenFreeTrialModal } = useTriggers();

  const dispatch = useDispatch();

  const api = useApi();
  const getFreeTrialCount = async () => {
    if (anonymous || auth?.tenant?.is_admin || auth?.tenant?.key !== 'main') {
      return;
    }

    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/free-usage-count`;
    setLoading(true);
    localStorage.setItem('loadingFreeTrialCount', 'true');
    const response = await api.get(url);
    if (response?.data) {
      setUsage(response.data);
      dispatch(authActions.freeTrialUsageUpdated(response.data));
      // if (response?.data?.count < 1) {
      //   dispatch(authActions.freeTrialExhaustedUpdated(true));
      //   handleOpenFreeTrialModal();
      // }
    }

    setLoading(false);
    localStorage.removeItem('loadingFreeTrialCount');
  };

  const getFreeTrialStatus = async () => {
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/free-trial`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data?.is_in_free_trial) {
      setOnFreeTrial(true);
    }
    setLoading(false);
  };

  const getDefaultLLMUsageStatus = async () => {
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/default-llm-key-usage`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      const data = response.data;
      if (data?.use_main_credentials) {
        setCanUseMain(true);
      }
    }
    setLoading(false);
  };

  const loadFreeTrialDetails = async () => {
    if (
      auth?.tenant?.key === 'main' &&
      !auth?.tenant?.is_admin &&
      process.env.REACT_APP_IBL_ALLOW_FREE_TRIAL_BANNER === 'true'
    ) {
      await getFreeTrialCount();
      setOnFreeTrial(true);
    }
  };

  const reloadFreeTrialCount = async () => {
    if (anonymous || localStorage.getItem('loadingFreeTrialCount') === 'true') {
      return;
    }
    await getFreeTrialCount();
  };

  const getPaymentSuccessCallbackUrl = () => {
    return `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/create-organization?redirect-to=${window.location.origin}`;
  };

  const handleSubmitUpgrade = async (
    coupon = null,
    sku = null,
    bypassURL = null,
    metered = true
  ) => {
    if (!sku) {
      sku = process.env.REACT_APP_IBL_MENTOR_PRODUCT_SKU;
    }
    const payload = {
      success_url: getPaymentSuccessCallbackUrl(),
      cancel_url: `${window.location.origin}`,
      mode: 'subscription',
      sku: sku,
      metered: metered,
      metric_type: 'user_count',
    };
    if (coupon) {
      payload['coupon'] = coupon;
    }
    const url =
      bypassURL ??
      `/api/service/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/stripe/checkout-session/`;
    const response = await api.post(
      url,
      payload,
      false,
      localStorage.getItem('dm_token')
    );
    if (response?.data) {
      window.location.assign(response?.data?.redirect_to);
    } else {
      toast.custom(Error('Could not create a checkout session'), {
        ariaProps: { role: 'alert' },
      });
    }
    return response;
  };

  const handleUpgradeClick = async () => {
    handleSubmitUpgrade();
  };

  useEffect(() => {
    if (!manual && !loading && !anonymous && !mentorIsIframe()) {
      loadFreeTrialDetails();
    }
  }, [auth?.tenant]);

  return {
    loading,
    usage,
    onFreeTrial,
    canUseMain,
    handleUpgradeClick,
    getFreeTrialCount,
    reloadFreeTrialCount,
    handleSubmitUpgrade,
  };
}
