import React, { useState } from 'react';
import './Apicredentialdeletemodalbox.css';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTranslation } from 'react-i18next';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/api/base/useApi';
import toast from 'react-hot-toast';
const Apicredentialdeletemodalbox = (props) => {
  const { t } = useTranslation();
  const { handleCloseDeleteAPICredentialModal, handleApiKeyStatusUpdated } =
    useTriggers();
  const api = useApi();
  const [isDeleting, setIsDeleting] = useState(false);

  const { currentApiKey, currentApiKeyOrg } = useSelector(
    (state) => state.navigation
  );
  const deleteApiKey = () => {
    setIsDeleting(true);
    const endpoint = `/api/core/platform/api-tokens/${currentApiKey.name}?platform_key=${currentApiKeyOrg}`;
    api
      .remove(endpoint, {}, null, localStorage.getItem('dm_token'))
      .then(() => {
        handleCloseDeleteAPICredentialModal();
        toast.success(t('API key deleted successfully'), {ariaProps: { role: 'status' }});
        handleApiKeyStatusUpdated();
      })
      .finally(() => setIsDeleting(false));
  };
  return (
    <div
      className="modal delete-api-credential-modal"
      onClick={(e) => {
        e.stopPropagation();
        handleCloseDeleteAPICredentialModal();
      }}
    >
      <div
        className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Delete API Token')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleCloseDeleteAPICredentialModal}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="1860f14d-8ea7-daca-eba5-62232122c044"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    Are you sure to delete API key with the name{' '}
                    <i>{currentApiKey.name}</i>?
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  placeholder={currentApiKey.name}
                  type="text"
                  disabled={true}
                />
              </div>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  onClick={isDeleting ? () => {} : deleteApiKey}
                  data-w-id="1860f14d-8ea7-daca-eba5-62232122c04b"
                  data-testid="delete-api-key"
                  href="#"
                  label={isDeleting ? t('Deleting') : t('Delete')}
                  saving={isDeleting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apicredentialdeletemodalbox;
