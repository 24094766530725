import React from 'react';
import './Apicredentialaddmodalbox.css';
import useDatasetResourceAdd from '../../hooks/datasets/useDatasetResourceAdd';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTranslation } from 'react-i18next';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/api/base/useApi';
import toast from 'react-hot-toast';

const Apicredentialaddmodalbox = (props) => {
  const auth = useSelector((state) => state.auth.data);
  const { currentApiKeyOrg } = useSelector((state) => state.navigation);
  const {
    handleCloseAddAPICredentialModal,
    handleApiKeyStatusUpdated,
    handleNewlyCreatedApiKeyUpdated,
  } = useTriggers();
  const { t } = useTranslation();
  const [apiKeyInput, setApiKeyInput] = React.useState({
    name: '',
    key: '',
    expiration_date: '',
  });
  const [isSaving, setIsSaving] = React.useState(false);

  const handleUpdateApiKeyName = (event) => {
    const value = event.target.value;
    setApiKeyInput({ ...apiKeyInput, name: value });
  };

  const handleUpdateApiKeyExpirationDate = (event) => {
    const value = event.target.value;
    setApiKeyInput({ ...apiKeyInput, expiration_date: value });
  };

  function daysFromCurrentDate(targetDate) {
    if (!targetDate) return null;
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const daysDifference = Math.round(timeDifference / oneDay);
    return daysDifference * 24 * 3600;
  }
  const api = useApi();
  const apiKeyUrl = '/api/core/platform/api-tokens/';
  const addApiKey = () => {
    if (!apiKeyInput.name) {
      toast.error('Please enter a name for the API key', {
        ariaProps: { role: 'alert' },
      });
      return;
    }

    if (!apiKeyInput.name.match(/^[a-zA-Z0-9-]+$/)) {
      toast.error('API name has to be a slug', {
        ariaProps: { role: 'alert' },
      });
      return;
    }

    if (apiKeyInput.expiration_date) {
      const selectedDate = new Date(apiKeyInput.expiration_date);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        toast.error('Expiration date must be greater than today', {
          ariaProps: { role: 'alert' },
        });
        return;
      }
    }

    const apiKey = {
      name: apiKeyInput.name,
      platform_key: currentApiKeyOrg,
      expires_in: daysFromCurrentDate(new Date(apiKeyInput.expiration_date)),
    };
    setIsSaving(true);
    api
      .post(apiKeyUrl, apiKey, false, localStorage.getItem('dm_token'))
      .then((response) => {
        if (response?.data) {
          handleNewlyCreatedApiKeyUpdated(
            response.data.name,
            response.data.key
          );
          handleCloseAddAPICredentialModal();
          handleApiKeyStatusUpdated();

          toast.success('API key created successfully', {
            ariaProps: { role: 'status' },
          });
          return;
        }

        if (response?.error) {
          toast.error('Failed to create API key', {
            ariaProps: { role: 'alert' },
          });
          return;
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <div
      className="modal add-api-credential-modal"
      onClick={(e) => {
        e.stopPropagation();
        handleCloseAddAPICredentialModal();
      }}
    >
      <div
        className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Add API Key')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleCloseAddAPICredentialModal}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="1860f14d-8ea7-daca-eba5-62232122c044"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="api-key-name">
                    {t('Key Name (*required)')}
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  aria-label="API Key Name"
                  data-name="API Key Name"
                  id="api-key-name"
                  maxLength={256}
                  name="api-key-name"
                  placeholder="main-key"
                  onChange={handleUpdateApiKeyName}
                  type="text"
                  value={apiKeyInput.name}
                  required
                  aria-required="true"
                />
              </div>
              <div className="w-layout-hflex flex-block-19">
                <label className="form-input-label" htmlFor="expiration-date">
                  {t('Expiration Date')}
                </label>
              </div>
              <input
                className="form-input secret-key-form-input w-input"
                aria-label="Expiration Date"
                data-name="Expiration Date"
                id="expiration-date"
                name="expiration-date"
                placeholder="YYYY-MM-DD"
                onChange={handleUpdateApiKeyExpirationDate}
                value={apiKeyInput.expiration_date}
                type="date"
                aria-describedby="date-format-hint"
              />
              <span id="date-format-hint" className="sr-only">
                Date format: YYYY-MM-DD
              </span>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  data-w-id="1860f14d-8ea7-daca-eba5-62232122c04b"
                  href="#"
                  onClick={isSaving ? () => {} : addApiKey}
                  label={isSaving ? t('Saving...') : t('Submit')}
                  saving={isSaving}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apicredentialaddmodalbox;
