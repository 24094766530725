import './InstructorTabs.css';
import { useDispatch, useSelector } from 'react-redux';
import { embedActions } from '../../../lib/redux/embed/slice';
import { chatActions } from '../../../lib/redux/chat/slice';
import useSuggestedPrompt from '../../../hooks/chat-room/useSuggestedPrompt';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const InstructorTabs = ({ tabs }) => {
  const { advanceActiveTab } = useSelector((state) => state.embed);
  const { sidebar } = useSelector((state) => state.navigation.data);
  const { tabbedSessions, tabbedMessages, sequence, streaming, generating } =
    useSelector((state) => state.chat);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      tabbedSessions[advanceActiveTab] &&
      tabbedMessages[advanceActiveTab].length === 1 &&
      tabbedMessages[advanceActiveTab][0].proactive
    ) {
      window.proActiveSession = tabbedSessions[advanceActiveTab];
      handleSuggestedPromptSelection(
        tabbedMessages[advanceActiveTab][0].content
      );
    }
  }, [tabbedSessions, advanceActiveTab]);

  const _tabChanged = (event, tab) => {
    event.preventDefault();
    if (generating || streaming) {
      toast.error(t('You cannot switch tabs while streaming'), {
        ariaProps: { role: 'alert' },
      });
      return;
    }
    dispatch(embedActions.advanceActiveTabUpdated(tab.name));
    if (!tabbedSessions[tab.name]) {
      window.creatingSession = false;
      dispatch(chatActions.chatSequenceUpdated(sequence + 1));
    }
  };
  return (
    <div
      className="instructor v-flexed-instructor instructor-tab-block w-tabs"
      data-current="Tab 1"
      data-duration-in={300}
      data-duration-out={100}
      data-easing="ease"
      style={{
        height: 'unset',
        zIndex: 100,
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        width: sidebar ? 'calc(100% - 312px)' : 'calc(100% - 53px)',
      }}
      id="Instructor"
    >
      <div className="tabs-menu-2 w-tab-menu">
        {tabs.map((tab, index) => (
          <div
            key={tab.name}
            className="tab-link-tab-1-3 w-inline-block w-tab-link w--current"
            data-w-tab={`Tab ${tab.name}`}
            data-active-tab={`Tab ${advanceActiveTab}`}
            onClick={(event) => _tabChanged(event, tab)}
          >
            <div
              className="tab-names"
              data-w-id="b399d09b-d8f1-4590-152f-fbf7973ca7d7"
            >
              {tab.display}
            </div>
            <div
              className={`w-layout-blockcontainer underliner w-container`}
              style={
                advanceActiveTab !== tab.name
                  ? { backgroundColor: 'transparent' }
                  : {}
              }
            ></div>
          </div>
        ))}
      </div>

      {/* <div className="tabs-content-2 w-tab-content">
        <div data-w-tab="Tab 1" className="chattab">
          <div className="w-layout-blockcontainer tabcontainer w-container">
            {!activeTab.selectedPrompt && (
              <div
                react-component="messageListContainer"
                className="w-layout-blockcontainer message-container-top w-container"
              >
                <div
                  id="preview-chat-tab"
                  className="w-layout-vflex suggessted-assessments"
                >
                  <div
                    react-component="staticButtonsBlock"
                    className="w-layout-vflex static-buttons"
                  >
                    {activeTab.prompts.map((prompt, index) => (
                      <StaticPromptButton
                        // onSelectPrompt={this.selectPrompt.bind(this)}
                        key={index}
                        prompt={prompt.summary}
                        icon={prompt.icon}
                      />
                    ))}
                  </div>
                  {activeTab.prompts.length > 0 && (
                    <div className="w-layout-vflex guided-questions">
                      <div className="w-layout-hflex suggestions-prompt-container">
                        <img
                          loading="lazy"
                          src="images/question-primary.svg"
                          alt=""
                          className="image-68"
                        />
                        {activeTab.prompts.map((prompt, index) => (
                          <GuidedQuestionButton
                            key={index}
                            label={prompt.summary}
                          />
                        ))}
                      </div>
                      <div className="w-layout-vflex suggestion-reload-block black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="text-block-106">refresh</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default InstructorTabs;
