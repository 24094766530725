import { useDispatch, useSelector } from 'react-redux';
import { navigationActions } from '../../lib/redux/navigation/slice';
import { findParentElementMatchingClass } from '../../utils/shared';
import useApi from '../api/base/useApi';
import { toast } from 'react-hot-toast';
import { chatActions } from '../../lib/redux/chat/slice';

export default function useTriggers() {
  const nav = useSelector((state) => state.navigation?.data);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth?.data);
  const api = useApi();
  const chatExportData = useSelector(
    (state) => state.navigation?.chatHistoryExportData
  );

  const _handleUpdateMenuStatus = (menu, status) => {
    dispatch(
      navigationActions.navigationUpdated({
        ...nav,
        menus: { ...nav.menus, [menu]: status },
      })
    );
  };

  const _handleUpdateModalStatus = (modal, status) => {
    dispatch(
      navigationActions.navigationUpdated({
        ...nav,
        modals: { ...nav.modals, [modal]: status },
      })
    );
  };

  const handleOpenVectorDocuments = () => {
    dispatch(chatActions.showVectorDocumentsUpdated(true));
  };

  const _handleUpdateMultipleModalStatus = (modals) => {
    let modalStatusObj = {};
    modals.forEach((modal) => {
      modalStatusObj = {
        ...modalStatusObj,
        [modal.name]: modal.status,
      };
    });
    dispatch(
      navigationActions.navigationUpdated({
        ...nav,
        modals: { ...nav.modals, ...modalStatusObj },
      })
    );
  };

  const handleSettingMenuBtnClick = () => {
    _handleUpdateMenuStatus('settings', true);
  };

  const handleUserProfileMenuBtnClick = () => {
    _handleUpdateMenuStatus('user', true);
  };

  const handleHelpMenuBtnClick = () => {
    _handleUpdateMenuStatus('help', true);
  };

  const handleSuggestedPromptBtnClick = (status = true) => {
    _handleUpdateMenuStatus('suggestedprompt', status);
  };

  const handlePromptGalleryBtnClick = () => {
    _handleUpdateModalStatus('prompt-gallery-modal', true);
  };

  const handleFeaturedMentorClick = () => {
    _handleUpdateModalStatus('featured-mentors-modals', true);
  };

  const handleHelpMenuClick = () => {
    _handleUpdateModalStatus('help-modal', true);
  };

  const handleHelpMenuCloseClick = () => {
    _handleUpdateModalStatus('help-modal', false);
  };

  const handleFaqMenuClick = () => {
    _handleUpdateModalStatus('faq-modal', true);
  };

  const handleFaqMenuCloseClick = () => {
    _handleUpdateModalStatus('faq-modal', false);
  };

  const handleMentorsMenuBtnClick = () => {
    _handleUpdateMenuStatus('settings', false);
    _handleUpdateModalStatus('mentor-list-modal', true);
  };

  const handleLLMProviderMenuBtnClick = () => {
    _handleUpdateMenuStatus('settings', false);
    _handleUpdateModalStatus('llm-modal', true);
  };
  const handleOpenLLMProviderModal = () => {
    _handleUpdateModalStatus('llm-provider-modal', true);
  };
  const handleCloseLLMProviderModal = (withSelection) => {
    if (withSelection === true) {
      _handleUpdateModalStatus('llm-selection-modal', false);
    }
    _handleUpdateModalStatus('llm-provider-modal', false);
  };
  const handleCloseLLMSelectionModal = () => {
    _handleUpdateModalStatus('llm-selection-modal', false);
  };
  const handleOpenLLMSelectionModal = () => {
    _handleUpdateModalStatus('llm-selection-modal', true);
  };
  const handleLLMUpdateKeyBtnClick = () => {
    _handleUpdateModalStatus('llm-set-api-key-modal', true);
  };

  const handleCreateMentorBtnClick = () => {
    _handleUpdateModalStatus('mentor-creation-modal', true);
  };

  const handleAddNewPromptBtnClick = () => {
    _handleUpdateModalStatus('add-new-prompt-modal', true);
  };

  const handleOpenEditMentorModal = () => {
    _handleUpdateModalStatus('edit-mentor-modal', true);
  };

  const handleOpenPromptDetailModal = () => {
    _handleUpdateModalStatus('prompt-gallery-element-view-modal', true);
  };

  const handleOpenDatasetResourceTypes = () => {
    _handleUpdateModalStatus('add-resource-modal', true);
  };

  const handleUsersMenuBtnClick = () => {
    _handleUpdateModalStatus('users-settings-modal', true);
  };

  const handleManageAccountBtnClick = () => {
    _handleUpdateModalStatus('manage-account-modal', true);
  };

  const handleAIAudioCallBtnClick = () => {
    _handleUpdateModalStatus('ai-audio-chat-modal', true);
  };

  const handleInviteUserBtnClick = () => {
    _handleUpdateModalStatus('users-settings-invite-modal', true);
  };

  const handleShowInvitedUsersBtnClick = () => {
    _handleUpdateModalStatus('users-settings-invited-users-list-modal', true);
  };

  const handleSeeAllMentorsClick = (status = true) => {
    _handleUpdateModalStatus('mentor-list-modal', status);
  };

  const handleSeePricingIframeBoxClick = () => {
    _handleUpdateModalStatus('pricing-iframe-box', true);
  };

  const handleOpenMentorSearchModal = () => {
    _handleUpdateModalStatus('mentor-search-modal', true);
  };

  const handleCloseMentorSearchModal = () => {
    _handleUpdateModalStatus('mentor-search-modal', false);
  };

  const handleHeaderMenuToggleClick = () => {
    dispatch(
      navigationActions.navigationUpdated({ ...nav, sidebar: !nav.sidebar })
    );
  };

  const handleOpenFreeTrialModal = () => {
    _handleUpdateModalStatus('not-admin-banner-modal', true);
  };

  const handleMobileHeaderMenuToggleClick = () => {
    dispatch(
      navigationActions.navigationUpdated({
        ...nav,
        mobileSidebar: !nav.mobileSidebar,
      })
    );
  };

  const handleUserTypeSwitchChange = (event) => {
    dispatch(navigationActions.instructorModeUpdated(event.target.checked));
  };

  const handleModalCloseClick = (event) => {
    const parentModal = findParentElementMatchingClass(event.target, 'modal ');
    if (parentModal) {
      const parentModalClassNames = parentModal?.className
        ?.split(' ')
        ?.filter((cls) => cls.trim());
      const specificModalClass = parentModalClassNames[1];
      _handleUpdateModalStatus(specificModalClass, false);
    }
  };

  const handleThemeSwicherBtnClick = () => {
    const bodyEl = document.body;
    if (bodyEl.className.includes('dark-mode')) {
      bodyEl.classList.remove('dark-mode');
      dispatch(navigationActions.darkModeUpdated(false));
    } else {
      bodyEl.classList.add('dark-mode');
      dispatch(navigationActions.darkModeUpdated(true));
    }
  };

  const handleMentorToParentCollapse = () => {
    window.parent.postMessage(
      JSON.stringify({ collapseSidebarCopilot: true }),
      '*'
    );
  };

  const handleTriggerChatHistoryExportDownload = () => {
    dispatch(navigationActions.chatHistoryExportDataUpdated(null));
    toast.success('Chat history export file downloaded.', {
      autoClose: 5000,
      ariaProps: { role: 'status' }
    });
    window.open(chatExportData?.status?.url ?? chatExportData?.url, '_self');
  };

  const handleUpdateChatHistoryStatus = async () => {
    const url = `/api/reports/orgs/${auth?.tenant?.key}/ai-mentor-chat-history`;
    const response = await api.get(url);
    if (response?.data) {
      dispatch(
        navigationActions.chatHistoryExportDataUpdated(response?.data?.data)
      );
    }
  };
  const handleChangeAPICredentialBtnClick = (apiKey) => {
    dispatch(navigationActions.currentApiKeyUpdated(apiKey));
    _handleUpdateModalStatus('change-api-credential-modal', true);
  };
  const handleAddAPICredentialBtnClick = () => {
    _handleUpdateModalStatus('add-api-credential-modal', true);
  };
  const handleApiKeyStatusUpdated = () => {
    dispatch(navigationActions.apiKeyStatusUpdated(Date.now()));
  };
  const handleCurrentApiKeyOrgUpdated = (org) => {
    dispatch(navigationActions.currentApiKeyOrgUpdated(org));
  };
  const handleDeleteAPICredentialBtnClick = (apiKey) => {
    dispatch(navigationActions.currentApiKeyUpdated(apiKey));
    _handleUpdateModalStatus('delete-api-credential-modal', true);
  };
  const handleCloseChangeAPICredentialModal = () => {
    _handleUpdateModalStatus('change-api-credential-modal', false);
  };
  const handleCloseAddAPICredentialModal = () => {
    _handleUpdateModalStatus('add-api-credential-modal', false);
  };
  const handleCloseDeleteAPICredentialModal = () => {
    _handleUpdateModalStatus('delete-api-credential-modal', false);
  };
  const handleNewlyCreatedApiKeyUpdated = (name, key) => {
    dispatch(navigationActions.newlyCreatedApiKeyUpdated({ name, key }));
  };

  return {
    handleNewlyCreatedApiKeyUpdated,
    _handleUpdateModalStatus,
    handleCloseLLMProviderModal,
    handleOpenLLMProviderModal,
    handleOpenLLMSelectionModal,
    handleCloseLLMSelectionModal,
    _handleUpdateMultipleModalStatus,
    handleCurrentApiKeyOrgUpdated,
    handleApiKeyStatusUpdated,
    handleCloseAddAPICredentialModal,
    handleCloseDeleteAPICredentialModal,
    handleCloseChangeAPICredentialModal,
    handleSettingMenuBtnClick,
    handleUserProfileMenuBtnClick,
    handleHelpMenuBtnClick,
    handlePromptGalleryBtnClick,
    handleFeaturedMentorClick,
    handleMentorsMenuBtnClick,
    handleModalCloseClick,
    handleSuggestedPromptBtnClick,
    handleLLMProviderMenuBtnClick,
    handleLLMUpdateKeyBtnClick,
    handleCreateMentorBtnClick,
    handleAddNewPromptBtnClick,
    handleOpenEditMentorModal,
    handleOpenPromptDetailModal,
    handleOpenDatasetResourceTypes,
    handleUserTypeSwitchChange,
    handleUsersMenuBtnClick,
    handleManageAccountBtnClick,
    handleThemeSwicherBtnClick,
    handleAIAudioCallBtnClick,
    handleInviteUserBtnClick,
    handleShowInvitedUsersBtnClick,
    handleHeaderMenuToggleClick,
    handleSeeAllMentorsClick,
    handleMentorToParentCollapse,
    handleHelpMenuClick,
    handleHelpMenuCloseClick,
    handleFaqMenuClick,
    handleFaqMenuCloseClick,
    handleMobileHeaderMenuToggleClick,
    handleOpenFreeTrialModal,
    handleTriggerChatHistoryExportDownload,
    handleUpdateChatHistoryStatus,
    handleOpenVectorDocuments,
    handleOpenMentorSearchModal,
    handleCloseMentorSearchModal,
    handleChangeAPICredentialBtnClick,
    handleDeleteAPICredentialBtnClick,
    handleAddAPICredentialBtnClick,
    handleSeePricingIframeBoxClick,
  };
}
