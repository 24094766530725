import useMentorEmbed from '../../../hooks/api/mentors/useMentorEmbed';
import { useSelector } from 'react-redux';
import MentorEmbedWidget from './embed-widget';
import {
  handleLayoutsWithCustomTooltipsJS,
  userIsOnTrial,
} from '../../../utils/shared';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTriggers } from '../../../hooks/navigation';
import useMentorRedirectTokens from '../../../hooks/api/mentors/useMentorRedirectTokens';

export default function MentorEmbed() {
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const auth = useSelector((state) => state.auth.data);
  const { modals } = useSelector((state) => state.navigation.data);
  const embedRedirectToken = useMentorRedirectTokens();
  const {
    handleSubmit,
    handleChange,
    handleCheckChange,
    submitting,
    mentorToEmbed,
    settings,
    handleMetaDataCheckChange,
    redirectToken,
    ssoProviders,
  } = useMentorEmbed(mentor);
  const { handleOpenFreeTrialModal } = useTriggers();

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <>
      <div
        className="w-layout-vflex edit-mentor-interface-tab"
        style={{ display: 'flex' }}
      >
        <div className="course-creator-block">
          <div className="w-layout-hflex course-creator-title-block">
            <h4 className="course-creator-title">Interface Design</h4>
          </div>
          <div
            className="w-layout-hflex flex-block-29"
            style={{ alignItems: 'stretch' }}
          >
            <div
              className="course-creator-content"
              style={{ rowGap: settings?.allow_anonymous ? '44px' : '32px' }}
            >
              <div
                className="w-layout-vflex subsection-flex-block"
                style={{ display: 'none' }}
              >
                <div className="subsection-title">Logo</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="file-upload-block"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f0891"
                      aria-label="Agent Settings"
                    >
                      <div className="w-embed">
                        <input type="file" />
                      </div>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block hide">
                <div className="subsection-title">Name</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="438cbaae-22ee-02d1-d824-ce67c6caa23c"
                      aria-label="Agent Settings"
                    >
                      <input
                        className="form-input secret-key-form-input edit-mentor-settings-form-input black-bg-white-color-form-field interface-mentor-name-input w-input"
                        maxLength="256"
                        name="organisation-name-4"
                        data-name="Organisation Name 4"
                        placeholder="Mentor Name"
                        custom-value="Personal"
                        type="email"
                        id="organisation-name-4"
                      />
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block hide">
                <div className="subsection-title">Theme</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="3cc34d8f-62a0-b2dc-3723-e1f2b9759509"
                      aria-label="Agent Settings"
                    >
                      <select
                        id="theme-4"
                        name="theme-4"
                        data-name="Theme 4"
                        className="form-select w-select"
                      >
                        <option value="light">Light</option>
                        <option value="Dark">Dark</option>
                      </select>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex subsection-flex-block advanced-css-block"
                style={{ height: 'unset' }}
              >
                <div className="subsection-title">Advanced CSS</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="3c779cfa-905e-5ebe-d1a0-190683d5ea2b"
                      aria-label="Agent Settings"
                    >
                      <textarea
                        placeholder="/* CSS */"
                        maxLength="5000"
                        rows="2"
                        id="custom_css"
                        value={settings?.custom_css}
                        onChange={handleChange}
                        name="custom_css"
                        className="textarea-4 black-bg-white-color-form-field min-height-reduced w-input"
                      ></textarea>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={`w-layout-vflex flex-block-103`}
                style={{ paddingBottom: '20px' }}
              >
                <div
                  data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                  style={{ width: '100%' }}
                >
                  <div
                    className="w-layout-hflex flex-block-78"
                    style={{ width: '100%', marginTop: '5px' }}
                  >
                    <div className="form-block-10 w-form">
                      <form
                        id="wf-form-Agent-Settings"
                        name="wf-form-Agent-Settings"
                        data-name="Agent Settings"
                        method="post"
                        className="form-6"
                        data-wf-page-id="66953e194f60e4f88496390e"
                        data-wf-element-id="fc87892e-81a2-2243-0dad-bf6f777e150a"
                      >
                        <div className="select-field-container">
                          <label
                            htmlFor="mode-select"
                            className="subsection-title"
                          >
                            Mode Selection
                          </label>
                          <select
                            id="mode-select"
                            name="mode"
                            className="select-field-5 w-select"
                            onChange={handleChange}
                            aria-describedby="mode-description"
                            aria-required={false} // Set to true if the field is required
                          >
                            <option value="default">Default</option>
                            <option value="advanced">Advanced</option>
                          </select>
                          <span
                            id="mode-description"
                            className="select-field-description sr-only"
                          >
                            Choose Default for standard options or Advanced for
                            additional configuration settings.
                          </span>
                        </div>
                      </form>
                    </div>
                    <div className="tooltip tooltip-embed-switcher anonymous">
                      Setting the mode as advanced automatically makes this
                      mentor context aware
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex flex-block-20">
                <div
                  className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block horizontal-flex gap-20"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div
                    className="w-layout-hflex flex-block-19"
                    style={{ gap: '7px' }}
                  >
                    <label id="anonymous-label" className="subsection-title">
                      Anonymous
                    </label>
                    {/* <img
                      src="/images/alert-circle.svg"
                      loading="lazy"
                      height="20"
                      alt="Information icon"
                      class="context-aware-icon"
                      style={{ cursor: 'pointer' }}
                    />
                    <span id="anonymous-description" className="sr-only">
                      When enabled, your identity will be hidden from students
                      when providing feedback.
                    </span> */}
                  </div>
                  <div className="user-row-switcher-embed justify-start-flex w-embed">
                    <label className="user-switcher" htmlFor="anonymous-toggle">
                      <input
                        id="anonymous-toggle"
                        type="checkbox"
                        name="allow_anonymous"
                        onChange={handleCheckChange}
                        defaultChecked={settings?.allow_anonymous}
                        aria-labelledby="anonymous-label"
                        aria-describedby="anonymous-description"
                        role="switch"
                        aria-checked={settings?.allow_anonymous || false}
                      />
                      <span
                        className="user-switcher-slider round"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">
                        {settings?.allow_anonymous
                          ? 'Anonymous mode is enabled'
                          : 'Anonymous mode is disabled'}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="w-layout-vflex subsection-flex-block advanced-css-block">
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b29526"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">oken</div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          disabled={submitting}
                          name="generate_redirect_token"
                          onChange={handleCheckChange}
                          checked={settings.generate_redirect_token}
                          type="checkbox"
                        />
                        <span className="user-switcher-slider round"></span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher context-aware">
                      Allows to set the website the user will be redirected to
                      after authentication in the case of a non anonymous mentor
                      embeding.
                    </div>
                  </div>
                </div>
              </div> */}
              {!settings.allow_anonymous && (
                <form
                  className="manage-account-form secret-key-form edit-mentor-settings-form"
                  data-name="Email Form 2"
                  data-wf-element-id="caf6ebbd-0b06-053d-0a1e-1eebea90b02a"
                  data-wf-page-id="650305782bc61751e5765214"
                  id="email-form-2"
                  method="get"
                  name="email-form-2"
                  style={{
                    borderTop: '1px solid #eee',
                    borderBottom: '1px solid #eee',
                    paddingBottom: '12px',
                  }}
                >
                  <div className="w-layout-hflex flex-block-81">
                    <div className="w-layout-vflex flex-block-82">
                      <div className="w-layout-vflex input-block invite-user-secret-block edit-mentor-settings-form-input-block">
                        <div className="w-layout-hflex flex-block-19"></div>
                        <div className="w-layout-hflex flex-block-19">
                          <div className="subsection-title" htmlFor="name-6">
                            Website URL
                          </div>
                        </div>
                        <p style={{ color: '#ff4d4f', fontSize: '0.8rem' }}>
                          {embedRedirectToken.error}
                        </p>
                        <input
                          className="form-input secret-key-form-input edit-mentor-settings-form-input black-bg-white-color-form-field w-input"
                          data-name="Website URL"
                          id="website_url"
                          maxLength={256}
                          name="website_url"
                          defaultValue={embedRedirectToken.websiteUrl}
                          onChange={(event) => {
                            embedRedirectToken.setWebsiteUrl(
                              event.target.value
                            );
                            handleChange(event);
                          }}
                          placeholder="https://ibl.ai"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-layout-hflex flex-block-81"
                    style={{ alignItems: 'center' }}
                  >
                    <SubmitBtn
                      label={submitting ? 'Generating Token...' : 'Get Token'}
                      onClick={embedRedirectToken.handleSubmit}
                      href="#"
                      saving={embedRedirectToken.submitting}
                    />
                    {embedRedirectToken.redirectToken.length > 0 && (
                      <div
                        style={{
                          background: '#eee',
                          color: '#222',
                          height: '32px',
                          marginTop: '6px',
                          borderRadius: '12px',
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          display: 'flex',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '0.8rem',
                            lineHeight: '32px',
                            paddingRight: '12px',
                          }}
                        >
                          {embedRedirectToken.redirectToken}
                        </p>
                        <img
                          src="/images/copy.svg"
                          loading="lazy"
                          alt=""
                          className="copy-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={embedRedirectToken.handleCopyRedirectToken}
                        />
                      </div>
                    )}
                  </div>
                </form>
              )}

              {/* <div
                className={`w-layout-vflex flex-block-103 ${settings?.allow_anonymous ? 'hidden' : ''}`}
                style={{ paddingBottom: '20px' }}
              >
                <div
                  data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                  style={{ width: '100%' }}
                >
                  <div className="w-layout-hflex flex-block-104">
                    <div className="w-layout-hflex flex-block-105 has-custom-tooltip">
                      <div className="subsection-title">Website URL</div>
                      <img
                        src="/images/alert-circle.svg"
                        loading="lazy"
                        height="20"
                        alt="This is the Free trial banner information icon"
                        className="context-aware-icon"
                      />
                      <div
                        className="tooltip tooltip-embed-switcher"
                        style={{ display: 'none' }}
                      >
                        The URL of the site where the mentor will be embedded.
                      </div>
                    </div>
                  </div>

                  <div
                    className="w-layout-hflex flex-block-78"
                    style={{ width: '100%', marginTop: '5px' }}
                  >
                    <div className="form-block-10 w-form">
                      <form
                        id="wf-form-Agent-Settings"
                        name="wf-form-Agent-Settings"
                        data-name="Agent Settings"
                        method="post"
                        className="form-6"
                        data-wf-page-id="66953e194f60e4f88496390e"
                        data-wf-element-id="fc87892e-81a2-2243-0dad-bf6f777e150a"
                      >
                        <input
                          className="text-field-6 w-input"
                          id="website"
                          maxLength="256"
                          name="website_url"
                          placeholder="Website URL"
                          type="url"
                          value={settings.website_url}
                          onChange={handleChange}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}
              {!userIsOnTrial() && (
                <div
                  className="w-layout-vflex subsection-flex-block advanced-css-block"
                  style={{ height: 'unset' }}
                >
                  <div className="w-layout-vflex flex-block-103">
                    <div
                      data-w-id="075318e9-3d9f-bc8b-7154-a61793b29526"
                      className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                    >
                      <div className="w-layout-hflex flex-block-104">
                        <div className="w-layout-hflex flex-block-105">
                          <div className="subsection-title">Context Aware</div>
                          <img
                            src="/images/alert-circle.svg"
                            loading="lazy"
                            height="20"
                            alt="This is the Free trial banner information icon"
                            className="context-aware-icon"
                          />
                        </div>
                      </div>
                      <div className="user-row-switcher-embed justify-start-flex w-embed">
                        <label className="user-switcher">
                          <input
                            disabled={submitting}
                            name="is_context_aware"
                            onChange={handleCheckChange}
                            checked={settings.is_context_aware}
                            type="checkbox"
                          />
                          <span className="user-switcher-slider round"></span>
                          <span className="sr-only">
                            {settings.is_context_aware
                              ? 'Context Aware is enabled'
                              : 'Context Aware is disabled'}
                          </span>
                        </label>
                      </div>
                      <div className="tooltip tooltip-embed-switcher context-aware">
                        Allows to access the content of the page that embeds the
                        mentor. This helps the mentor gain context.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="w-layout-vflex subsection-flex-block advanced-css-block"
                style={{ height: 'unset' }}
              >
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b29532"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">
                          Safety Disclaimer
                        </div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          name="safety_disclaimer"
                          onChange={handleMetaDataCheckChange}
                          type="checkbox"
                          disabled={submitting}
                          checked={settings.metadata.safety_disclaimer}
                        />
                        <span className="user-switcher-slider round"></span>
                        <span className="sr-only">
                          {settings.metadata.safety_disclaimer
                            ? 'Safety Disclaimer is enabled'
                            : 'Safety Disclaimer is disabled'}
                        </span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher safety-disclaimer">
                      Ensures that users are informed about potential risks
                      associated with using the mentor. This provides important
                      safety information to maintain a secure experience.
                    </div>
                  </div>
                </div>
              </div>
              {/* Custom SSO */}
              <div
                className="w-layout-vflex subsection-flex-block advanced-css-block"
                style={{ height: 'unset' }}
              >
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">Single Sign On</div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          type="checkbox"
                          name="sso"
                          onChange={handleCheckChange}
                          checked={settings.sso}
                        />
                        <span className="user-switcher-slider round"></span>
                        <span className="sr-only">
                          {settings.sso
                            ? 'Single Sign On is enabled'
                            : 'Single Sign On is disabled'}
                        </span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher custom-sso">
                      Students will be asked to sign in using the selected
                      backend provider.
                    </div>
                  </div>
                  <div
                    className={`w-layout-vflex flex-block-103 ${settings?.sso ? '' : 'hidden'}`}
                  >
                    <div
                      data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                      style={{ width: '100%' }}
                    >
                      <div
                        className="w-layout-hflex flex-block-78"
                        style={{ width: '100%', marginTop: '5px' }}
                      >
                        <div className="form-block-10 w-form">
                          <form
                            id="wf-form-Agent-Settings"
                            name="wf-form-Agent-Settings"
                            data-name="Agent Settings"
                            method="post"
                            className="form-6"
                            data-wf-page-id="66953e194f60e4f88496390e"
                            data-wf-element-id="fc87892e-81a2-2243-0dad-bf6f777e150a"
                          >
                            <select
                              id="field-11"
                              name="auth_backend"
                              data-name="Field 11"
                              className="select-field-5 w-select"
                              onChange={handleChange}
                            >
                              <option value="">Select one...</option>
                              {ssoProviders.map((provider) => (
                                <option
                                  key={provider.slug}
                                  value={provider.backend_uri}
                                >
                                  {provider.slug}
                                </option>
                              ))}
                            </select>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Custom SSO END */}
              <div
                className="w-layout-vflex subsection-flex-block advanced-css-block"
                style={{ height: 'unset' }}
              >
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">Open By Default</div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          type="checkbox"
                          name="auto_open"
                          onChange={handleCheckChange}
                          checked={settings.auto_open}
                        />
                        <span className="user-switcher-slider round"></span>
                        <span className="sr-only">
                          {settings.auto_open
                            ? 'Open By Default is enabled'
                            : 'Open By Default is disabled'}
                        </span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher anonymous">
                      Checking this will open the widget automatically on load
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-layout-vflex course-creator-chatbot-block"
              style={{ height: 'unset' }}
            >
              <div
                className="ai-mentor-embed w-embed w-iframe"
                style={{ height: '100%' }}
                id="ai-mentor-embed-container"
              >
                <iframe
                  id="embed-mentor-preview"
                  title="embed-mentor-preview"
                  src={`${window.location.origin}/platform/${auth?.tenant?.key}/${mentor?.slug}?mentor=${mentor?.name}&embed=true&internalPreview=true&tenant=${auth?.tenant?.key}&mode=anonymous&chat=${settings.mode}`}
                  style={{
                    height: '100%',
                    minHeight: '580px',
                    width: '100%',
                    minWidth: '280px',
                  }}
                  frameBorder="0"
                ></iframe>
              </div>
              <div className="w-layout-hflex flex-block-76">
                <img
                  src="https://assets-global.website-files.com/650305782bc61751e5765211/650305782bc61751e576524c_ibl-logo-animated.gif"
                  loading="lazy"
                  alt=""
                  className="image-53"
                />
              </div>
            </div>
          </div>
          <div
            className="w-layout-hflex course-creator-bottom"
            style={{ justifyContent: 'end' }}
          >
            <SubmitBtn
              onClick={
                submitting || userIsOnTrial()
                  ? handleOpenFreeTrialModal
                  : handleSubmit
              }
              data-w-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f093a"
              className="section-submit-btn"
              saving={submitting}
              label={'Embed Now'}
            />
          </div>
        </div>
      </div>

      {modals?.['embed-mentor-modal'] && (
        <MentorEmbedWidget
          mentor={mentorToEmbed}
          redirectToken={redirectToken}
        />
      )}
    </>
  );
}
