import { useEffect } from 'react';

import { Track } from 'livekit-client';
import { useSelector } from 'react-redux';
import {
  GridLayout,
  LiveKitRoom,
  RoomAudioRenderer,
  useTracks,
} from '@livekit/components-react';
import '@livekit/components-styles';

import { useTriggers } from '../../../../hooks/navigation';
import { useCreateCallCredentialsMutation } from '../../../../lib/redux/call/api-slice';

const DEFAULT_IMAGE = '/images/ai-academy-p-500.png';

export function LiveKitChat() {
  const { _handleUpdateModalStatus } = useTriggers();
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat?.sessionId);
  const key = auth?.tenant?.key;
  const user_nicename = auth?.user?.user_nicename;
  const mentor_id = mentors?.mentor?.unique_id;

  const [initiateCall, { data, isLoading, isSuccess, isError }] =
    useCreateCallCredentialsMutation({
      skip: !key || !user_nicename || !mentor_id || !sessionId,
    });

  useEffect(() => {
    initiateCall({
      org: key,
      userId: user_nicename,
      mentor: mentor_id,
      body: {
        session_id: sessionId,
        pathway: mentor_id,
      },
    });
  }, []);

  // Room event handler
  const roomConnected = (room) => {
    const localParticipant = room?.localParticipant;
    if (localParticipant) {
      localParticipant.enableMicrophone();
    }
  };

  async function handleEndCall() {
    _handleUpdateModalStatus('ai-audio-chat-modal', false);
  }

  const tracks = useTracks(
    [{ source: Track.Source.Microphone, withPlaceholder: true }],
    { onlySubscribed: false }
  );

  let profileImageUrl = mentors?.mentor?.profile_image ?? DEFAULT_IMAGE;

  let label = 'Connecting...';

  if (isLoading) {
    label = 'Connecting...';
  }

  if (isSuccess) {
    label = 'Connected';
  }

  if (isError) {
    label = 'Something went wrong';
  }

  return (
    <LiveKitRoom
      serverUrl={data?.ws_url}
      token={data?.participant_token}
      video={false}
      audio={true}
      data-lk-theme="default"
      style={{ height: '100vh' }}
      roomName={data?.room_name}
      onConnected={roomConnected}
    >
      <RoomAudioRenderer />
      <div
        className="modal ai-audio-chat-modal lighter-dark-bg"
        style={{ display: 'flex' }}
        onClick={() => {
          handleEndCall();
        }}
      >
        <div
          className="w-layout-vflex modal-container ai-audio-chat-modal-container"
          onClick={(e) => e.stopPropagation()}
        >
          <GridLayout
            tracks={tracks}
            style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}
          >
            <div className="w-layout-vflex modal-body ai-audio-chat-modal-body">
              <div className="w-layout-vflex flex-block-74">
                <h4 className="audio-chat-welcome-text">{label}</h4>
                <div className="text-block-48" id="call-duration"></div>
              </div>
              <div className="w-layout-hflex audio-chat-mentor-img-container">
                <img
                  src={profileImageUrl}
                  loading="lazy"
                  alt=""
                  className="audio-chat-mentor-image"
                  onError={(e) => {
                    e.target.src = DEFAULT_IMAGE;
                  }}
                />
                <div className={`audio-circle-wave-container w-embed`}>
                  <div className="circle delay1"></div>
                  <div className="circle delay2"></div>
                  <div className="circle delay3"></div>
                  <div className="circle delay4"></div>
                </div>
              </div>
              <div className="w-layout-hflex audio-chat-animated-soundwave-container">
                <canvas
                  style={{ backgroundColor: 'transparent' }}
                  id="ai-voice-visualizer"
                ></canvas>
              </div>
              <div
                className="w-layout-hflex audio-chat-action-btn-container"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="w-layout-hflex audio-chat-action-btn audio-chat-animated-icon">
                  <img
                    src="/images/phone-hangup-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="audio-chat-action-icon"
                  />
                  <img
                    src="/images/Siri-Logo-Transparent-Free-PNG.png"
                    loading="lazy"
                    sizes="100vw"
                    srcSet="images/Siri-Logo-Transparent-Free-PNG-p-500.png 500w, images/Siri-Logo-Transparent-Free-PNG-p-800.png 800w, images/Siri-Logo-Transparent-Free-PNG.png 820w"
                    alt=""
                    className="audio-chat-mentor-img"
                  />
                </div>
                <div
                  onClick={() => {
                    handleEndCall();
                  }}
                  className="w-layout-hflex audio-chat-action-btn audio-chat-hang-action-btn"
                >
                  <img
                    src="/images/phone-hangup-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="audio-chat-action-icon display-block"
                  />
                </div>
              </div>
              <div className="html-embed w-embed">
                <ul className="audio-wave">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </GridLayout>
        </div>
      </div>
    </LiveKitRoom>
  );
}
