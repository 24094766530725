import useToolItem from '../../../hooks/tools/useToolItem';
import { useDebounce } from '../../../hooks/useDebounce';
import { handleLayoutsWithCustomTooltipsJS } from '../../../utils/shared';

export default function ToolItem({ item }) {
  const { handleToggleTool, selected } = useToolItem(item);
  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);
  return (
    <>
      <div
        key={item.slug}
        react-component="ToolsRow"
        className="w-layout-hflex tools-row-element"
      >
        <div
          data-w-id="d9fd469c-54a3-9687-7296-327b4a4fb757"
          className="w-layout-hflex tools-row-left-side has-custom-tooltip"
        >
          <img
            loading="lazy"
            src="https://uploads-ssl.webflow.com/65b7b42902a3af61e7c4fd77/65b7b42902a3af61e7c4fde5_info%20(3).svg"
            alt=""
            className="tools-row-left-side-icon"
          />
          <h5 className="assistant-form-input-label">{item.name}</h5>
          <div className="tooltip tooltip-tools-feature more-height">
            {item.description}
          </div>
        </div>
        <div className="w-layout-hflex tools-row-right-side">
          <div
            react-component="ToolsRowSwitch"
            className="html-embed-3 w-embed"
          >
            <label className="mini-switch">
              <span className="sr-only">Toggle Tool</span>
              <input
                checked={selected}
                onChange={handleToggleTool}
                type="checkbox"
              />
              <span className="mini-switch-slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="tools-block-seperator"></div>
    </>
  );
}
