import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useMentors from './useMentors';
import { useTriggers } from '../../navigation';
import { toast } from 'react-hot-toast';
import useApi from '../base/useApi';
import { useParams, useNavigate } from 'react-router-dom';
import useMentorUtils from '../../utils/useMentorUtils';
import useEmbedMode from '../../iframe/useEmbedMode';
import { Error } from '../../../modules/alerts';
import { authActions } from '../../../lib/redux/auth/slice';

export default function useSetCurrentMentor() {
  const [featured, setFeatured] = useState(true);
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const { loadingMentors, error, seedDefaultMentors } = useMentors(featured);
  const api = useApi();
  const embedPreview = useEmbedMode();

  const { _handleUpdateModalStatus } = useTriggers();
  const pathParams = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let requestedMentor = pathParams?.mentor || urlParams.get('mentor');
  const { setCurrentMentorLLM } = useMentorUtils();
  const IBL_MAIN_PLATFORM_DEFAULT_MENTOR =
    process.env.REACT_APP_IBL_MAIN_PLATFORM_DEFAULT_MENTOR || 'ai-mentor';

  const previousMentorSlugRef = useRef();

  const _setCurrentMentor = (mentor) => {
    dispatch(mentorActions.currentMentorUpdated(mentor));
    dispatch(authActions.readyUpdated(true));
    if (!window.location.pathname.includes('share')) {
      navigate(
        `/platform/${auth.tenant.key}/${mentor.slug}${window.location.search}`
      );
    }
  };

  const _getDefaultMentorFromList = (mentors) => {
    return mentors?.find((item) => item?.metadata?.default) || mentors[0];
  };

  const loadMentorPublicSettings = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${requestedMentor}/public-settings/`;
    const response = await api.get(url);
    const currentMentorSettings = mentors?.mentor?.settings ?? {};
    if (response?.data) {
      const settings = response.data;
      dispatch(
        mentorActions.currentMentorUpdated({
          name: settings?.mentor,
          slug: settings?.mentor_slug,
          unique_id: settings?.mentor_unique_id,
          suggested_prompts: settings?.suggested_prompts,
          settings: { ...currentMentorSettings, ...settings },
          proactive_prompt: settings?.proactive_prompt,
        })
      );
    } else {
      toast.custom(
        Error(`Could not load the requested mentor: ${requestedMentor}`),
        {
          ariaProps: { role: 'alert' },
        }
      );
    }
  };

  useEffect(() => {
    if (
      mentors?.mentor?.slug &&
      mentors?.mentor?.slug !== previousMentorSlugRef.current
    ) {
      requestedMentor = mentors?.mentor?.slug;
      // loadMentorPublicSettings();
    }
    previousMentorSlugRef.current = mentors?.mentor?.slug;
  }, [mentors?.mentor]);

  useEffect(() => {
    setCurrentMentorLLM();
  }, [mentors.mentor, mentors?.llms]);

  // useEffect(() => {
  //   if (
  //     !embedPreview &&
  //     requestedMentor &&
  //     !mentors?.mentor &&
  //     auth?.tenant &&
  //     auth?.user
  //   ) {
  //     loadMentorPublicSettings();
  //   }
  // }, [auth]);

  useEffect(() => {
    if (mentors?.featured?.results?.length === 0 && featured) {
      setFeatured(false);
    }
  }, [mentors?.featured]);

  const handleNoMentorsAvailable = async () => {
    const seedDefaultResponse = await seedDefaultMentors();
    if (seedDefaultResponse) {
      window.location.reload();
      console.log(
        '############### reloading window in handleNoMentorsAvailable'
      );
    } else {
      dispatch(mentorActions.initialSetupUpdated(true));
      _handleUpdateModalStatus('mentor-creation-modal', true);
      toast.info("You don't have any mentors. Please create one");
    }
  };

  useEffect(() => {
    if (mentors?.mentors?.results?.length === 0 && !mentors?.mentor) {
      if (auth?.tenant?.is_admin) {
        return handleNoMentorsAvailable();
      } else {
        window.location.assign('/no-mentors');
      }
    }
  }, [mentors?.mentors]);

  const handleSetDefaultMentor = async (mentorSlug) => {
    requestedMentor = mentorSlug;
    // await loadMentorPublicSettings();
    navigate(
      `/platform/${auth.tenant.key}/${requestedMentor}${window.location.search}`
    );
  };

  const handleUseDefaultMentor = async () => {
    let mostRecentMentors = null;
    let mostRecentForPlatform = null;

    if (auth?.tenant?.key && auth?.user?.user_nicename) {
      mostRecentMentors = mentors.userRecentMentors;
      mostRecentForPlatform = mostRecentMentors?.find(
        (item) => item.platform_key === auth?.tenant?.key
      );
    }
    if (mostRecentForPlatform) {
      await handleSetDefaultMentor(mostRecentForPlatform.slug);
      return;
    }
    if (mentors?.featured?.results?.length > 0 && !requestedMentor) {
      if (auth?.tenant?.key === 'main' && !auth?.tenant?.is_admin) {
        await handleSetDefaultMentor(IBL_MAIN_PLATFORM_DEFAULT_MENTOR);
      } else {
        _setCurrentMentor(
          _getDefaultMentorFromList(mentors?.featured?.results)
        );
      }
    } else if (mentors?.mentors?.results?.length > 0 && !requestedMentor) {
      _setCurrentMentor(mentors?.mentors?.results[0]);
    }
  };

  useEffect(() => {
    if (!mentors?.mentor) {
      handleUseDefaultMentor();
    }
  }, [mentors]);

  return { loadingMentors, error };
}
