export function queryParams(route, args) {
  let _args = { ...args };
  let url = route(_args);

  if (!url.includes('clean-vector-results')) {
    url += `?${new URLSearchParams(_args).toString()}`;
  }

  return url;
}
