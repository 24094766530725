import useDatasetTypes from '../../../../hooks/datasets/useDatasetTypes';
import { userIsOnTrial } from '../../../../utils/shared';
import { useTriggers } from '../../../../hooks/navigation';

export default function DatasetResourceTypesModal({}) {
  const { datasetTypes, getInActiveStatus, handleSelect } = useDatasetTypes();

  const {
    handleOpenFreeTrialModal,
    handleModalCloseClick,
    _handleUpdateModalStatus,
  } = useTriggers();

  const handleClickDatasetType = (item) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    if (item?.active) {
      handleSelect(item.type);
    }
  };

  return (
    <div
      react-component="DatasetResourcesModalBox"
      className="modal add-resource-modal"
      onClick={(e) => {
        e.stopPropagation();
        _handleUpdateModalStatus('add-resource-modal', false);
      }}
    >
      <div
        className="w-layout-vflex modal-container new-resource-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Add Resource</h3>
          <div
            prop-events-value-onclick="handleModalCloseClick"
            prop-events-names="onClick"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            className="modal-close-wrapper"
            onClick={handleModalCloseClick}
          >
            <img
              src="/images/close_1close.png"
              loading="lazy"
              alt="Close Button Image"
            />
          </div>
        </div>
        <div className="w-layout-vflex modal-body dataset-resource-modal-body">
          <div className="w-layout-hflex flex-block-485">
            <div className="text-block-225">
              Add knowledge to help your agent provide more relevant insights.
              Others with edit access can reuse these sources for more topics.
            </div>
            <div
              className="w-layout-hflex flex-block-486"
              style={{ display: 'none' }}
            >
              <div className="code-embed-24 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#969696"
                >
                  <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                </svg>
              </div>
              <div className="form-block-25 w-form">
                <form
                  id="email-form-17"
                  name="email-form-17"
                  data-name="Email Form 17"
                  method="get"
                  data-wf-page-id="672a4c49d8fa05e0362eced4"
                  data-wf-element-id="7e3422cc-a7a1-2ce6-626f-f5e191c993c8"
                  aria-label="Email Form 17"
                >
                  <input
                    className="text-field-14 w-input"
                    maxLength="256"
                    name="search"
                    data-name="search"
                    placeholder="Search"
                    type="email"
                    id="search"
                    required=""
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="w-layout-grid grid-3">
            {datasetTypes?.map((item) => (
              <div
                key={item.type}
                onClick={() => handleClickDatasetType(item)}
                className={`w-layout-hflex resource-card datasource-element-picker ${getInActiveStatus(item)}`}
              >
                <img
                  src={item.img_url}
                  loading="lazy"
                  alt=""
                  className="image-184"
                />
                <div>{item.type}</div>
              </div>
            ))}
          </div>
          <div className="w-layout-vflex data-resource-upload-box-block"></div>
        </div>
      </div>
    </div>
  );
}
