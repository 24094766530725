import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import navigationSlice from './navigation/slice';
import authSlice from './auth/slice';
import mentorSlice from './mentors/slice';
import chatSlice from './chat/slice';
import promptSlice from './prompts/slice';
import usersSlice from './users/slice';
import embedReducer from './embed/slice';
import analyticsReducer from './analytics/slice';
import { analyticsApiSlice } from './analytics/api-slice';
import { mentorsOverviewApiSlice } from './mentors-overview/api-slice';
import { promptsApiSlice } from './prompts/api-slice';
import { sentryReduxEnhancer } from '@iblai/ibl-web-react-common/sentry';
import { documentApiSlice } from './documents/api-slice';
import { callApiSlice } from './call/api-slice';

const appReducer = combineReducers({
  navigation: navigationSlice,
  auth: authSlice,
  mentors: mentorSlice,
  chat: chatSlice,
  prompts: promptSlice,
  users: usersSlice,
  embed: embedReducer,
  analytics: analyticsReducer,
  [analyticsApiSlice.reducerPath]: analyticsApiSlice.reducer,
  [mentorsOverviewApiSlice.reducerPath]: mentorsOverviewApiSlice.reducer,
  [promptsApiSlice.reducerPath]: promptsApiSlice.reducer,
  [documentApiSlice.reducerPath]: documentApiSlice.reducer,
  [callApiSlice.reducerPath]: callApiSlice.reducer,
});

const apiSliceMiddleware = [
  analyticsApiSlice.middleware,
  mentorsOverviewApiSlice.middleware,
  promptsApiSlice.middleware,
  documentApiSlice.middleware,
  callApiSlice.middleware,
];

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers();
  },
  middleware: (getDefaultMiddleware) =>
    process.env.ENABLE_REDUX_LOGGING
      ? getDefaultMiddleware().concat(logger, apiSliceMiddleware)
      : getDefaultMiddleware().concat(apiSliceMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});
