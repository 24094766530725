import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MentorSettings,
  MentorLLM,
  MentorPrompts,
  MentorDataset,
  MentorEmbed,
  MentorTools,
  MentorHistory,
  MentorSafety,
  MentorFlow,
  MentorAPI,
} from '../../modules/mentors/edit';
import { useTriggers } from '../../hooks/navigation';
import useEditMentorTabs from '../../hooks/api/mentors/useEditMentorTabs';

import './Editmentormodalbox.css';
import useMentorDetails from '../../hooks/api/mentors/useMentorDetails';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { mentorsOptionsRoutes } from '../Header/options';
import { useEmbedParams } from '../../hooks/utils/useEmbedParams';

const Editmentormodalbox = (props) => {
  const modalRef = React.useRef(null);
  const { t } = useTranslation();
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const auth = useSelector((state) => state.auth.data);
  const { handleModalCloseClick, _handleUpdateModalStatus } = useTriggers();
  const { tab, tabs, setTab } = useEditMentorTabs();
  const { loadMentorSettings } = useMentorDetails();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mentors = useSelector((state) => state.mentors);
  const [isPage, setIsPage] = useState(false);
  const { modal } = useEmbedParams();

  const platform = auth?.tenant?.key;
  const mentor = mentors?.mentor?.slug;
  const baseUrl = `/platform/${platform}/${mentor}`;

  const handleSetMentorForEdit = async () => {
    const mentorSettings = await loadMentorSettings(
      auth?.tenant.key,
      editMentor.unique_id
    );
    if (mentorSettings) {
      const editMentorFullPayload = {
        ...editMentor,
        allow_anonymous: mentorSettings.allow_anonymous,
        system_prompt: mentorSettings.system_prompt,
        safety_system_prompt: mentorSettings.safety_system_prompt,
        safety_response: mentorSettings.safety_response,
        moderation_system_prompt: mentorSettings.moderation_system_prompt,
        moderation_response: mentorSettings.moderation_response,
        guided_prompt_instructions: mentorSettings.guided_prompt_instructions,
        enable_guided_prompts: mentorSettings?.enable_guided_prompts,
        settings: mentorSettings,
      };
      dispatch(mentorActions.editMentorUpdated(editMentorFullPayload));
    }
  };

  useEffect(() => {
    handleSetMentorForEdit();
  }, []);

  useEffect(() => {
    const tabFromPath = pathname.split('/').pop();

    const foundPage = mentorsOptionsRoutes.find((item) => item === tabFromPath);
    if (foundPage) {
      setIsPage(true);
      setTab(tabFromPath);
    }
  }, []);

  useEffect(() => {
    const closeButton = document.querySelector('.modal-close-wrapper');
    if (closeButton) {
      closeButton.focus();
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        _handleUpdateModalStatus('edit-mentor-modal', false);
        if (isPage) {
          navigate(baseUrl);
        }
      }
    };

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isPage, navigate, baseUrl, _handleUpdateModalStatus]);

  return (
    <div
      ref={modalRef}
      className="modal edit-mentor-modal"
      onClick={(e) => {
        e.stopPropagation();
        if (isPage) {
          navigate(baseUrl);
        }
        _handleUpdateModalStatus('edit-mentor-modal', false);
      }}
    >
      <div
        style={
          [
            'settings',
            'datasets',
            'llm',
            'prompts',
            'embed',
            'tools',
            'history',
            'safety',
            'flow',
            'api',
          ].includes(modal)
            ? {
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '0',
                height: '100%',
                width: '100%',
              }
            : {}
        }
        className={classnames(
          'w-layout-vflex modal-container prompt-gallery-container edit-mentor-modal-container',
          {
            'custom-prompt-gallery-container': tab === 'prompts',
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {!modal && (
          <div className="w-layout-hflex modal-header">
            <h3 className="modal-header-title">{t('Edit Mentor')}</h3>
            <a className="default-btn prompt-add-btn w-button" href="#">
              {t(' + Add New Prompt')}
            </a>
            <button
              style={{ height: '40px', width: '40px' }}
              className="modal-close-wrapper"
              data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
              onClick={(event) => {
                // close modal and route
                handleModalCloseClick(event);

                if (isPage) {
                  navigate(baseUrl);
                  return;
                }
              }}
              prop-events-value-onclick="handleModalCloseClick"
            >
              <img alt="" loading="lazy" src="/images/close_1close.png" />
            </button>
          </div>
        )}
        <div
          className="w-layout-vflex modal-body all-whiten-in-dark-mode llm-modal-body"
          style={modal && { justifyContent: 'flex-start' }}
        >
          <div className="edit-mentor-tabs w-tabs">
            {!modal && (
              <div
                role="tablist"
                aria-label="Mentor settings tabs"
                className="tabs-menu-2 w-tab-menu"
                style={{ marginTop: '10px', marginBottom: '20px' }}
              >
                {tabs?.map((item) => (
                  <button
                    className={`edit-mentor-tabs-menu-link-2 w-inline-block w-tab-link ${item.key === tab ? 'w--current' : ''}`}
                    data-w-id="ca621093-7a00-bd53-ecc7-48d2e28e9363"
                    role="tab"
                    key={`tab-${item.key}`}
                    onClick={(event) => {
                      event.preventDefault();
                      setTab(item.key);
                    }}
                  >
                    <div>{item.title}</div>
                  </button>
                ))}
              </div>
            )}
            <div
              className="w-layout-vflex edit-mentor-tabs-container tabs-content-2 w-tab-content"
              style={{ padding: '10px' }}
            >
              {tab === 'settings' && <MentorSettings />}
              {tab === 'llm' && <MentorLLM />}
              {tab === 'prompts' && <MentorPrompts />}
              {tab === 'datasets' && <MentorDataset />}
              {tab === 'embed' && <MentorEmbed />}
              {tab === 'tools' && <MentorTools />}
              {tab === 'history' && <MentorHistory />}
              {tab === 'safety' && <MentorSafety />}
              {tab === 'flow' && <MentorFlow />}
              {tab === 'api' && <MentorAPI />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editmentormodalbox;
