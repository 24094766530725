import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Error404 from './error404';
import Error403 from './error403';
import { Error503 } from '@iblai/ibl-web-react-common';
import { redirectToAuth } from '../../utils/auth';

export const ErrorBoundary = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 500) {
      return <Error503 />;
    } else if (error.status === 401) {
      console.log('############### redirectToAuth in error-boundary');
      redirectToAuth();
    }
  }
};

export const ProductPageErrorBoundary = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 500) {
      return <Error503 />;
    } else if (error.status === 401) {
      localStorage.setItem(
        'redirect-path',
        `${window.location.pathname}${window.location.search ? window.location.search : '?no-cache=true'}`
      );
      window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?enforce-login=1&redirect-to=${window.location.href}`;
    }
  }
};
