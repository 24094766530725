import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

export function SidebarBottomLink({
  url,
  label,
  icon,
  onClick,
  isOnTrialAndMainTenant,
}) {
  const location = useLocation();
  const pathname = location.pathname;
  const { sidebar } = useSelector((state) => state.navigation.data);
  const [isHovered, setIsHovered] = React.useState(false);

  const commonProps = {
    className: classnames('w-inline-block', {
      'w--current': !onClick && pathname.startsWith(url),
    }),
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  const component = (
    <div
      className={classnames(
        'w-layout-hflex left-sidebar-bottom-btn greyish-bg-in-darkmode',
        { active: pathname.startsWith(url) }
      )}
      id="06c1888a-8d96-8883-4b16-0b16fe050ca3"
      data-testid="sidebar-bottom-link"
    >
      <img
        loading="lazy"
        src={icon}
        alt={label}
        className="left-sidebar-bottom-btn-icon-2"
        data-testid="sidebar-bottom-link-icon"
      />
      <div
        className="tooltip tooltip-left-sidebar-bottom-btn help"
        style={{ display: isHovered && !sidebar ? 'block' : 'none' }}
        data-testid="sidebar-bottom-link-tooltip"
      >
        {label}
      </div>
      <div
        className="left-sidebar-bottom-btn-label"
        data-testid="sidebar-bottom-link-label"
      >
        {label}
      </div>
    </div>
  );

  if (onClick && isOnTrialAndMainTenant) {
    return (
      <div {...commonProps} onClick={onClick}>
        <span className="sr-only">Show upgrade package modal</span>
        {component}
      </div>
    );
  }

  return (
    <Link {...commonProps} to={url}>
      <span className="sr-only">Navigate to {label} Page</span>
      {component}
    </Link>
  );
}
