import { createApi } from '@reduxjs/toolkit/query/react';

import { endpoints } from './endpoints';
import { queryParams } from './query-params';
import { fetchBaseQuery } from '../fetch-base-query';

export const documentApiSlice = createApi({
  reducerPath: 'documentApiSlice',

  baseQuery: fetchBaseQuery,

  tagTypes: ['Documents'],

  endpoints: (builder) => ({
    getVectorDocuments: builder.query({
      query: (args) => queryParams(endpoints.vectorDocuments, args),
    }),
  }),
});

export const { useGetVectorDocumentsQuery } = documentApiSlice;
