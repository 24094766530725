import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Error } from '../../modules/alerts';
import { chatActions } from '../../lib/redux/chat/slice';

export default function useShareChat(sessionId) {
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const api = useApi();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const loadSharedChat = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/${sessionId}?share=true`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      setData(response.data);
      dispatch(chatActions.shareUpdated(response.data));
    } else {
      setError(response?.error?.toString());
      toast.custom(
        Error('Could not load shared chat!', {
          autoClose: 100000,
          ariaProps: { role: 'alert' },
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!data && !error && !loading) {
      loadSharedChat();
    }
  }, [loading]);

  return { loading, data, error };
}
