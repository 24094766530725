import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import { Error } from '../../../modules/alerts';
import { userIsOnTrial } from '../../../utils/shared';

export default function useGetCredentials() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleFetchCredentials = async (
    type,
    notify = true,
    isForm = false
  ) => {
    if (userIsOnTrial()) {
      return;
    }
    const endpoint = `/api/ai-account/orgs/${auth?.tenant?.key}/integration-credential/?name=${type}`;
    const response = await api.get(endpoint, isForm);
    if (response?.data) {
      if (notify) {
        toast.success('credentials has been fetched!', {
          ariaProps: { role: 'status' },
        });
      }
      return response?.data;
    } else {
      if (notify) {
        toast.custom(Error(response?.error), {
          ariaProps: { role: 'alert' },
        });
      }
    }
  };

  return { handleFetchCredentials };
}
