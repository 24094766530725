import { RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  api,
  AppDataContext,
  inIframe,
  sentryCreateBrowserRouter,
} from '@iblai/ibl-web-react-common';
import '@iblai/ibl-web-react-common/index.css';
import { Helmet } from 'react-helmet';
import { LiveKitRoom } from '@livekit/components-react';
import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import { store } from './lib/redux/store';
import './i18n';
import { routes } from './routes';
import { EmbedCustomStyles, GlobalStyles } from './utils/styles';
import {
  initiateAppContextData,
  notifyParentOnTokenExpiry,
} from './utils/auth';

const handleTokenMessage = (tokenData) => {
  Object.entries(tokenData).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
  if (!localStorage.getItem('current_tenant')) {
    const tenants = JSON.parse(localStorage.getItem('tenants'));
    const selectedTenant = localStorage.getItem('tenant');
    api.iblutils.saveUserTenantsDataToLocalStorage(tenants, selectedTenant);
  }
  console.log('############### reloading window in handleTokenMessage');
  window.location.reload();
};

export const handleIframeMessageEvent = (event) => {
  try {
    const dataStr = event?.data;
    const data = JSON.parse(dataStr);
    if (data?.axd_token) {
      handleTokenMessage(data);
    }
  } catch (e) {}
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessageEvent);
    return () => {
      window.removeEventListener('message', handleIframeMessageEvent);
    };
  }, []);

  useEffect(() => {
    if (inIframe()) {
      api.setGlobalHandlers({
        errors: {
          401: (redirectUrl) => {
            notifyParentOnTokenExpiry();
          },
        },
      });
    }
  }, []);

  const gsite_verif_content =
    process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_CONTENT;

  return (
    <>
      <Helmet>
        {gsite_verif_content && (
          <meta name="google-site-verification" content={gsite_verif_content} />
        )}
      </Helmet>
      <GlobalStyles />
      <EmbedCustomStyles />
      <LiveKitRoom
        serverUrl={process.env.REACT_APP_LIVEKIT_SERVER_URL}
        token={process.env.REACT_APP_LIVEKIT_TOKEN}
        video={false}
        audio={true}
        roomName="test-room"
      >
        <ReduxProvider store={store}>
          <AppDataContext.Provider
            value={{ appData: { ...appData }, setAppData }}
          >
            <RouterProvider
              router={sentryCreateBrowserRouter(routes(appData, setAppData))}
            />
          </AppDataContext.Provider>
        </ReduxProvider>
      </LiveKitRoom>
    </>
  );
}

export default App;
