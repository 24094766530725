import { toast } from 'react-hot-toast';
import useAnonymousMode from './useAnonymousMode';
import useApi from '../api/base/useApi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Error } from '../../modules/alerts';

export default function useAnonymousMentor() {
  const anonymous = useAnonymousMode();
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const mentor = pathParams?.mentor || urlParams.get('mentor');
  const tenant = pathParams?.platform || urlParams.get('tenant');
  const api = useApi();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (anonymous && !mentor) {
  //     toast.custom(Error('No mentor specified'));
  //   }
  // }, [anonymous, mentor]);

  const loadExtraBodyClasses = () => {
    if (urlParams.get('extra-body-classes')) {
      let extraBodyClasses = urlParams.get('extra-body-classes');
      extraBodyClasses = extraBodyClasses.split(',');
      extraBodyClasses.forEach((className) => {
        document?.body?.classList?.add(className);
      });
    }
  };

  const getMentorPublicSettings = async (platformKey, mentorId) => {
    const url = `/api/ai-mentor/orgs/${platformKey}/users/anonymous/mentors/${mentorId}/public-settings/`;
    setLoading(true);
    const response = await api.get(url);
    return response?.data;
  };

  useEffect(() => {
    loadExtraBodyClasses();
  }, []);

  useEffect(() => {
    if (!mentor && tenant) {
      loadExtraBodyClasses();
    }
  }, []);

  return { loading, getMentorPublicSettings };
}
