import { createSlice } from '@reduxjs/toolkit';

export const mentorsInitialState = {
  mentor: null,
  userRecentMentors: null,
  llms: null,
  providers: null,
  chosenProvider: null,
  tools: null,
  categories: [],
  selectedLLM: null,
  mentors: null,
  featured: null,
  edit: {
    mentor: null,
    activeTab: null,
    llmProvider: '',
  },
  mentorToView: null,
  initialSetup: false,
  dataset: {
    type: null,
  },
  userSelectedMentor: false,
  currentMentorLLM: null,
  mentorSettings: {
    settings: {},
    refreshSettingsCount: 0,
  },
};

export const mentorSlice = createSlice({
  name: 'mentors',
  initialState: mentorsInitialState,
  reducers: {
    reset: () => mentorsInitialState,
    mentorsUpdated: (state, action) => {
      state.mentors = action.payload;
    },
    userRecentMentorsUpdated: (state, action) => {
      state.userRecentMentors = action.payload;
    },
    editMentorUpdated: (state, action) => {
      state.edit.mentor = action.payload;
    },
    editMentorLLMProviderUpdated: (state, action) => {
      state.edit.llmProvider = action.payload;
    },
    editMentorActiveTab: (state, action) => {
      state.edit.activeTab = action.payload;
    },
    datasetUpdated: (state, action) => {
      state.dataset = action.payload;
    },
    initialSetupUpdated: (state, action) => {
      state.initialSetup = action.payload;
    },
    featuredMentorsUpdated: (state, action) => {
      state.featured = action.payload;
    },
    currentMentorUpdated: (state, action) => {
      state.mentor = action.payload;
    },
    llmsUpdated: (state, action) => {
      state.llms = action.payload;
    },
    providersUpdated: (state, action) => {
      state.providers = action.payload;
    },
    selectedLLMUpdated: (state, action) => {
      state.selectedLLM = action.payload;
    },
    userSelectedMentorUpdated: (state, action) => {
      state.userSelectedMentor = action.payload;
    },
    mentorToViewUpdated: (state, action) => {
      state.mentorToView = action.payload;
    },
    mentorsCategoriesUpdated: (state, action) => {
      state.categories = action.payload;
    },
    currentMentorLLMUpdated: (state, action) => {
      state.currentMentorLLM = action.payload;
    },
    toolsUpdated: (state, action) => {
      state.tools = action.payload;
    },
    chosenProviderUpdated: (state, action) => {
      state.chosenProvider = action.payload;
    },
    mentorSettingsUpdated: (state, action) => {
      state.mentorSettings.settings = action.payload;
    },
    refreshMentorSettings: (state) => {
      state.mentorSettings.refreshSettingsCount += 1;
    },
  },
});

export const mentorActions = mentorSlice.actions;
export default mentorSlice.reducer;
