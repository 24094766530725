export function queryParams(route, args) {
  let _args = { ...args };
  let url = route(_args);

  //   delete _args.org;
  //   delete _args.userId;

  url += `?${new URLSearchParams(_args).toString()}`;

  return url;
}
