import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHeader } from '../../hooks/header/useHeader';
import useIframeLogo from '../../hooks/iframe/useIframeLogo';
import { BASE_API_URL } from '../../utils/api';
import useNewChat from '../../hooks/chat-room/useNewChat';
import './Logo.css';

export default function Logo() {
  const darkMode = useSelector((state) => state.navigation.darkMode);
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const { USE_SPECIAL_IFRAME_LOGO, logoSize } = useHeader();
  const iframeLogo = useIframeLogo();
  const [logoUrl, setLogoUrl] = React.useState('');
  const [ready, setReady] = React.useState(false);
  const [defaultLogoInUse, setDefaultLogoInUse] = useState(false);
  const { handleNewChatBtnClick } = useNewChat();

  const loadLogo = async () => {
    let logoImgUrl = darkMode ? '/images/logo-dark.gif' : '/images/logo.gif';
    const tenant = auth?.tenant?.key;
    if (tenant !== 'main') {
      const url = `${BASE_API_URL}/api/core/orgs/${tenant}/${darkMode ? 'dark-mode-logo' : 'logo'}/`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          logoImgUrl = url;
        } else {
          setDefaultLogoInUse(true);
        }
      } catch (e) {
        setDefaultLogoInUse(true);
      }
    } else {
      setDefaultLogoInUse(true);
    }

    setLogoUrl(logoImgUrl);
    setReady(true);
  };

  useEffect(() => {
    if (!USE_SPECIAL_IFRAME_LOGO) {
      loadLogo();
    }
  }, [darkMode]);

  return (
    <div
      onClick={handleNewChatBtnClick}
      className="w-layout-hflex"
      style={{ alignItems: 'center', cursor: 'pointer' }}
    >
      {(ready || USE_SPECIAL_IFRAME_LOGO) && (
        <img
          src={USE_SPECIAL_IFRAME_LOGO ? iframeLogo : logoUrl}
          loading="lazy"
          width="Auto"
          sizes="100vw"
          alt="mentorAI logo"
          className={`image-58 ${defaultLogoInUse ? 'default-mentor-logo' : ''}`}
          style={{
            width: `${logoSize}px`,
            borderRadius: USE_SPECIAL_IFRAME_LOGO ? '50%' : '0',
          }}
        />
      )}

      {/* {USE_SPECIAL_IFRAME_LOGO && (
        <div
          className="logo-title"
          style={{ marginLeft: '10px', fontWeight: 'bold' }}
        >
          {mentors?.mentor?.name}
        </div>
      )} */}
    </div>
  );
}
