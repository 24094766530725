import { useDispatch, useSelector } from 'react-redux';

import { chatActions } from '../../../lib/redux/chat/slice';
import { capitalizeFirstLetter, isLoggedIn, textTruncate } from '../../../utils/shared';
import { useGetVectorDocumentsQuery } from '../../../lib/redux/documents/api-slice';

const DocumentItem = ({ item }) => {
  return (
    <div className="w-layout-vflex retrieved-card">
      <div className="w-layout-hflex flex-block-195">
        <div className="w-layout-hflex flex-block-197">
          <img
            src="/images/radio_button_unchecked_24dp_004CE0_FILL0_wght400_GRAD0_opsz24_1radio_button_unchecked_24dp_004CE0_FILL0_wght400_GRAD0_opsz24.png"
            loading="lazy"
            width="18"
            alt=""
          />
          <div className="text-block-66 text-inline" style={{ width: '80%' }}>
            <a href={item.source} target="_blank" rel="noopener noreferrer">
              {capitalizeFirstLetter(item.title)}
            </a>
          </div>
          <div className="retrieved-doc-score-2">{item.score?.toFixed(2)}</div>
        </div>
        <div
          className="w-layout-hflex flex-block-196"
          style={{ display: 'none' }}
        >
          <label className="w-checkbox checkbox-field-4">
            <input
              type="checkbox"
              name="checkbox-4"
              id="checkbox-4"
              data-name="Checkbox 4"
              className="w-checkbox-input checkbox-2"
            />
            <span
              className="checkbox-label-3 w-form-label"
              htmlFor="checkbox-4"
            >
              Select
            </span>
          </label>
        </div>
      </div>
      <div
        className="w-layout-hflex retrieved-doc-box-tag-block"
        style={{ display: 'none' }}
      >
        <div className="w-layout-hflex retrieved-doc-box-tag-box-2">
          <img
            loading="lazy"
            src="/images/tag-2.svg"
            alt=""
            className="image-74 hidden"
          />
          <div className="retrieved-doc-box-tag"></div>
        </div>
      </div>
      <p className="paragraph-7">{textTruncate(item.snippet, '300')}</p>
    </div>
  );
};

export default function DocumentRetrieve() {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth?.data);
  const sessionId = useSelector((state) => state.chat?.sessionId);

  const { data: documents, isLoading } = useGetVectorDocumentsQuery(
    {
      sessionId,
      org: auth?.tenant?.key,
      userId: auth?.user?.user_nicename,
    },
    {
      skip: !sessionId || !isLoggedIn(),
    }
  );

  const handleToggleShowVectorDocuments = (status) => {
    dispatch(chatActions.showVectorDocumentsUpdated(status));
  };

  return (
    <div
      className={`w-layout-vflex retrieved-doc-column ${chat?.showVectorDocuments ? 'vector-documents-opened' : ''}`}
      data-testid="doc-column"
      style={{
        width: chat?.showVectorDocuments ? '600px' : '50px',
        transition: 'width 0.3s',
      }}
    >
      <div className="w-layout-hflex flex-block-194">
        <div className="w-layout-hflex flex-block-198">
          <img
            src="/images/file-text-3.svg"
            loading="lazy"
            width="25"
            height="25"
            alt=""
            onClick={() => handleToggleShowVectorDocuments(true)}
            className="show-retrieved-doc-column-button cursor-pointer"
            data-testid="toggle-button"
          />
          {chat?.showVectorDocuments && (
            <div className="retrieved-doc-title">Retrieved Documents</div>
          )}
        </div>
        {chat?.showVectorDocuments && (
          <div
            onClick={() => handleToggleShowVectorDocuments(false)}
            className="close-retrieved-doc-button w-embed cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#858585"
            >
              <path d="M360-120v-720h80v720h-80Zm160-160v-400l200 200-200 200Z"></path>
            </svg>
          </div>
        )}
      </div>
      {chat?.showVectorDocuments && (
        <div className="w-layout-vflex retrieved-body">
          <div className="form-block-15 w-form">
            <form
              id="email-form-14"
              name="email-form-14"
              data-name="Email Form 14"
              method="get"
              className="form-10"
              data-wf-page-id="67003ffe1addb65c933bd8ff"
              data-wf-element-id="d22ee819-add5-f35a-2956-41be05669405"
              aria-label="Email Form 14"
            >
              <div className="w-layout-hflex retrieved-doc-list">
                {isLoading && <div data-testid="loading-indicator">Loading...</div>}
                {documents?.map((document, index) => (
                  <DocumentItem item={document} key={index} />
                ))}
              </div>
            </form>
          </div>
        </div>
      )}

      {chat?.showVectorDocuments && (
        <div
          className="w-layout-vflex retrieved-bottom"
          style={{ display: 'none' }}
        >
          <div className="w-layout-hflex selected-document-block">
            <img
              src="https://cdn.prod.website-files.com/67003ffe1addb65c933bd8da/67051620e337316db5a2c957_description_24dp_004CE0_FILL0_wght400_GRAD0_opsz24.png"
              loading="lazy"
              width="25"
              height="25"
              alt=""
            />
            <div className="retrieved-doc-title">Selected Documents</div>
          </div>
          <div className="form-block-16 w-form">
            <div className="selected-document-empty">
              Select documents from the retrieved document section to chat
              specifically with them!
            </div>
            <form
              id="email-form-15"
              name="email-form-15"
              data-name="Email Form 15"
              method="get"
              className="selected-retrieved-doc-list"
              data-wf-page-id="67003ffe1addb65c933bd8ff"
              data-wf-element-id="3eb3bfc7-e5b0-c5c3-8cb5-490a14e38ca3"
              aria-label="Email Form 15"
            >
              <div className="w-layout-hflex selected-retrieved-doc-card">
                <div className="w-layout-hflex flex-block-197">
                  <img
                    src="/images/radio_button_unchecked_24dp_004CE0_FILL0_wght400_GRAD0_opsz24.png"
                    loading="lazy"
                    width="18"
                    alt=""
                  />
                  <div className="text-block-66">Introduction - Document</div>
                </div>
                <div className="w-layout-hflex flex-block-196">
                  <label className="w-checkbox checkbox-field-4">
                    <input
                      type="checkbox"
                      name="checkbox-4"
                      id="checkbox-4"
                      data-name="Checkbox 4"
                      className="w-checkbox-input checkbox-2"
                    />
                    <span
                      className="checkbox-label-3 w-form-label"
                      htmlFor="checkbox-4"
                    >
                      Select
                    </span>
                  </label>
                </div>
              </div>
              <div className="w-layout-hflex selected-retrieved-doc-card">
                <div className="w-layout-hflex flex-block-197">
                  <img
                    src="/images/radio_button_unchecked_24dp_004CE0_FILL0_wght400_GRAD0_opsz24.png"
                    loading="lazy"
                    width="18"
                    alt=""
                  />
                  <div className="text-block-66">Guide - Document</div>
                </div>
                <div className="w-layout-hflex flex-block-196">
                  <label className="w-checkbox checkbox-field-4">
                    <input
                      type="checkbox"
                      name="checkbox-4"
                      id="checkbox-4"
                      data-name="Checkbox 4"
                      className="w-checkbox-input checkbox-2"
                    />
                    <span
                      className="checkbox-label-3 w-form-label"
                      htmlFor="checkbox-4"
                    >
                      Select
                    </span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
