import { memo, useState, useEffect } from 'react';

import ReactQuill from 'react-quill';

import { htmlToMarkdown, isHtml, markdownToHtml } from './utils';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './RichTextEditor.css';

export const RichTextEditor = memo(function RichTextEditor({
  value = '',
  onChange,
  name,
  ...rest
}) {
  const [htmlValue, setHtmlValue] = useState(() => {
    if (!value) return '';
    return isHtml(value) ? value : markdownToHtml(value);
  });

  useEffect(() => {
    if (value !== htmlToMarkdown(htmlValue)) {
      setHtmlValue(isHtml(value) ? value : markdownToHtml(value));
    }
  }, [value]);

  const handleChange = (newValue) => {
    setHtmlValue(newValue);

    if (onChange) {
      onChange({ target: { name, value: htmlToMarkdown(newValue) } });
    }
  };

  return <ReactQuill value={htmlValue} onChange={handleChange} {...rest} />;
});
