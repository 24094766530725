import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import { useTriggers } from '../../navigation';
import usePrompts from './usePrompts';
import useMentorSuggestedPrompts from '../mentors/useMentorSuggestedPrompts';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { Error } from '../../../modules/alerts';

export default function useCreatePrompt() {
  const emptyPrompt = {
    prompt: '',
    description: '',
    category: '',
  };
  const [prompt, setPrompt] = useState(emptyPrompt);
  const [creating, setCreating] = useState(false);
  const dispatch = useDispatch();
  const { handlePromptDataSuccess } = usePrompts();
  const { loadSuggestedPrompts } = useMentorSuggestedPrompts();

  const auth = useSelector((state) => state.auth.data);
  const prompts = useSelector((state) => state.prompts.data);
  const mentors = useSelector((state) => state.mentors);
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const api = useApi();
  const { _handleUpdateModalStatus } = useTriggers();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrompt((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleUpdateLocalMentorSuggestedPrompts = (suggestedPrompts) => {
    const newEditMentor = { ...mentor, suggested_prompts: suggestedPrompts };
    dispatch(mentorActions.editMentorUpdated(newEditMentor));

    if (mentors?.mentor?.unique_id === newEditMentor?.unique_id) {
      dispatch(mentorActions.currentMentorUpdated(newEditMentor));
    }
  };

  const handleSaveMentorSuggestedPrompt = async () => {
    const endpoint = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/`;
    const payload = {
      mentor: mentor?.unique_id,
      prompt: prompt.description,
      platform: auth?.tenant?.key,
      is_system: false,
    };
    await makeRequest(endpoint, payload);
    const suggestedPrompts = await loadSuggestedPrompts(mentor?.unique_id);
    handleUpdateLocalMentorSuggestedPrompts(suggestedPrompts);
  };

  const addNewPromptToStore = (data) => {
    if (!prompts.loadedPrompts || !Array.isArray(prompts.loadedPrompts)) {
      handlePromptDataSuccess([data]);
      return;
    }

    const updatedPrompts = [...prompts.loadedPrompts, data];
    handlePromptDataSuccess(updatedPrompts);
  };

  const makeRequest = async (url, payload) => {
    setCreating(true);

    const response = await api.post(url, payload);
    if (response?.data) {
      toast.success('Prompt has been saved!', {
        ariaProps: { role: 'status' },
      });
      addNewPromptToStore(response?.data);
      _handleUpdateModalStatus('add-new-prompt-modal', false);
    } else {
      toast.custom(Error(response?.error), {
        ariaProps: { role: 'alert' },
      });
    }

    setCreating(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mentor) {
      await handleSaveMentorSuggestedPrompt();
      return;
    }
    if (!prompt?.category) {
      toast.custom('Please select a prompt category!', {
        ariaProps: { role: 'alert' },
      });
      return;
    }
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/`;
    const payload = {
      ...prompt,
      platform: auth?.tenant?.key,
      is_system: false,
    };
    await makeRequest(url, payload);
  };

  return { handleChange, handleSubmit, creating, prompt };
}
