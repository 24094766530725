import PromptItem from '../../prompts/prompt-item';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getPromptFromString,
  handleLayoutsWithCustomTooltipsJS,
  userIsOnTrial,
} from '../../../utils/shared';
import { useDebounce } from '../../../hooks/useDebounce';
import useUpdateMentorSettings from '../../../hooks/api/mentors/useUpdateMentorSettings';
import useMentorUtils from '../../../hooks/utils/useMentorUtils';
import { useTriggers } from '../../../hooks/navigation';
import {
  DEFAULT_MODERATION_PROMPT,
  DEFAULT_MODERATION_RESPONSE,
  DEFAULT_SAFETY_PROMPT,
  DEFAULT_SAFETY_RESPONSE,
} from '../../../hooks/api/mentors/useCreateMentor';

export default function Safety({ setMentor = () => {} }) {
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { updateCurrentEditMentor } = useMentorUtils();
  const { handleOpenFreeTrialModal } = useTriggers();

  const handleToggleSafety = async (event) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    const { name, checked } = event.target;
    const payload = {
      [name]: checked,
    };
    const updatedSettings = await handleUpdateSettings(mentor, payload);
    if (updatedSettings) {
      updateCurrentEditMentor({
        ...mentor,
        settings: {
          ...mentor.settings,
          [name]: checked,
        },
      });
    }
  };

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <div className="w-layout-vflex edit-mentor-system-prompt-tab">
      <div className="w-layout-hflex moderaion-proactive-prompt-block more-gap">
        <div className="w-layout-vflex prompt-category-container">
          <div className="w-layout-hflex prompt-category-container-header">
            <div
              data-w-id="35e1ed36-d0d9-16ec-e441-a78d88c3fdd6"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">
                Moderation Prompt
              </h5>
              <img
                loading="lazy"
                src="/images/info_1.svg"
                alt=""
                className="prompt-category-container-title-icon"
              />
              <div
                className="w-layout-vflex tooltip prompt-tooltip safety-prompt-tooltip"
                style={{ display: 'none' }}
              >
                <div className="w-layout-hflex prompt-tooltip-header-block">
                  <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                    <img
                      loading="lazy"
                      src="/images/filter.svg"
                      alt=""
                      className="image-62 no-filter-applied-in-darkmode"
                    />
                  </div>
                  <h5 className="prompt-tooltip-title">Moderation Prompt</h5>
                </div>
                <div className="w-layout-hflex tooltip-bg-img-block moderation"></div>
                <div className="w-layout-vflex prompt-tooltip-details-block">
                  <p className="prompt-tooltip-details-parag">
                    Acts as a filter of the user’s incoming message, determining
                    whether it’s appropriate to pass through to the main AI
                    system. <br />
                    <br />
                    The system prompt allows administrators to set the criteria
                    for identifying potentially harmful, inappropriate or
                    non-compliant content. Though it’s fast and efficient, a
                    clever user may be able to bypass it via “jailbreaking”.{' '}
                    <br />
                    <br />A more secure (albeit less fast) option is provided
                    via the “Safety Prompt”.
                  </p>
                </div>
                <div className="prompt-tooltip-left-triangle-pointer safety-prompt moderation"></div>
              </div>
            </div>
            <div className="w-layout-hflex flex-block-214">
              <h5 className="heading-48">Active</h5>
              <div
                react-component="ToolsRowSwitch"
                className="html-embed-3 w-embed"
              >
                <label className="mini-switch">
                  <span className="sr-only">Enable moderation</span>
                  <input
                    checked={mentor?.settings?.enable_moderation}
                    onChange={handleToggleSafety}
                    type="checkbox"
                    name="enable_moderation"
                  />
                  <span className="mini-switch-slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_MODERATION_PROMPT
                    : mentor?.moderation_system_prompt
                ),
                isSystem: true,
                type: 'moderation_system_prompt',
                mentor,
              }}
            />
          </div>
          <div className="w-layout-hflex prompt-category-container-header">
            <div
              data-w-id="64c2465b-a7e8-adb4-4544-5c112dcd1ff9"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">
                Moderation Response
              </h5>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_MODERATION_RESPONSE
                    : mentor?.moderation_response
                ),
                isSystem: true,
                type: 'moderation_response',
                mentor,
              }}
            />
          </div>
        </div>

        <div className="w-layout-vflex prompt-category-container">
          <div className="w-layout-hflex prompt-category-container-header">
            <div
              data-w-id="64c2465b-a7e8-adb4-4544-5c112dcd1ff9"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">Safety Prompt</h5>
              <img
                loading="lazy"
                src="/images/info_1.svg"
                alt=""
                className="prompt-category-container-title-icon"
              />
              <div
                className="w-layout-vflex tooltip prompt-tooltip safety-prompt-tooltip safety"
                style={{ display: 'none' }}
              >
                <div className="w-layout-hflex prompt-tooltip-header-block">
                  <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                    <img
                      loading="lazy"
                      src="/images/shield-check.svg"
                      alt=""
                      className="image-62 no-filter-applied-in-darkmode"
                    />
                  </div>
                  <h5 className="prompt-tooltip-title">Safety Prompt</h5>
                </div>
                <div className="w-layout-hflex tooltip-bg-img-block proactive"></div>
                <div className="w-layout-vflex prompt-tooltip-details-block">
                  <p className="prompt-tooltip-details-parag">
                    Acts as a filter of the AI’s outbound message, determining
                    whether it’s appropriate to return to the end user.
                    <br />
                    <br />
                    The system prompt allows administrators to set the criteria
                    for identifying potentially harmful, inappropriate or
                    non-compliant content. <br />
                    <br />
                    Before outputting a response to the user, it will finish
                    streaming it and analyze it in its entirety. <br />
                    <br />
                    The additional safety will come at the expense of added
                    latency.
                  </p>
                </div>
                <div className="prompt-tooltip-left-triangle-pointer safety-prompt safety"></div>
              </div>
            </div>
            <div className="w-layout-hflex flex-block-214">
              <h5 className="heading-48">Active</h5>
              <div
                react-component="ToolsRowSwitch"
                className="html-embed-3 w-embed"
              >
                <label className="mini-switch">
                  <span className="sr-only">Enable safety system</span>
                  <input
                    checked={mentor?.settings?.enable_safety_system}
                    onChange={handleToggleSafety}
                    name="enable_safety_system"
                    type="checkbox"
                  />
                  <span className="mini-switch-slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_SAFETY_PROMPT
                    : mentor?.safety_system_prompt
                ),
                isSystem: true,
                type: 'safety_system_prompt',
                mentor,
              }}
            />
          </div>
          <div className="w-layout-hflex prompt-category-container-header">
            <div
              data-w-id="64c2465b-a7e8-adb4-4544-5c112dcd1ff9"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">
                Safety Response
              </h5>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_SAFETY_RESPONSE
                    : mentor?.safety_response
                ),
                isSystem: true,
                type: 'safety_response',
                mentor,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
