import React from 'react';

export const SubscriptionExpired = (message) => {
  return (
    <div
      className="notification-box-container template error"
      style={{ display: 'flex' }}
    >
      <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
        <div className="w-layout-hflex notification-icon-block error">
          <img
            alt=""
            className="notification-icon"
            loading="lazy"
            src="/images/x.svg"
          />
        </div>
        <div className="notification-msg whiten-in-dark-mode">
          <div>
            <p style={{ marginLeft: '5px' }}>{message?.toString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
