import React from 'react';
import useVoiceChat from '../../../hooks/chat-room/useVoiceChat';
import { useSelector } from 'react-redux';

export default function VoiceChat() {
  const subscriptionExpired = useSelector((state) => state.navigation.subscriptionExpired);
  const { handleMicrophoneBtnClick, recording, processing } = useVoiceChat();
  return (
    <div
      className="has-custom-tooltip"
      data-w-id="c4c241a8-c39d-44d4-4bf4-1cd95b8c7c6c"
      prop-events-names="onClick"
      prop-events-value-onclick="handleMicrophoneBtnClick"
      onClick={processing || subscriptionExpired ? () => {} : handleMicrophoneBtnClick}
      style={{
        backgroundColor: recording ? 'darkgray' : '',
        position: 'relative',
      }}
    >
      <div className="code-embed-5 w-embed" style={{ marginTop: '5px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#919191"
        >
          <path d="M480-400q-50 0-85-35t-35-85v-240q0-50 35-85t85-35q50 0 85 35t35 85v240q0 50-35 85t-85 35Zm0-240Zm-40 520v-123q-104-14-172-93t-68-184h80q0 83 58.5 141.5T480-320q83 0 141.5-58.5T680-520h80q0 105-68 184t-172 93v123h-80Zm40-360q17 0 28.5-11.5T520-520v-240q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v240q0 17 11.5 28.5T480-480Z"></path>
        </svg>
      </div>
      <div
        style={{
          position: 'absolute',
          display: 'none',
          right: '10px',
          bottom: '15px',
          maxWidth: '180px',
        }}
        className="tooltip"
      >
        Use the microphone
      </div>
    </div>
  );
}
