import { useSelector } from 'react-redux';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

import { useMentorProperties } from '../../../hooks/settings/useSettings';
import useSuggestedPrompt from '../../../hooks/chat-room/useSuggestedPrompt';
import useGuidedSuggestedPrompts from '../../../hooks/chat-room/useGuidedSuggestedPrompts';

export default function GuidedSuggestedPrompts() {
  const prompts = useSelector((state) => state.chat.guidedPrompts);
  const { handleReloadGuidedQuestionsBtnClick, loading } =
    useGuidedSuggestedPrompts();
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const mentors = useSelector((state) => state.mentors);
  const { settings } = useMentorProperties(mentors?.mentor?.unique_id);

  if (!settings?.enable_guided_prompts) return null;

  return (
    <>
      {Array.isArray(prompts) && prompts.length > 0 && (
        <div
          className="w-layout-vflex guided-question-block"
          style={{ position: 'relative', display: 'flex' }}
        >
          <div className="w-layout-hflex suggestions-prompt-container">
            <img
              src="/images/question-primary.svg"
              loading="lazy"
              alt=""
              className="image-4"
            />
            {prompts.slice(0, 3).map((prompt, i) => {
              if (prompt) {
                return (
                  <div
                    key={`${i}-prompt`}
                    onClick={() => {
                      handleSuggestedPromptSelection(prompt);
                    }}
                    className="w-layout-hflex suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode"
                  >
                    <div>{prompt}</div>
                    <img
                      src="/images/arrow-sm-right-svgrepo-com.svg"
                      loading="lazy"
                      alt=""
                      className="image-39"
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
          <div
            prop-events-value-onclick="handleReloadGuidedQuestionsBtnClick"
            onClick={loading ? () => {} : handleReloadGuidedQuestionsBtnClick}
            className="w-layout-vflex suggestion-reload-block black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode"
          >
            {loading ? (
              <IBLSpinLoader size={16} color={'var(--primary)'} />
            ) : (
              <img
                src="/images/reload-ui-svgrepo-com.svg"
                loading="lazy"
                alt=""
                className="image-40"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
