import { createApi } from '@reduxjs/toolkit/query/react';

import { queryParams } from './query-params';
import { fetchBaseQuery } from '../fetch-base-query';
import { endpoints } from '../../../modules/prompt-gallery/enpoint';

export const promptsApiSlice = createApi({
  reducerPath: 'promptsApiSlice',

  baseQuery: fetchBaseQuery,

  tagTypes: ['Prompts'],

  endpoints: (builder) => ({
    getPromptCategories: builder.query({
      query: (args) => queryParams(endpoints.getPromptCategories, args),
      //   transformResponse: transformResponse.getPromptCategories,
    }),

    getPrompts: builder.query({
      query: (args) => queryParams(endpoints.getPrompts, args),
      //   transformResponse: transformResponse.getPrompts,
      providesTags: ['Prompts'],
    }),

    updatePrompt: builder.mutation({
      query: (args) => ({
        url: endpoints.updatePrompt(args),
        method: 'PUT',
        body: args.data,
      }),
      invalidatesTags: ['Prompts'],
    }),

    createPrompt: builder.mutation({
      query: (args) => ({
        url: endpoints.createPrompt(args),
        method: 'POST',
        body: args.data,
      }),
      invalidatesTags: ['Prompts'],
    }),

    deletePrompt: builder.mutation({
      query: (args) => ({
        url: endpoints.deletePrompt(args),
        method: 'DELETE',
      }),
      invalidatesTags: ['Prompts'],
    }),
  }),
});

export const {
  useGetPromptCategoriesQuery,
  useGetPromptsQuery,
  useUpdatePromptMutation,
  useCreatePromptMutation,
  useDeletePromptMutation,
} = promptsApiSlice;
