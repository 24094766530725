import { useState } from 'react';

import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useCreatePromptMutation,
  useGetPromptCategoriesQuery,
} from '../../lib/redux/prompts/api-slice';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTriggers } from '../../hooks/navigation';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';

export default function AddNewPromptModal() {
  const [createPrompt, { isLoading }] = useCreatePromptMutation();
  const mentor = useSelector((state) => state.analytics.mentor);
  const { t } = useTranslation();
  const { _handleUpdateModalStatus } = useTriggers();
  const emptyPrompt = {
    prompt: '',
    description: '',
    category: '',
    prompt_visibility: 'viewable_by_tenant_students',
    mentor: mentor.unique_id,
  };
  const [prompt, setPrompt] = useState(emptyPrompt);
  const auth = useSelector((state) => state.auth.data);
  const key = auth?.tenant?.key;
  const user_nicename = auth?.user?.user_nicename;
  const { data: promptCategories, isLoading: promptCategoriesIsLoading } =
    useGetPromptCategoriesQuery({
      org: auth?.tenant?.key,
      userId: auth?.user?.user_nicename,
    });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrompt((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  function handleModalCloseClick() {
    _handleUpdateModalStatus('add-new-prompt-gallery-page-modal', false);
  }

  const handleSubmit = async () => {
    if (!prompt.category) {
      toast.error('Please select a category', { ariaProps: { role: 'alert' } });
      return;
    }

    if (!prompt.prompt) {
      toast.error('Please enter a prompt', { ariaProps: { role: 'alert' } });
      return;
    }

    try {
      const response = await createPrompt({
        data: prompt,
        org: key,
        userId: user_nicename,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      toast.success('Prompt created successfully', {
        ariaProps: { role: 'status' },
      });
      handleModalCloseClick();
    } catch (error) {
      toast.error('Failed to create prompt', { ariaProps: { role: 'alert' } });
    }
  };

  return (
    <div
      className="modal add-new-prompt-gallery-page-modal"
      onClick={(e) => {
        e.stopPropagation();
        handleModalCloseClick();
      }}
    >
      <div
        className="w-layout-vflex modal-container integration-modal-container add-new-prompt-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header add-prompt-modal-header">
          <div
            className="w-layout-hflex get-back-btn greyish-bg-in-darkmode"
            data-w-id="b1c89d75-a2ac-e78c-4a69-d79e04966a00"
            onClick={handleModalCloseClick}
          >
            <img
              alt=""
              className="image-41"
              loading="lazy"
              src="/images/move-left-1.svg"
            />
          </div>
          <div className="w-layout-hflex flex-block-28">
            <h3 className="modal-header-title">{t('Add Suggested Prompt')}</h3>
          </div>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-full p-4" style={{ display: 'grid', gap: '30px' }}>
          <select
            className="prompt-select-category whiten-in-dark-mode w-select"
            data-name="Field 2"
            id="field-2"
            onChange={handleChange}
            disabled={promptCategoriesIsLoading || isLoading}
            name="category"
          >
            <option value="">
              {t(
                promptCategoriesIsLoading || isLoading
                  ? 'Loading'
                  : 'Select Category'
              )}
            </option>
            {promptCategories?.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          <RichTextEditor
            value={prompt?.prompt}
            onChange={handleChange}
            name="prompt"
            readOnly={isLoading}
            placeholder="Type your prompt"
          />
        </div>
        <div className="w-layout-hflex p-4 flex-block-32">
          <SubmitBtn
            saving={isLoading}
            label={'Submit'}
            onClick={isLoading ? () => {} : handleSubmit}
            className="default-btn w-button"
            href="#"
          />
        </div>
      </div>
    </div>
  );
}
