import { useSelector } from 'react-redux';

import { userIsOnTrial } from '../../utils/shared';
import { useTriggers } from '../../hooks/navigation';

export default function FreeTrialModal() {
  const auth = useSelector((state) => state.auth.data);
  const { handleSeePricingIframeBoxClick, handleModalCloseClick } =
    useTriggers();

  const isOnTrialAndMainTenant =
    userIsOnTrial() && auth?.tenant?.key === 'main';

  return (
    <div
      react-component="EmbedMentorModalBox"
      className="modal not-admin-banner-modal"
    >
      <div className="w-layout-vflex modal-container-5 get-started-modal-container">
        <div className="w-layout-vflex get-started-main-content">
          <div className="w-layout-hflex flex-block-40">
            <div className="w-layout-hflex flex-block-39">
              <img
                loading="lazy"
                src="/images/star.svg"
                alt=""
                className="image-63"
              />
            </div>
            <h4 className="get-started-header">Upgrade Your Experience</h4>
          </div>
          <div className="get-started-rich-text w-richtext">
            <p>
              Upgrade to create your own mentors—no credit card required for
              five days.
            </p>
            <p>
              Currently, you can only chat with mentors for a limited time.
              Unlock custom data sources and advanced behaviors by upgrading
              now.
            </p>
            <p>
              For full enterprise control — trusted by leading universities and
              companies —{' '}
              <a href="mailto:support@iblai.zendesk.com">
                contact our partnerships team.
              </a>{' '}
            </p>
          </div>
        </div>
        <div className="w-layout-hflex flex-block-38">
          <button
            onClick={
              isOnTrialAndMainTenant
                ? handleSeePricingIframeBoxClick
                : undefined
            }
            className="banner-btn get-started-btn w-button"
          >
            Upgrade for Free
          </button>
        </div>
        <div
          prop-events-value-onclick="handleModalCloseClick"
          prop-events-names="onClick"
          onClick={handleModalCloseClick}
          data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
          className="modal-close-wrapper"
        >
          <img
            src="/images/close_1close.png"
            loading="lazy"
            alt="Close Button Image"
          />
        </div>
      </div>
    </div>
  );
}
