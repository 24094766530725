import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import useApi from '../base/useApi';
import { Error } from '../../../modules/alerts';

export default function useUpdateMentorSettings() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const extractErrorFromResponse = (response) => {
    let msg = [];
    Object.keys(response).forEach((key) => {
      msg.push(`${key} - ${response[key]}`);
    });

    return msg.join(',');
  };

  const handleUpdateSettings = async (
    mentor,
    data,
    notify = true,
    isForm = false,
    onSuccess = () => {}
  ) => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentor?.unique_id}/settings/`;
    const response = await api.put(endpoint, data, isForm);
    if (response?.data) {
      onSuccess();

      if (notify) {
        toast.success('Mentor has been updated!', {
          ariaProps: { role: 'status' },
        });
      }
      return response?.data;
    } else {
      if (notify) {
        let errorMessage = null;
        try {
          const responseError = JSON.parse(response?.error);
          errorMessage = responseError?.error || response?.detail;
          if (!errorMessage) {
            errorMessage = extractErrorFromResponse(responseError);
          }
        } catch (e) {
          errorMessage = response;
        }
        toast.custom(Error(errorMessage), {
          ariaProps: { role: 'alert' },
        });
      }
    }
  };

  return { handleUpdateSettings };
}
