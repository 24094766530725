import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { textTruncate } from '../../../../utils/shared';

TimeAgo.addLocale(en);

export default function HistoryItem({ item, selected, setSelected }) {
  const timeAgo = new TimeAgo('en-US');

  const name = item?.lti_email || item?.email || 'Anonymous';

  return (
    <div
      map-value="message"
      react-component="ConversationBlock"
      onClick={() => setSelected(item)}
      map=""
      className={`w-layout-vflex flex-block-181 no-border-radius-bottom ${selected?.id === item.id ? 'mentor-chat-history-selected' : ''}`}
    >
      <div className="w-layout-hflex flex-block-180">
        <div>
          {name}: {textTruncate(item?.messages[0].human, 20)}
        </div>
        <div>{timeAgo.format(new Date(item.inserted_at))}</div>
      </div>
      <p className="paragraph-3">
        Bot: {textTruncate(item?.messages[0].ai, 40)}
      </p>
    </div>
  );
}
