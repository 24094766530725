import { useQuery } from './useQuery';

export const useEmbedParams = () => {
  const queryParams = useQuery();
  const hideSideNav = queryParams.get('hide_side_nav') === 'true';
  const hideHeader = queryParams.get('hide_header') === 'true';
  const modal = queryParams.get('modal');
  return {
    hideSideNav,
    hideHeader,
    modal,
  };
};
