export default function ShareHeader({ handleRedirectToMentor }) {
  return (
    <main role="banner" react-component="header" className="header">
      <div className="left-hand-side">
        <a href="/" className="share-logo">
          <img
            src="/images/ibl.ai-logo.png"
            loading="lazy"
            height="549"
            alt="mentorAI logo"
            className="image-58"
          />
        </a>
      </div>
      <div className="w-layout-hflex middle-block-side"></div>
      <div className="right-hand-side">
        <div className="w-layout-hflex header-action-btn-block">
          <div
            data-hover="false"
            data-delay="0"
            behave-as-btn="1"
            role="button"
            className="header-settings-dropdown display-only-on-mobile w-dropdown"
          >
            <div
              prop-events-value-onclick="handleSettingMenuBtnClick"
              onClick={handleRedirectToMentor}
              prop-events-names="onClick"
              className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode w-dropdown-toggle"
            >
              <img
                src="/images/cog.svg"
                loading="lazy"
                alt="You can view the settings of the mentorAI"
                className="ai-expert-header-action-icon"
              />
            </div>
          </div>
        </div>
        <a
          href="#"
          onClick={handleRedirectToMentor}
          style={{ marginTop: 0 }}
          className="default-btn w-button start-chatting-btn"
        >
          Chat for Free
        </a>
      </div>
    </main>
  );
}
