import { createSlice } from '@reduxjs/toolkit';

export const analyticsInitialState = {
  mentor: null,
  promptGalleryPrompt: null,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: analyticsInitialState,
  reducers: {
    mentorUpdated: (state, action) => {
      state.mentor = action.payload;
    },

    promptGalleryPromptUpdated: (state, action) => {
      state.promptGalleryPrompt = action.payload;
    },
  },
});

export const analyticsActions = analyticsSlice.actions;
export default analyticsSlice.reducer;
