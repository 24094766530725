import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { preprocessLaTeX } from '../../utils/shared';

const _mapProps = (props) => ({
  ...props,
  remarkPlugins: [remarkMath, remarkGfm],
  rehypePlugins: [rehypeKatex, rehypeRaw],
  components: {
    math: ({ value }) => <BlockMath>{value}</BlockMath>,
    inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
  },
});

const Markdown = ({ children, reduceLength = undefined }) => {
  const components = {
    math: ({ value }) => <BlockMath>{value}</BlockMath>,
    inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>,
  };

  if (reduceLength) {
    let title = preprocessLaTeX(children || '');
    if (title.split(' ').length > reduceLength) {
      title = title.split(' ').slice(0, reduceLength).join(' ') + '...';
    }

    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={components}
      >
        {title}
      </ReactMarkdown>
    );
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex, rehypeRaw]}
      components={components}
    >
      {preprocessLaTeX(children)}
    </ReactMarkdown>
  );
};

export default Markdown;
