import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './Promptgallerydetailmodalbox.css';

import Loader from '../../modules/loader';
import { useTriggers } from '../../hooks/navigation';
import usePromptItem from '../../hooks/api/prompts/usePromptItem';
import useEditPrompt from '../../hooks/api/prompts/useEditPrompt';
import { RichTextEditor } from '../../components/RichTextEditor/RichTextEditor';
import { Dialog } from '../Dialog';

const Promptgallerydetailmodalbox = (props) => {
  const prompt = useSelector((state) => state.prompts.selectedPrompt);
  const { t } = useTranslation();
  const { handleModalCloseClick } = useTriggers();
  const { handleCopyPrompt, handleTriggerPrompt } = usePromptItem(prompt);
  const { openEdit, handleChange, handleSave, saving, data, setOpenEdit } =
    useEditPrompt(prompt);
  const { _handleUpdateModalStatus } = useTriggers();

  const handleSubmission = (e) => {
    if (data?.creationMode) {
      data?.setMentor({
        ...data?.mentor,
        [data?.type]: data?.description,
      });
      setOpenEdit(false);
      _handleUpdateModalStatus('prompt-gallery-element-view-modal', false);
    } else {
      handleSave(e);
    }
  };

  const trimmedPrompt =
    prompt?.prompt?.length > 35
      ? `${prompt?.prompt.substring(0, 35)}...`
      : prompt?.prompt;

  return (
    <Dialog
      className="w-layout-vflex modal-container create-secret-key-modal-container prompt-gallery-element-view-modal-container"
      name="prompt-gallery-element-view-modal"
      closeModal={() => {
        _handleUpdateModalStatus('prompt-gallery-element-view-modal', false);
      }}
    >
      <div
        className={`w-layout-hflex modal-header ${prompt?.is_system || prompt.index !== undefined ? 'padding-zero' : ''}`}
      >
        {openEdit && !prompt?.is_system && prompt.index === undefined && (
          <div className="prompt-gallery-element-view-header-form-block w-form">
            <div
              className="prompt-gallery-element-view-header-form-input w-input"
              dangerouslySetInnerHTML={{ __html: trimmedPrompt }}
            />
          </div>
        )}
        <div
          className="modal-close-wrapper"
          data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
          onClick={handleModalCloseClick}
          prop-events-value-onclick="handleModalCloseClick"
        >
          <img alt="" loading="lazy" src="/images/close_1close.png" />
        </div>
      </div>
      <div className="w-layout-vflex modal-body create-secret-key-modal-body prompt-gallery-element-view-modal-body">
        {openEdit && (
          <div className="prompt-gallery-element-view-desc-form-block w-form">
            <form
              className="prompt-gallery-element-view-desc-form"
              data-name="Email Form 10"
              data-wf-element-id="1f4a83bc-a16c-f78f-8d36-5b581f17207b"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-10"
              method="get"
              name="email-form-10"
            >
              <RichTextEditor
                value={prompt?.description}
                onChange={handleChange}
                name="description"
                readOnly={saving}
              />
            </form>
          </div>
        )}
        <div className="w-layout-hflex flex-block-69">
          {openEdit && (
            <button
              onClick={handleSubmission}
              className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn prompt-gallery-element-save-btn"
            >
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="/images/save-3.svg"
              />
              <div>{t('Save')}</div>
            </button>
          )}
          {!prompt.isSystem && (
            <button
              onClick={handleTriggerPrompt}
              className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
            >
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="/images/terminal.svg"
              />
              <div>{t('Run')}</div>
            </button>
          )}
          <button
            onClick={handleCopyPrompt}
            className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
          >
            <img
              alt=""
              className="gallery-element-btn-icon gallery-element-btn-showed-icon"
              loading="lazy"
              src="/images/copy.svg"
            />
            <div>{t('Copy')}</div>
          </button>
        </div>
        {saving && <Loader />}
      </div>
    </Dialog>
  );
};

export default Promptgallerydetailmodalbox;
