import React from 'react';
import './Inviteduserslistmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useInvitedUsers from '../../hooks/users/useInvitedUsers';
import Loader from '../../modules/loader';
import { useTranslation } from 'react-i18next';

const Inviteduserslistmodalbox = (props) => {
  const { handleModalCloseClick, _handleUpdateModalStatus } = useTriggers();
  const { invitations, loading, setParams } = useInvitedUsers();
  const { t } = useTranslation();
  return (
    <div
      className="modal users-settings-invited-users-list-modal"
      onClick={(e) => {
        e.stopPropagation();
        _handleUpdateModalStatus(
          'users-settings-invited-users-list-modal',
          false
        );
      }}
    >
      <div
        className="w-layout-vflex modal-container users-settings-invited-users-list-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Invited Users</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body user-settings-modal-body">
          <div className="text-block-25">
            {t('Showing list of invited users pending confirmation')}
          </div>
          <div className="w-layout-hflex table-top-header-block">
            <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode invited-users-list-search-box">
              <img
                alt=""
                className="image-49"
                loading="lazy"
                src="/images/search-1.svg"
              />
              <div className="search-box-form-block w-form">
                <form
                  data-name="Email Form 4"
                  data-wf-element-id="43aac662-bdc7-9060-d2aa-1012f2a4e94b"
                  data-wf-page-id="650305782bc61751e5765214"
                  id="email-form-4"
                  method="get"
                  name="email-form-4"
                >
                  <input
                    className="text-field-4 w-input"
                    data-name="Name 5"
                    id="name-5"
                    maxLength={256}
                    name="name-5"
                    placeholder="Search invited users"
                    type="text"
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="w-layout-vflex table-container">
            <div className="w-layout-hflex table-header invited-users-table-header">
              <div className="table-block table-block-medium all-whiten-in-dark-mode invited-users-table-block">
                <h5 className="table-title">{t('EMAIL')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
              <div className="table-block table-block-big all-whiten-in-dark-mode invited-users-table-block">
                <h5 className="table-title">{t('INVITED AT')}</h5>
                <img
                  alt=""
                  className="user-table-sort-icon"
                  loading="lazy"
                  src="/images/arrow-up-down.svg"
                />
              </div>
            </div>
            {loading && <Loader />}

            {invitations?.results?.map((item) => (
              <div
                key={item.email}
                className="w-layout-hflex table-body-row greyish-bg-in-darkmode invited-users-table-row"
              >
                <div className="table-block table-block-medium invited-users-table-block">
                  <div className="table-text">{item.email}</div>
                </div>
                <div className="table-block table-block-big invited-users-table-block">
                  <div className="table-text">-</div>
                </div>
              </div>
            ))}

            {invitations?.results?.length === 0 && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <i>{t('No invited users')}</i>
              </div>
            )}
          </div>
          <div className="w-layout-hflex users-settings-pagination-block centered hidden">
            <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
              <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                <img
                  alt=""
                  className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  loading="lazy"
                  src="/images/chevron-first.svg"
                />
              </div>
              <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                <img
                  alt=""
                  className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  loading="lazy"
                  src="/images/chevron-left-2.svg"
                />
              </div>
              <div className="w-layout-hflex table-pagination-btn table-pagination-btn-active">
                <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                  1
                </h5>
              </div>
              <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                <h5 className="table-pagination-page-number">2</h5>
              </div>
              <div className="w-layout-hflex table-pagination-btn table-pagination-btn-no-hover">
                <img
                  alt=""
                  className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  loading="lazy"
                  src="/images/more-horizontal-3.svg"
                />
              </div>
              <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                <h5 className="table-pagination-page-number">4</h5>
              </div>
              <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                <img
                  alt=""
                  className="table-pagination-btn-icon"
                  loading="lazy"
                  src="/images/chevron-right.svg"
                />
              </div>
              <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                <img
                  alt=""
                  className="table-pagination-btn-icon"
                  loading="lazy"
                  src="/images/chevron-last-1.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inviteduserslistmodalbox;
