import React from 'react';
import './Invitenewusermodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useInviteNewUser from '../../hooks/users/useInviteNewUser';
import { userIsOnTrial } from '../../utils/shared';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTranslation } from 'react-i18next';

const Invitenewusermodalbox = (props) => {
  const { t } = useTranslation();
  const {
    handleModalCloseClick,
    handleShowInvitedUsersBtnClick,
    _handleUpdateModalStatus,
  } = useTriggers();
  const { email, handleChange, handleSubmit, submitting } = useInviteNewUser();
  return (
    <div
      className="modal users-settings-invite-modal"
      onClick={(e) => {
        e.stopPropagation();
        _handleUpdateModalStatus('users-settings-invite-modal', false);
      }}
    >
      <div
        className="w-layout-vflex modal-container create-secret-key-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-hflex modal-header space-between">
          <h3 className="modal-header-title">{t('Invite New User')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
          <div
            className="featured-mentor-see-all-btn"
            data-w-id="23515756-e9f3-0cd4-fd91-f49ca50bb76c"
            onClick={handleShowInvitedUsersBtnClick}
          >
            See All
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              className="manage-account-form secret-key-form"
              data-wf-page-id="650305782bc61751e5765214"
              data-wf-element-id="cc38c0f4-e6f4-39ef-7b5b-901fd0ec0df9"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label">{t('Email')}</label>
                </div>
                <div>{t('A confirmation link would be sent')}</div>
                <input
                  disabled={submitting}
                  onChange={handleChange}
                  value={email}
                  type="email"
                  className="form-input secret-key-form-input w-input"
                  maxLength="256"
                  name="organisation-name-4"
                  data-name="Organisation Name 4"
                  placeholder="example@ibl.ai"
                  custom-value="Personal"
                  id="organisation-name-4"
                />
              </div>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  onClick={!submitting ? handleSubmit : () => {}}
                  href="#"
                  data-w-id="cc38c0f4-e6f4-39ef-7b5b-901fd0ec0e04"
                  saving={submitting}
                  label={'Submit'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitenewusermodalbox;
