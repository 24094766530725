import React from 'react';
import { useSelector } from 'react-redux';
import PromptItem from '../../prompts/prompt-item';
import { useTriggers } from '../../../hooks/navigation';
import {
  getPromptFromString,
  handleLayoutsWithCustomTooltipsJS,
  userIsOnTrial,
} from '../../../utils/shared';
import useMentorPrompts from '../../../hooks/api/mentors/useMentorPrompts';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  DEFAULT_GUIDED_PROMPT,
  DEFAULT_SYSTEM_PROMPT,
} from '../../../hooks/api/mentors/useCreateMentor';
import { useMentorProperties } from '../../../hooks/settings/useSettings';
import useUpdateMentorSettings from '../../../hooks/api/mentors/useUpdateMentorSettings';

export default function MentorPrompts({
  creationMode = false,
  mentor = {},
  setMentor = () => {},
}) {
  const mentors = useSelector((state) => state.mentors);
  const { handleAddNewPromptBtnClick, handleOpenFreeTrialModal } =
    useTriggers();
  const { suggestedPrompts } = useMentorPrompts();
  const { settings, refreshSettings } = useMentorProperties(
    mentors?.mentor?.unique_id
  );
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const [greetingMethod, setGreetingMethod] = React.useState(false);

  React.useEffect(() => {
    if (settings?.settings?.greeting_method) {
      setGreetingMethod(
        settings?.settings?.greeting_method === 'proactive_prompt'
      );
    }
  }, [settings?.settings?.greeting_method]);

  const handleToggleSafety = async (event) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    const { name, checked } = event.target;
    let payload = {
      [name]: checked,
    };

    if (name === 'greeting_method') {
      payload.greeting_method = checked
        ? 'proactive_prompt'
        : 'proactive_response';
    }

    await handleUpdateSettings(
      mentors?.mentor,
      payload,
      true,
      false,
      refreshSettings
    );
  };

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <div className="w-layout-vflex edit-mentor-system-prompt-tab">
      <div className="w-layout-hflex moderaion-proactive-prompt-block custom-moderaion-proactive-prompt-block">
        <div className="w-layout-vflex prompt-category-container">
          <div
            data-w-id="8c90a795-9942-b013-5ad8-3276936686d6"
            className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
          >
            <h5 className="prompt-category-container-title">System Prompt</h5>
            <img
              src="/images/info_1.svg"
              loading="lazy"
              alt=""
              className="prompt-category-container-title-icon"
            />
            <div
              className={`w-layout-vflex tooltip prompt-tooltip ${creationMode ? 'safety-prompt-tooltip tooltip-more-left' : 'system-prompt-tooltip'}`}
            >
              <div className="w-layout-hflex prompt-tooltip-header-block">
                <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                  <img
                    src="/images/terminal.svg"
                    loading="lazy"
                    alt=""
                    className="image-62 no-filter-applied-in-darkmode"
                  />
                </div>
                <h5 className="prompt-tooltip-title">System Prompt</h5>
              </div>
              <div className="w-layout-hflex tooltip-bg-img-block"></div>
              <div className="w-layout-vflex prompt-tooltip-details-block">
                <p className="prompt-tooltip-details-parag">
                  It sets the behavior, tone, and guidelines for how it should
                  respond to user inputs.
                  <br />‍<br /> It establishes the context and defines the
                  boundaries within which the AI operates. <br />‍<br />
                  This prompt helps guide the AI&#x27;s responses to align with
                  specific goals, such as maintaining a formal tone or focusing
                  on a particular subject. <br />‍<br />
                  Essentially, it influences how the AI interprets and generates
                  text.
                </p>
              </div>
              <div
                className={`prompt-tooltip-left-triangle-pointer ${creationMode ? 'triangle-pointer-down' : ''}`}
              ></div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_SYSTEM_PROMPT
                    : creationMode
                      ? mentor?.system_prompt
                      : mentors?.edit?.mentor?.system_prompt
                ),
                isSystem: true,
                type: 'system_prompt',
                creationMode,
                mentor,
                setMentor,
              }}
            />
          </div>
        </div>

        {(creationMode || mentors?.edit?.mentor?.proactive_prompt) && (
          <div className="w-layout-vflex prompt-category-container">
            <div className="w-layout-hflex prompt-category-container-header">
              <div
                data-w-id="64c2465b-a7e8-adb4-4544-5c112dcd1ff9"
                className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
              >
                <h5 className="prompt-category-container-title">
                  Proactive Prompt
                </h5>
                <img
                  loading="lazy"
                  src="/images/info_1.svg"
                  alt=""
                  className="prompt-category-container-title-icon"
                />
                <div
                  className="w-layout-vflex tooltip prompt-tooltip safety-prompt-tooltip safety"
                  style={{ display: 'none' }}
                >
                  <div className="w-layout-hflex prompt-tooltip-header-block">
                    <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                      <img
                        loading="lazy"
                        src="/images/shield-check.svg"
                        alt=""
                        className="image-62 no-filter-applied-in-darkmode"
                      />
                    </div>
                    <h5 className="prompt-tooltip-title">Proactive Prompt</h5>
                  </div>
                  <div className="w-layout-hflex tooltip-bg-img-block proactive"></div>
                  <div className="w-layout-vflex prompt-tooltip-details-block">
                    <p className="prompt-tooltip-details-parag">
                      It&#x27;s a hidden directive provided to the AI model that
                      initiates the conversation, allowing the application to
                      begin with a response instead of a blank chat. <br />‍
                      <br />
                      It is designed to guide the AI to generate an opening
                      message, setting the tone and context for the interaction
                      without requiring input from the user first. <br />‍<br />
                      This helps create a more engaging and dynamic experience
                      by having the AI take the lead in starting the
                      conversation.
                    </p>
                  </div>
                  <div className="prompt-tooltip-left-triangle-pointer safety-prompt safety"></div>
                </div>
              </div>
              <div className="w-layout-hflex flex-block-214">
                <h5 className="heading-48">Active</h5>
                <div
                  react-component="ToolsRowSwitch"
                  className="html-embed-3 w-embed"
                >
                  <label className="mini-switch">
                    <span className="sr-only">Enable safety system</span>
                    <input
                      checked={greetingMethod}
                      onChange={handleToggleSafety}
                      name="greeting_method"
                      type="checkbox"
                    />
                    <span className="mini-switch-slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
              <PromptItem
                prompt={{
                  ...getPromptFromString(
                    creationMode
                      ? mentor?.proactive_prompt
                      : mentors?.edit?.mentor?.proactive_prompt
                  ),
                  isSystem: true,
                  type: 'proactive_prompt',
                  creationMode,
                  mentor,
                  setMentor,
                }}
              />
            </div>
          </div>
        )}

        <div className="w-layout-vflex prompt-category-container">
          <div className="w-layout-hflex prompt-category-container-header">
            <div
              data-w-id="35e1ed36-d0d9-16ec-e441-a78d88c3fdd6"
              className="w-layout-hflex prompt-category-container-title-block has-custom-tooltip"
            >
              <h5 className="prompt-category-container-title">Guided Prompt</h5>
              <img
                src="/images/info_1.svg"
                loading="lazy"
                alt=""
                className="prompt-category-container-title-icon"
              />
              <div
                className="w-layout-vflex tooltip prompt-tooltip safety-prompt-tooltip"
                style={{ display: 'none' }}
              >
                <div className="w-layout-hflex prompt-tooltip-header-block">
                  <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                    <img
                      loading="lazy"
                      src="/images/filter.svg"
                      alt=""
                      className="image-62 no-filter-applied-in-darkmode"
                    />
                  </div>
                  <h5 className="prompt-tooltip-title">Guided Prompt</h5>
                </div>
                <div className="w-layout-hflex tooltip-bg-img-block moderation"></div>
                <div className="w-layout-vflex prompt-tooltip-details-block">
                  <p className="prompt-tooltip-details-parag">
                    It's a hidden directive that analyzes the ongoing
                    conversation, identifying key points and knowledge gaps to
                    suggest relevant follow-up questions.
                    <br />‍<br />
                    By constantly monitoring the context, it helps the AI
                    provide prompts that encourage deeper exploration and
                    critical thinking. This approach ensures learners remain
                    engaged, offering them meaningful avenues of inquiry to
                    pursue.
                    <br />‍<br />
                    It ultimately fosters a more interactive and personalized
                    learning environment by guiding discussions in a focused and
                    dynamic manner.
                    <br />‍<br />
                  </p>
                </div>
                <div className="prompt-tooltip-left-triangle-pointer safety-prompt moderation"></div>
              </div>
            </div>
            <div className="w-layout-hflex flex-block-214">
              <h5 className="heading-48">Active</h5>
              <div
                react-component="ToolsRowSwitch"
                className="html-embed-3 w-embed"
              >
                <label className="mini-switch">
                  <span className="sr-only">Enable moderation</span>
                  <input
                    checked={settings?.enable_guided_prompts}
                    onChange={handleToggleSafety}
                    type="checkbox"
                    name="enable_guided_prompts"
                  />
                  <span className="mini-switch-slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid _1column-grid">
            <PromptItem
              prompt={{
                ...getPromptFromString(
                  userIsOnTrial()
                    ? DEFAULT_GUIDED_PROMPT
                    : creationMode
                      ? mentor?.guided_prompt_instructions
                      : mentors?.edit?.mentor?.guided_prompt_instructions
                ),
                isSystem: true,
                type: 'guided_prompt_instructions',
                creationMode,
                mentor,
                setMentor,
              }}
            />
          </div>
        </div>
      </div>

      {!creationMode && (
        <div className="w-layout-vflex prompt-category-container">
          <div
            data-w-id="e097b85c-9716-dbc5-99c1-9b2dbccdeb6a"
            className="w-layout-hflex prompt-category-container-title-block  has-custom-tooltip"
          >
            <h5 className="prompt-category-container-title">
              Suggested Prompts
            </h5>
            <img
              src="/images/info_1.svg"
              loading="lazy"
              alt=""
              className="prompt-category-container-title-icon"
            />
            <div className="w-layout-vflex tooltip prompt-tooltip suggested-prompt">
              <div className="w-layout-hflex prompt-tooltip-header-block">
                <div className="w-layout-hflex prompt-tooltip-header-icon-block">
                  <img
                    src="/images/layout-list-1.svg"
                    loading="lazy"
                    alt=""
                    className="image-62 no-filter-applied-in-darkmode"
                  />
                </div>
                <h5 className="prompt-tooltip-title">Suggested Prompts</h5>
              </div>
              <div className="w-layout-hflex tooltip-bg-img-block suggested"></div>
              <div className="w-layout-vflex prompt-tooltip-details-block">
                <p className="prompt-tooltip-details-parag">
                  It&#x27;s a recommendation provided to the user, guiding them
                  on how to interact with the AI model based on its
                  capabilities.
                  <br /> <br />
                  These prompts are crafted to encourage meaningful and
                  effective interactions by highlighting questions or tasks the
                  AI is well-suited to handle. <br />‍<br />
                  By offering tailored suggestions, the AI helps users make the
                  most of the system&#x27;s strengths and ensures a smoother,
                  more productive experience.
                </p>
              </div>
              <div className="prompt-tooltip-left-triangle-pointer suggested"></div>
            </div>
          </div>
          <div className="w-layout-grid prompt-gallery-grid edit-mentor-system-prompt-grid">
            {suggestedPrompts?.map((item, i) => (
              <PromptItem
                prompt={{
                  ...getPromptFromString(item?.prompt),
                  index: item.id,
                  id: item.id,
                }}
                key={`${i}-prompt`}
              />
            ))}
            <div
              className="w-layout-vflex prompt-grid-element add-prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode add-prompt-grid-element-1"
              data-w-id="1dc72672-b432-9a64-835d-c12c25541aac"
              onClick={
                userIsOnTrial()
                  ? handleOpenFreeTrialModal
                  : handleAddNewPromptBtnClick
              }
              id="w-node-_1dc72672-b432-9a64-835d-c12c25541aac-e5765214"
            >
              <div className="w-layout-hflex flex-block-18">
                <img
                  alt=""
                  className="image-28"
                  loading="lazy"
                  src="/images/plus-4.svg"
                />
              </div>
              <h5 className="add-new-prompt-label">Add New Prompt</h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
