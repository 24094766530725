import useMentorSuggestedPrompts from './useMentorSuggestedPrompts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useMentorPrompts(mentor_id) {
  const mentors = useSelector((state) => state.mentors);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const { loadSuggestedPrompts } = useMentorSuggestedPrompts();

  const prepareSuggestedPrompts = async () => {
    if (mentors?.edit?.mentor?.unique_id) {
      const data = await loadSuggestedPrompts(
        mentor_id || mentors?.edit?.mentor?.unique_id
      );
      if (data) {
        //const prompts = data?.filter((item) => item.trim());
        setSuggestedPrompts(data);
      }
    }
  };

  useEffect(() => {
    prepareSuggestedPrompts();
  }, [mentors?.edit?.mentor]);

  return { suggestedPrompts };
}
