import { createSlice } from '@reduxjs/toolkit';

import { isDesktop } from '../../../utils/shared';

export const navigationInitialState = {
  data: {
    menus: {
      settings: false,
      user: false,
      help: false,
    },
    modals: {
      'llm-modal': false,
      'featured-mentors-modals': false,
      'mentor-list-modal': false,
    },
    sidebar: isDesktop() ? true : false,
  },
  instructorMode: true,
  darkMode: false,
  iframeCloseButton: false,
  tenantCustomization: {
    enabled: false,
    metadata: null,
  },
  chatHistoryExportData: null,
  currentApiKey: null,
  currentApiKeyOrg: null,
  apiKeyStatus: null,
  newlyCreatedApiKeys: {},
  subscriptionExpired:false,
};

export const navigationSlice = createSlice({
  name: 'header',
  initialState: navigationInitialState,
  reducers: {
    reset: () => navigationInitialState,
    navigationUpdated: (state, action) => {
      state.data = action.payload;
    },
    instructorModeUpdated: (state, action) => {
      state.instructorMode = action.payload;
    },
    currentApiKeyUpdated: (state, action) => {
      state.currentApiKey = action.payload;
    },
    apiKeyStatusUpdated: (state, action) => {
      state.apiKeyStatus = action.payload;
    },
    darkModeUpdated: (state, action) => {
      state.darkMode = action.payload;
    },
    iframeCloseButtonEnabled: (state, action) => {
      state.iframeCloseButton = action.payload;
    },
    tenantCustomizationUpdated: (state, action) => {
      state.tenantCustomization = action.payload;
    },
    chatHistoryExportDataUpdated: (state, action) => {
      state.chatHistoryExportData = action.payload;
    },
    currentApiKeyOrgUpdated: (state, action) => {
      state.currentApiKeyOrg = action.payload;
    },
    newlyCreatedApiKeyUpdated: (state, action) => {
      state.newlyCreatedApiKeys[action.payload.name] = action.payload.key;
    },
    subscriptionExpiredUpdated: (state, action) => {
      state.subscriptionExpired = action.payload;
    }

  },
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice.reducer;
