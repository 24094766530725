import Header from '../../components/Header/Header';
import Aiaudiochat from '../../components/Aiaudiochat/Aiaudiochat';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ChatRoom from '../chat-room';
import FreeTrialBanner from '../trial-banner';
import SubscriptionInactiveBanner from '../subscription-inactive-banner';
import { useDebounce } from '../../hooks/useDebounce';
import {
  handleFixedMainContentHeightJS,
  handleLayoutsWithCustomTooltipsJS,
  isLoggedIn,
} from '../../utils/shared';
import InstructorHeader from '../../components/Instructor/InstructorHeader/InstructorHeader';
import { inIframe } from '@iblai/ibl-web-react-common';
import InstructorTabs from '../../components/Instructor/InstructorTabs/InstructorTabs';

import FullSidebar from '../sidebar';
import { Outlet } from 'react-router-dom';
import DocumentRetrieve from '../chat-room/document-retrieve/document-retrieve';
import Modals from '../modals';
import { useGetVectorDocumentsQuery } from '../../lib/redux/documents/api-slice';
import { useEmbedParams } from '../../hooks/utils/useEmbedParams';

export default function Home() {
  const { tabs, mode } = useSelector((state) => state.embed);
  const { sidebar } = useSelector((state) => state.navigation.data);

  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const { hideSideNav, hideHeader } = useEmbedParams();

  const { data: documents } = useGetVectorDocumentsQuery(
    {
      sessionId,
      org: auth?.tenant?.key,
      userId: auth?.user?.user_nicename,
    },
    {
      skip: !sessionId || !isLoggedIn(),
    }
  );

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  useEffect(() => {
    window.addEventListener('resize', handleFixedMainContentHeightJS);
    return () => {
      window.removeEventListener('resize', handleFixedMainContentHeightJS);
    };
  }, []);

  useDebounce(handleFixedMainContentHeightJS, 1000, []);
  return (
    <div className="body">
      <div className="site-notifications-container">
        <div className="w-layout-vflex notifications-boxes-container">
          <div className="notification-box-container template success">
            <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
              <div className="w-layout-hflex notification-icon-block success">
                <img
                  alt=""
                  className="notification-icon"
                  loading="lazy"
                  src="/images/check.svg"
                />
              </div>
              <div className="notification-msg whiten-in-dark-mode">
                {' '}
                This is a success message
              </div>
            </div>
          </div>
          <div className="notification-box-container template warning">
            <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
              <div className="w-layout-hflex notification-icon-block warning">
                <img
                  alt=""
                  className="notification-icon"
                  loading="lazy"
                  src="/images/exclamation-svgrepo-com.svg"
                />
              </div>
              <div className="notification-msg whiten-in-dark-mode">
                {' '}
                This is a warning message
              </div>
            </div>
          </div>
          <div className="notification-box-container template info">
            <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
              <div className="w-layout-hflex notification-icon-block info">
                <img
                  alt=""
                  className="notification-icon"
                  loading="lazy"
                  src="/images/info-svgrepo-com-1.svg"
                />
              </div>
              <div className="notification-msg whiten-in-dark-mode">
                {' '}
                This is an info message
              </div>
            </div>
          </div>
          <div className="notification-box-container template error">
            <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
              <div className="w-layout-hflex notification-icon-block error">
                <img
                  alt=""
                  className="notification-icon"
                  loading="lazy"
                  src="/images/x.svg"
                />
              </div>
              <div className="notification-msg whiten-in-dark-mode">
                {' '}
                This is an error message
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreeTrialBanner />
      <SubscriptionInactiveBanner />
      <div
        className="w-layout-hflex site-container"
        style={{ gridColumnGap: sidebar ? '20px' : '8px' }}
      >
        {isLoggedIn() && !hideSideNav && <FullSidebar />}
        <div className="w-layout-hflex site-right-content-block with-sidebar less-pad-right pad-right-increased">
          {mode === 'advanced' && inIframe()
            ? !hideHeader && (
                <>
                  <InstructorHeader />
                  <InstructorTabs tabs={tabs} />
                </>
              )
            : !hideHeader && <Header />}

          <div className="w-layout-hflex main-content-body">
            <main
              className="main-content with-sidebar"
              style={
                mode === 'advanced'
                  ? { paddingTop: '58px', paddingBottom: '10px' }
                  : {}
              }
            >
              <ChatRoom />

              <div className="rate-limit-exceed-container">
                <div className="w-layout-hflex rate-limit-exceed-block lighter-grey-bg-dark-mode">
                  <img
                    alt=""
                    className="rate-limit-exceed-icon"
                    loading="lazy"
                    src="/images/complain.png"
                  />
                  <div className="w-layout-vflex rate-limit-exceed-desc-container">
                    <h5 className="rate-limit-exceed-header whiten-in-dark-mode">
                      Rate Limit Exceeded
                    </h5>
                    <div className="rate-limit-exceed-desc whiten-in-dark-mode">
                      Please upgrade your account now
                    </div>
                    <div className="w-layout-hflex rate-limit-exceed-btn-container">
                      <h5
                        className="platform-upgrade-btn platform-upgrade-open-modal-btn"
                        data-w-id="46505d38-312f-0336-2cb3-2027454e9ae7"
                      >
                        UPGRADE
                      </h5>
                      <h5 className="platform-upgrade-btn platform-upgrade-later-btn whiten-in-dark-mode">
                        NOT NOW
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex theme-switcher-container-2 greyish-bg-in-darkmode whiten-border-in-dark-mode theme-switcher-container">
                <img
                  alt=""
                  className="theme-switcher-icon"
                  loading="lazy"
                  src="/images/moon-svgrepo-com.svg"
                />
                <img
                  alt=""
                  className="theme-switcher-icon light-mode"
                  loading="lazy"
                  src="/images/sun.svg"
                />
              </div>
              {documents && documents.length > 0 && <DocumentRetrieve />}
            </main>
            <Aiaudiochat />
          </div>
        </div>
      </div>

      <div className="modal prompt-modal">
        <div className="w-layout-vflex modal-container full-modal-container">
          <div className="w-layout-hflex modal-header">
            <h3 className="modal-header-title">Suggested Prompts</h3>
            <a className="default-btn prompt-add-btn w-button" href="#">
              + Add New Prompt
            </a>
            <div
              className="modal-close-wrapper"
              data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
              prop-events-names="onClick"
              prop-events-value-onclick="handleModalCloseClick"
            >
              <img alt="" loading="lazy" src="/images/close_1close.png" />
            </div>
          </div>
          <div className="w-layout-vflex modal-body">
            <div className="w-layout-grid prompt-container">
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-b576be1e-6ba4-dfcb-bbf1-cb91dcf3c001-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Find answers to specific questions</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Provide information about [topic]
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/graduation-cap-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Learners
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-af72694c-8686-bb95-68c4-b455ff8555be-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Generate study materials</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Summarize the key points about [topic]
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/graduation-cap-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Learners
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_51750434-663d-833b-fbce-3a0daf0f3ff7-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Assist with research projects</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      List some relevant sources for [topic]
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/graduation-cap-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Learners
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-a7cd9a1e-06e8-090b-f8f6-b8c45befa912-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Improve understanding of the subject matter</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Explain [concept] in simple terms
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/graduation-cap-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Learners
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-c759fff2-ef9c-a105-a409-237d50d636d9-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Prepare for exams</strong>{' '}
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      What are the most important things to know about [topic]
                      for a test?
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/graduation-cap-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Learners
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_5a505de0-516a-2a64-e67d-16956e5a70c0-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>
                      <em>Generating Educational Content</em>
                    </strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Provide examples of [topic] for [grade level] students
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/presentation-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Instructors
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_9e603d53-b516-ca5b-44d6-a98f039371be-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Lesson Planning</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Explain the key concepts of [topic] in simple language
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/presentation-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Instructors
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_5068f3b8-e576-1e69-2bdf-072c5c46ac84-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Personalized Learning Experiences</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Provide activities related to [topic] for [grade level]
                      students
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/presentation-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Instructors
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-bd8f269d-4a7e-2c14-1de7-265adbd63344-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Summaries of complex topics</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Provide a summary of [topic]
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/presentation-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Instructors
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_1af02b37-6ae8-68eb-1777-b7601022c2da-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Creating interactive quizzes</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Create a quiz with [number] questions on [topic]
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/presentation-1.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    For Instructors
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-c0b90eb4-6a75-f47f-543a-0b7e429cda10-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Write an Essay</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Write an essay on the Civil War in 1000 words on the title
                      "The North vs the South” <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/chevron-right-square.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    My Prompt #1
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_9047bc1f-67de-2625-2129-aeb12369f037-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">
                    <strong>Suggest Ideas</strong>
                  </h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    <em>
                      Suggest me some ideas on the origin of the Silicon Valley
                      boom
                      <br />
                    </em>
                  </div>
                  <a className="prompt-edit-btn" href="#">
                    Edit
                  </a>
                </div>
                <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                  <img
                    alt=""
                    className="prompt-el-footer-icon whiteing-img"
                    loading="lazy"
                    src="/images/chevron-right-square.svg"
                  />
                  <div className="prompt-el-action-text prompt-el-action-text-white">
                    My Prompt #2
                  </div>
                </div>
              </div>
              <div
                className="w-layout-vflex add-suggested-prompt-block"
                data-w-id="b49f10dc-e0b4-c7e5-4451-56865fbcf1a8"
                id="w-node-b49f10dc-e0b4-c7e5-4451-56865fbcf1a8-e5765214"
              >
                <div className="w-layout-hflex flex-block-18">
                  <img
                    alt=""
                    className="image-28"
                    loading="lazy"
                    src="/images/plus-4.svg"
                  />
                </div>
                <h5 className="add-new-prompt-label black-color-in-dark-mode">
                  Add New Prompt
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal datasets-modal">
        <div className="w-layout-vflex modal-container full-modal-container">
          <div className="w-layout-hflex modal-header dataset-modal-header">
            <h3 className="modal-header-title">Datasets</h3>
            <a
              className="default-btn w-button"
              data-w-id="9185f9b8-cd24-ba2c-2492-2cf95e6df7d1"
              href="#"
            >
              New Resource
            </a>
            <div
              className="modal-close-wrapper"
              data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
              prop-events-names="onClick"
              prop-events-value-onclick="handleModalCloseClick"
            >
              <img alt="" loading="lazy" src="/images/close_1close.png" />
            </div>
          </div>
          <div className="w-layout-vflex modal-body">
            <div className="w-layout-grid prompt-container dataset-prompt-container">
              <div className="w-layout-vflex prompt-element">
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">PDF</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    project-execution-map.pdf
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_9185f9b8-cd24-ba2c-2492-2cf95e6df7e4-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-b4bcbbc4-4267-f9b9-f5ac-26fd18f8c710-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_92fd9786-901f-3562-c5c4-e56c5fa930df-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_6058983d-a036-bfab-a90b-7047e792d469-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_097c02ac-e437-5106-4e8e-cc17689e8408-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-c46101f8-a0ce-b7a5-a4f0-088197d66407-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_284bf844-f06b-74eb-3609-10a2208f3c59-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-_6d6cb5d0-af0e-20d6-cf58-c504a8086be8-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-d2369ff8-d840-495a-bf4a-8ac751a369d8-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-fd4f2f42-6ef7-9af6-b549-e27032c3243f-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
              <div
                className="w-layout-vflex prompt-element"
                id="w-node-e48dc68a-76ec-d559-3d8d-164e5df00028-e5765214"
              >
                <div className="prompt-el-header">
                  <h5 className="prompt-el-title">URL</h5>
                </div>
                <div className="prompt-el-inner">
                  <div className="prompt-el-description">
                    www.ibleducation.com
                  </div>
                </div>
                <div className="w-layout-hflex prompt-el-footer">
                  <div className="prompt-el-action-text">LOAD</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modals />

      <Outlet />
    </div>
  );
}
