import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useMentorDetails from '../api/mentors/useMentorDetails';
import { mentorActions } from '../../lib/redux/mentors/slice';

export function useSettings(unique_id) {
  const auth = useSelector((state) => state.auth.data);
  const { loadMentorSettings } = useMentorDetails();
  const [mentorSettings, setMentorSettings] = useState({});

  const handleSetMentorForEdit = async () => {
    const mentorSettings = await loadMentorSettings(
      auth?.tenant.key,
      unique_id
    );
    if (mentorSettings) {
      setMentorSettings({
        allow_anonymous: mentorSettings.allow_anonymous,
        system_prompt: mentorSettings.system_prompt,
        safety_system_prompt: mentorSettings.safety_system_prompt,
        safety_response: mentorSettings.safety_response,
        moderation_system_prompt: mentorSettings.moderation_system_prompt,
        moderation_response: mentorSettings.moderation_response,
        guided_prompt_instructions: mentorSettings.guided_prompt_instructions,
        enable_guided_prompts: mentorSettings?.enable_guided_prompts,
        settings: mentorSettings,
      });
    }
  };

  useEffect(() => {
    handleSetMentorForEdit();
  }, []);

  return { settings: mentorSettings };
}

export function useMentorProperties(unique_id) {
  const dispatch = useDispatch();
  const { loadMentorProperties } = useMentorDetails();
  const auth = useSelector((state) => state.auth.data);
  const mentorSettings = useSelector((state) => state?.mentors?.mentorSettings?.settings);
  const refreshSettingsCount = useSelector(
    (state) => state?.mentors?.mentorSettings?.refreshSettingsCount
  );

  const handleSetMentorForEdit = async () => {
    const mentorSettings = await loadMentorProperties(
      auth?.tenant.key,
      unique_id
    );
    if (mentorSettings) {
      dispatch(
        mentorActions.mentorSettingsUpdated({
          allow_anonymous: mentorSettings.allow_anonymous,
          system_prompt: mentorSettings.system_prompt,
          safety_system_prompt: mentorSettings.safety_system_prompt,
          safety_response: mentorSettings.safety_response,
          moderation_system_prompt: mentorSettings.moderation_system_prompt,
          moderation_response: mentorSettings.moderation_response,
          guided_prompt_instructions: mentorSettings.guided_prompt_instructions,
          enable_guided_prompts: mentorSettings?.enable_guided_prompts,
          settings: mentorSettings,
        })
      );
    }
  };

  function refreshSettings() {
    dispatch(mentorActions.refreshMentorSettings());
  }

  useEffect(() => {
    handleSetMentorForEdit();
  }, [refreshSettingsCount]);

  return {
    settings: mentorSettings,
    refreshSettings,
    refreshSettingsCount,
  };
}
