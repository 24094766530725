import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import { logger } from 'redux-logger/src';
import { Error } from '../../../modules/alerts';

export default function useIntegrationCredential() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleResponse = (response, notify) => {
    if (response?.data) {
      if (notify) {
        toast.success('Integration Data has been updated!', {
          ariaProps: { role: 'status' },
        });
      }
      return response?.data;
    } else {
      if (notify) {
        toast.custom(Error(response?.error), {
          ariaProps: { role: 'alert' },
        });
      }
    }
  };

  const handleCreateIntegration = async (
    data,
    notify = true,
    isForm = false
  ) => {
    const endpoint = `/api/ai-account/orgs/${auth?.tenant?.key}/integration-credential/`;
    data.platform = auth?.tenant?.key;
    try {
      const getResponse = await api.get(endpoint, data, isForm);
      if (getResponse?.data?.length > 0) {
        const response = await api.patch(endpoint, data, isForm);
        return handleResponse(response, notify);
      } else {
        const createResponse = await api.post(endpoint, data, isForm);
        return handleResponse(createResponse, notify);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  return { handleCreateIntegration };
}
