import { useState } from 'react';

import DOMPurify from 'dompurify';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

import { useTriggers } from '../../hooks/navigation';
import { copyTextToClipboard as copyTextToClipBoard } from '../../utils/shared';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import {
  useGetPromptCategoriesQuery,
  useUpdatePromptMutation,
} from '../../lib/redux/prompts/api-slice';

export default function EditPromptModal() {
  const { t } = useTranslation();
  const { _handleUpdateModalStatus } = useTriggers();
  const [updatePrompt, { isLoading }] = useUpdatePromptMutation();
  const auth = useSelector((state) => state.auth.data);
  const promptGalleryPrompt = useSelector(
    (state) => state.analytics.promptGalleryPrompt
  );
  const { data: promptCategories, isLoading: promptCategoriesIsLoading } =
    useGetPromptCategoriesQuery({
      org: auth?.tenant?.key,
      userId: auth?.user?.user_nicename,
    });
  const [prompt, setPrompt] = useState({
    prompt: promptGalleryPrompt?.prompt,
    category: promptGalleryPrompt?.category?.name,
  });
  const key = auth?.tenant?.key;
  const user_nicename = auth?.user?.user_nicename;

  const openEdit = true;

  const handleModalCloseClick = () => {
    _handleUpdateModalStatus('edit-prompt-gallery-page-modal', false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrompt((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleEditPrompt = async () => {
    try {
      const response = await updatePrompt({
        promptId: promptGalleryPrompt.id,
        data: { ...prompt, prompt: DOMPurify.sanitize(prompt.prompt) },
        org: key,
        userId: user_nicename,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      toast.success('Prompt edited successfully', {
        ariaProps: { role: 'status' },
      });
      handleModalCloseClick();
    } catch (error) {
      toast.error('Failed to edit prompt', { ariaProps: { role: 'alert' } });
    }
  };

  const handleTriggerPrompt = () => {};

  if (!prompt) return null;

  return (
    <div
      className="modal edit-prompt-gallery-page-modal"
      onClick={(e) => {
        e.stopPropagation();
        handleModalCloseClick();
      }}
    >
      <div
        className="w-layout-vflex modal-container create-secret-key-modal-container prompt-gallery-element-view-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`w-layout-hflex modal-header ${prompt?.is_system || prompt.index !== undefined ? 'padding-zero' : ''}`}
        >
          {openEdit && !prompt?.is_system && prompt.index === undefined && (
            <div className="prompt-gallery-element-view-header-form-block w-form">
              <div
                className="prompt-gallery-element-view-header-form-input w-input"
                dangerouslySetInnerHTML={{
                  __html:
                    prompt?.prompt?.length > 35
                      ? `${prompt?.prompt.substring(0, 35)}...`
                      : prompt?.prompt,
                }}
              />
            </div>
          )}
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body prompt-gallery-element-view-modal-body">
          {openEdit && (
            <div className="prompt-gallery-element-view-desc-form-block w-form">
              <form
                className="prompt-gallery-element-view-desc-form"
                data-name="Email Form 10"
                data-wf-element-id="1f4a83bc-a16c-f78f-8d36-5b581f17207b"
                data-wf-page-id="650305782bc61751e5765214"
                id="email-form-10"
                method="get"
                name="email-form-10"
                style={{ display: 'grid', gap: '20px' }}
              >
                <select
                  className="prompt-select-category whiten-in-dark-mode w-select"
                  data-name="Field 2"
                  id="field-2"
                  onChange={handleChange}
                  disabled={promptCategoriesIsLoading || isLoading}
                  name="category"
                  defaultValue={prompt?.category}
                >
                  <option value="">
                    {t(
                      promptCategoriesIsLoading || isLoading
                        ? 'Loading'
                        : 'Select Category'
                    )}
                  </option>
                  {promptCategories?.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <RichTextEditor
                  value={prompt?.prompt}
                  onChange={handleChange}
                  name="prompt"
                  readOnly={isLoading}
                />
              </form>
            </div>
          )}
          <div className="w-layout-hflex flex-block-69">
            {openEdit && (
              <div
                onClick={handleEditPrompt}
                className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn prompt-gallery-element-save-btn"
              >
                {isLoading ? (
                  <IBLSpinLoader size={14} />
                ) : (
                  <img
                    alt=""
                    className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                    loading="lazy"
                    src="/images/save-3.svg"
                  />
                )}
                <div>{t('Save')}</div>
              </div>
            )}
            {!prompt.isSystem && (
              <div
                onClick={handleTriggerPrompt}
                className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
              >
                <img
                  alt=""
                  className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                  loading="lazy"
                  src="/images/terminal.svg"
                />
                <div>{t('Run')}</div>
              </div>
            )}
            <div
              onClick={() => {
                copyTextToClipBoard(prompt?.prompt);
                toast.success('Copied!', { ariaProps: { role: 'status' } });
              }}
              className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
            >
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="/images/copy.svg"
              />
              <div>{t('Copy')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
