import { createApi } from '@reduxjs/toolkit/query/react';

import { endpoints } from './endpoints';
import { fetchBaseQuery } from '../fetch-base-query';

export const callApiSlice = createApi({
  reducerPath: 'callApiSlice',

  baseQuery: fetchBaseQuery,

  endpoints: (builder) => ({
    createCallCredentials: builder.mutation({
      query: (args) => ({
        url: endpoints.createCallCredentials(args),
        method: 'POST',
        body: args.body,
      }),
    }),
  }),
});

export const { useCreateCallCredentialsMutation } = callApiSlice;
