import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getTabsAndMessagesAndSessions } from '../../modules/platform/data';

import { chatActions } from '../../lib/redux/chat/slice';

const paths = ['/analytics', '/explore', '/prompt-gallery'];

export default function useNewChat() {
  const params = useParams();
  const { mode } = useSelector((state) => state.embed);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chat = useSelector((state) => state.chat);

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);

  const platformKey = params?.platform || auth?.tenant?.key;
  const mentorSlug = mentors?.mentor?.slug || params?.mentor;

  const handleNewChatBtnClick = () => {
    window.creatingSession = false;
    dispatch(chatActions.sessionDataUpdated(null));
    dispatch(chatActions.chatSequenceUpdated(chat.sequence + 1));

    if (mode === 'advanced') {
      const { tabbedMessages } = getTabsAndMessagesAndSessions();
      dispatch(chatActions.tabbedMessagesUpdated({ ...tabbedMessages }));
      return;
    }

    if (!mentorSlug) {
      navigate('/');
      return;
    }

    const pathName = window.location.pathname;

    if (paths.some((path) => pathName?.includes(path))) {
      navigate(`/platform/${platformKey}/${mentorSlug}`);
    }
  };

  return { handleNewChatBtnClick };
}
