import { isLoggedIn } from '../../../utils/shared';
import useApi from '../base/useApi';
import { useSelector } from 'react-redux';

export default function useMentorSuggestedPrompts() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const loadSuggestedPrompts = async (mentor) => {
    if (!isLoggedIn()) {
      return;
    }

    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/?mentor_unique_id=${mentor}`;
    const response = await api.get(url);
    if (response?.data) {
      return Array.isArray(response?.data) ? response?.data : [];
    }
  };

  return { loadSuggestedPrompts };
}
