import { useSelector } from 'react-redux';

import useEmbedMode from '../../hooks/iframe/useEmbedMode';
import { userIsAdmin, userIsOnTrial } from '../../utils/shared';

export function SidebarAuthButton(props) {
  const embed = useEmbedMode();

  const auth = useSelector((state) => state.auth.data);
  let instructorMode = useSelector((state) => state.navigation.instructorMode);

  if (!auth?.tenant?.is_admin) {
    instructorMode = false;
  }

  if (embed) return null;

  if (!userIsAdmin() && !userIsOnTrial()) return null;

  if (!instructorMode) return null;

  return <>{props.children}</>;
}
