import { useState } from 'react';
import { api } from '@iblai/ibl-web-react-common';
import toast from 'react-hot-toast';
import { copyTextToClipboard } from '../../../utils/shared';

export default function useMentorRedirectTokens() {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [error, setError] = useState('');
  const [redirectToken, setRedirectToken] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    if (!websiteUrl) {
      setError('Invalid URL');
      return;
    }
    const validUrl = getValidEmbedUrl();
    if (!validUrl) return;
    setSubmitting(true);
    setRedirectToken('');
    api.ibldmcore.createTokenFromUrl(
      { org: localStorage.getItem('tenant'), url: validUrl },
      (data) => {
        setRedirectToken(data.token);
        setSubmitting(false);
        setError('');
      }
    );
  };
  const getValidEmbedUrl = () => {
    try {
      const url = new URL(websiteUrl);
      return url.origin;
    } catch (error) {
      console.log(error);
      setError('Invalid URL');
      return false;
    }
  };

  const handleCopyRedirectToken = async () => {
    const isCopied = await copyTextToClipboard(redirectToken);
    if (!isCopied) {
      toast.error('Failed to copy to clipboard', {
        ariaProps: { role: 'alert' },
      });
      return;
    }
    toast.success('Copied to clipboard', { ariaProps: { role: 'status' } });
  };

  return {
    handleSubmit,
    error,
    submitting,
    handleCopyRedirectToken,
    websiteUrl,
    redirectToken,
    setWebsiteUrl,
  };
}
