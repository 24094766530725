import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';
import useMentorUtils from '../../utils/useMentorUtils';
import { api, getTenant, getUserName } from '@iblai/ibl-web-react-common';
import { useTranslation } from 'react-i18next';

const DUMMY_CUSTOM_CSS = '/* CUSTOM_CSS */';

export default function useMentorEmbed(mentor) {
  const [mentorToEmbed, setMentorToEmbed] = useState(mentor);
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { _handleUpdateModalStatus } = useTriggers();
  const { updateLocalMentorSettings } = useMentorUtils();
  const [ssoProviders, setSsoProviders] = useState([]);
  const mentors = useSelector((state) => state.mentors);
  const { t } = useTranslation();

  const [settings, setSettings] = useState({
    initial_message: '',
    suggested_message: '',
    is_context_aware: false,
    mode: 'default',
    metadata: {
      primary_color: '#2467eb',
      secondary_color: '#000',
      safety_disclaimer: false,
    },
    allow_anonymous: mentor.allow_anonymous,
    sso: false,
    auth_backend: '',
    website_url: '',
    custom_css: '',
    auto_open: false,
    generate_redirect_token: !mentor.allow_anonymous,
  });

  const [redirectToken, setRedirectToken] = useState();

  const getExistingCss = (data) => {
    if (data?.custom_css && data.custom_css.trim() === DUMMY_CUSTOM_CSS) {
      return '';
    }
    return data?.custom_css ?? '';
  };

  const [submitting, setSubmitting] = useState(false);

  const getDefaultInitialMessage = () => {
    return `I'm your ${mentors?.edit?.mentor?.name}. Not sure where to start? You can try:`;
  };

  const getDefaultSuggestedPrompt = () => {
    return mentors?.edit?.mentor?.settings?.suggested_prompts?.[0]?.prompt;
  };

  useEffect(() => {
    if (mentor) {
      const data = mentor.settings;
      const dataToOverride = {
        initial_message: data?.initial_message ?? getDefaultInitialMessage(),
        suggested_message:
          data?.suggested_message ?? getDefaultSuggestedPrompt(),
        metadata: {
          ...settings.metadata,
          ...(data?.metadata ?? {}),
        },
        allow_anonymous: mentor.allow_anonymous ?? false,
        custom_css: getExistingCss(data),
        mode: 'default',
        auto_open: false,
      };
      setSettings(dataToOverride);
    }
  }, [mentor]);

  useEffect(() => {
    const payload = {
      platform_key: getTenant(),
      username: getUserName(),
    };
    api.ibledxplatform.getIntegratedProviders(payload, (response) => {
      setSsoProviders(response.providers);
    });
  }, []);

  const handleSubmit = async () => {
    if (!settings.allow_anonymous && !settings.website_url) {
      toast.error(
        t('Please specify the Website URL, when embedding in Auth mode'),
        { ariaProps: { role: 'alert' } }
      );
      return;
    }

    setSubmitting(true);
    const payload = {
      metadata: settings.metadata,
      allow_anonymous: settings.allow_anonymous,
    };
    if (settings.initial_message) {
      payload['initial_message'] = settings.initial_message;
    }

    if (settings.suggested_message) {
      payload['suggested_message'] = settings.suggested_message;
    }

    if (settings.custom_css.trim()) {
      payload['custom_css'] = settings.custom_css;
    } else {
      payload['custom_css'] = DUMMY_CUSTOM_CSS;
    }

    if (settings.safety_disclaimer) {
      payload['safety_disclaimer'] = settings.safety_disclaimer;
    }

    if (settings.mode === 'advanced') {
      settings.is_context_aware = true;
    }

    const getValidEmbedUrl = () => {
      const url = new URL(settings.website_url);
      return url.origin;
    };

    const updatedSettings = await handleUpdateSettings(mentor, payload);
    updateLocalMentorSettings(mentor, updatedSettings);
    setMentorToEmbed({
      ...mentor,
      is_context_aware: settings.is_context_aware,
      mode: settings.mode,
      safety_disclaimer: settings.safety_disclaimer,
      allow_anonymous: settings.allow_anonymous,
      auth_backend: settings.auth_backend,
      auto_open: settings.auto_open,
    });
    if (!settings.allow_anonymous) {
      api.ibldmcore.createTokenFromUrl(
        { org: localStorage.getItem('tenant'), url: getValidEmbedUrl() },
        (data) => {
          setRedirectToken(data.token);
          setSubmitting(false);
          _handleUpdateModalStatus('embed-mentor-modal', true);
        }
      );
    } else {
      setSubmitting(false);
      _handleUpdateModalStatus('embed-mentor-modal', true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleMetadataChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return {
        ...prevState,
        metadata: { ...prevState.metadata, [name]: value },
      };
    });
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: checked };
    });
    if (name === 'allow_anonymous') {
      setSettings((prevState) => ({
        ...prevState,
        generate_redirect_token: !checked,
      }));
    }
  };

  const handleMetaDataCheckChange = (event) => {
    const { name, checked } = event.target;
    setSettings((prevState) => {
      return {
        ...prevState,
        metadata: {
          ...prevState.metadata,
          [name]: checked,
        },
      };
    });
  };

  const handleUpdatePreview = () => {
    const iframedSite = document.querySelector('#embed-mentor-preview');
    const message = JSON.stringify({
      welcomeMessage: settings?.initial_message,
      defaultPrompt: settings?.suggested_message,
      css: settings?.custom_css,
      allowAnonymous: true,
      internal_preview: true,
      metadata: settings?.metadata,
    });
    iframedSite.contentWindow.postMessage(message, '*');
  };

  useEffect(() => {
    handleUpdatePreview();
  }, [settings]);

  return {
    handleChange,
    handleCheckChange,
    handleMetadataChange,
    handleSubmit,
    mentorToEmbed,
    submitting,
    settings,
    handleMetaDataCheckChange,
    redirectToken,
    ssoProviders,
  };
}
